import Vue from 'vue'
import Vuex from 'vuex'
import { IUserData } from '../types/app'
import jQuery from 'jquery'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      authenticated: false,
      name: '',
      nickName: '',
      email: '',
      locale: 'de-DE',
      timezone: 'Europe/Berlin',
      decimalSeparator: '.',
      groupingSeparator: ','
    },
    version: {
      appNG: '',
      app: '',
      frontend: ''
    },
    router: {
      from: {}
    }
  },
  mutations: {
    updateUserData (state: any, data: IUserData): any {
      state.user.authenticated = typeof data.authenticated !== 'undefined' ? data.authenticated : state.user.authenticated
      state.user.name = typeof data.name !== 'undefined' ? data.name : state.user.name
      state.user.nickName = typeof data.nickName !== 'undefined' ? data.nickName : state.user.nickName
      state.user.email = typeof data.email !== 'undefined' ? data.email : state.user.email
      state.user.locale = typeof data.locale !== 'undefined' ? data.locale : state.user.locale
      state.user.decimalSeparator = typeof data.decimalSeparator !== 'undefined' ? data.decimalSeparator : state.user.decimalSeparator
      state.user.groupingSeparator = typeof data.groupingSeparator !== 'undefined' ? data.groupingSeparator : state.user.groupingSeparator
    },
    resetUserData (state: any): any {
      state.user.authenticated = false
      state.user.name = ''
      state.user.nickName = ''
      state.user.email = ''
      state.user.locale = 'de-DE'
      state.user.decimalSeparator = '.'
      state.user.groupingSeparator = ','
    },
    updateVersionData (state: any, data: {[key: string]: string}): any {
      state.version.appNG = typeof data.appNGVersion !== 'undefined' ? data.appNGVersion : state.version.appNG
      state.version.app = typeof data.appVersion !== 'undefined' ? data.appVersion : state.version.app
      if (typeof data.frontendVersion !== 'undefined' && data.frontendVersion !== '' && state.version.frontend !== '' && data.frontendVersion !== state.version.frontend) {
        jQuery(window).trigger('frontendAppChanged')
      }
      state.version.frontend = typeof data.frontendVersion !== 'undefined' ? data.frontendVersion : state.version.frontend
    }
  },
  actions: {
  },
  modules: {
  }
})
