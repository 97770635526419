import Vue from 'vue'
import { IComponentData } from '@/types/app.d'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'
import { generalService } from '@/scripts/services/GeneralService'
import { dataService } from '@/scripts/services/DataService'
import { uiService } from '@/scripts/services/UIService'

export default Vue.extend({
  props: {
    sourceURL: String,
    dontShowImmediately: Boolean
  },
  data (): IComponentData {
    return {
      id: '',
      guid: '',
      originId: '',
      execute: '',
      title: '',
      filter: {},
      setFilters: [],
      field: {},
      links: [],
      pagination: null,
      params: {},
      permissions: {},
      results: [],
      sort: [],
      labels: {},
      layout: {},
      messages: null,
      images: null,
      isDataRequestError: null
    }
  },
  watch: {
    dontShowImmediately (val: boolean, oldVal: boolean): void {
      // generalService.log('!!!!!!!!!!>>>>>>>>> watch from dontShowImmediately', val, this.id)
      if (((val === false) && (this.id === '')) || ((oldVal === true) && (val === false))) {
        this.fetchData()
      }
    }
  },
  computed: {
    locale (): string {
      return generalService.getLocale()
    },
    componentSourceURL (): string {
      let sourceURL = ''
      if (typeof this.sourceURL === 'undefined') {
        const currentRoute: any = this.$router.currentRoute
        sourceURL = ((typeof currentRoute.meta !== 'undefined') && (typeof currentRoute.meta.sourceURL !== 'undefined')) ? currentRoute.meta.sourceURL : ''
      } else sourceURL = this.sourceURL
      return sourceURL
    },
    labelsEnabled (): boolean {
      return (this.labels !== null && (Object.keys(this.labels).length > 0))
    },
    fieldsEnabled (): boolean {
      return (this.field !== null && (Object.keys(this.field).length > 0))
    },
    formId (): string {
      return this.id + '_' + this.guid
    },
    headlineEnabled ():boolean {
      return (this.labelsEnabled && this.labels.headline) || this.title
    },
    headline (): any {
      if (this.labelsEnabled && this.labels.headline) return this.labels.headline
      else return this.title
    },
    showLoader ():boolean {
      return (this.id === '' && !this.isDataRequestError)
    }
  },
  created (): void {
    generalService.log('Action component "' + this.$options.name + '" created', this.dontShowImmediately)
    this.$on('afterSendData', (data: any): void => {
      this.callbackAfterSendData(data)
    })
  },
  mounted (): void {
    generalService.log('Action component "' + this.$options.name + '" mounted !', this.sourceURL)
    generalService.log('Action component "' + this.$options.name + '": Get data ...')

    this.guid = generalService.createGuid()

    if (this.dontShowImmediately !== true) {
      this.fetchData()
    }
  },
  updated (): void {
    this.$nextTick((): void => {
      generalService.log('Action component "' + this.$options.name + '" updated')
      if (this.id !== '') {
        uiService.initForm(this, '#' + this.formId, (data: any) => {
          this.callbackSubmitForm(data)
        }).then(() => {
          this.callbackAfterInitForm()
        })
      }
    })
  },
  methods: {
    getServiceUrl (sourceId: string): string {
      return dataService.getServiceUrl(this, sourceId)
    },
    fetchData (): void {
      if (this.componentSourceURL) {
        dataService.getContentData4Action(this, this.componentSourceURL, {}, (data: any) => {
          dataService.updateComponentData(this, data)
        })
      }
    },
    callbackSubmitForm (data: any): void {
      generalService.log('submitForm Callback', data)
      dataService.updateComponentData(this, data)
    },
    callbackAfterInitForm (): void {
      return
    },
    callbackAfterSendData (data: any): void {
      generalService.log('> DEFAULT afterSendData callback', data)
    },
    registerRequestState (code: number): void {
      this.isDataRequestError = code !== 200 ? true : false
      this.$emit4AllParents('setRequestState', {
        name: this.$options.name,
        code: code
      })
    },
    getButtonLabel (name: string): string {
      return (this.labels !== null) && (typeof this.labels.button !== 'undefined') && (typeof this.labels.button[name] !== 'undefined') ? this.labels.button[name] : (name.charAt(0).toUpperCase() + name.slice(1))
    },
    getTooltips (fieldName: string): any {
      if (this.labelsEnabled){
        if (typeof this.labels.field[fieldName].tooltips !== 'undefined' && this.labels.field[fieldName].tooltips !== null && (Object.keys(this.labels.field[fieldName].tooltips).length > 0))  return this.labels.field[fieldName].tooltips
        else if (typeof this.labels.field[fieldName].tooltip !== 'undefined' && this.labels.field[fieldName].tooltip !== null) return this.labels.field[fieldName].tooltip
        else return null
      }
    },
    getIcons (fieldName: string): any {
      if (this.labelsEnabled){
        if (typeof this.labels.field[fieldName].icons !== 'undefined' && this.labels.field[fieldName].icons !== null && (Object.keys(this.labels.field[fieldName].icons).length > 0))  return this.labels.field[fieldName].icons
        else if (typeof this.labels.field[fieldName].icons !== 'undefined' && this.labels.field[fieldName].icons !== null) return this.labels.field[fieldName].icons
        else return null
      }
    },
    $emit4AllParents (eventName: string, data: any): void {
      vueTemplateService.$emit4AllParents(this, eventName, data)
    },
    $emit4Children (eventName: string, data: any): void {
      vueTemplateService.$emit4Children(this, eventName, data)
    },
    getGlobalLabel (type: string, name: string): string {
      return uiService.getGlobalLabel(type, name)
    },
    setDataContainerHeight (): void {
      uiService.setDataContainerHeight()
    }
  }
})