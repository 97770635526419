<template>
  <div id="communication-loader" v-bind:class="{ hidden: isHidden }">
    <div>
      <div class="wrapper">
        <div class="ajax-loader"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'communication-loader',
  props: {
    hidden: Boolean
  },
  computed: {
    isHidden (): boolean {
      return this.hidden
    }
  }
})
</script>
