export interface IComParamScopeItem {
  [key: string]: string;
}

export const comParam = {
  add: function (target: Array<IComParamScopeItem>, name: string, value: string): Array<IComParamScopeItem> {
    const param: IComParamScopeItem = {}
    param[name] = value
    target.push(param)
    return target
  },
  remove: function (target: Array<IComParamScopeItem>, name: string, value: string): Array<IComParamScopeItem> {
    function isValuesEQ (targetValue: string, value: string): boolean {
      if (typeof value === 'undefined') return true
      if (targetValue === value) return true
      else return false
    }
    for (let i = 0; i < target.length; i++) {
      for (const paramName in target[i]) {
        if ((paramName === name) && isValuesEQ(target[i][paramName], value)) {
          target.splice(i, 1)
        }
      }
    }
    return target
  },
  change: function (target: Array<IComParamScopeItem>, name: string, value: string): Array<IComParamScopeItem> {
    let found = false
    for (let i = 0; i < target.length; i++) {
      for (const paramName in target[i]) {
        if (paramName === name) {
          target[i][paramName] = value
          found = true
        }
      }
    }
    if (!found) this.add(target, name, value)
    return target
  },
  get: function (target: Array<IComParamScopeItem>, name: string): null | string | Array<string> {
    const result: Array<string> = []
    for (let i = 0; i < target.length; i++) {
      for (const paramName in target[i]) {
        if (paramName === name) {
          result.push(target[i][paramName])
        }
      }
    }
    if (result.length === 0) return null
    if (result.length === 1) return result[0]
    return result
  },
  getRequestedState: function (): Array<IComParamScopeItem> {
    const state: Array<IComParamScopeItem> = []
    const items: Array<string> = this.getUrlParams().split('&')
    if ((items.length === 1) && (items[0] === '')) return state
    for (let i = 0; i < items.length; i++) {
      const item: Array<string> = items[i].split('=')
      const param: IComParamScopeItem = {}
      param[item[0]] = typeof item[1] !== 'undefined' ? decodeURIComponent(item[1].replace(/\+/g, '%20')) : ''
      state.push(param)
    }
    return state
  },
  getUrlParams: function (): string {
    const hash = window.location.hash
    const params = window.location.search
    if (typeof window.history.pushState === 'function') {
      if (params.trim() !== '') {
        return params.substring(1)
      }
    } else {
      if (hash.trim() !== '') {
        return hash.substring(1)
      } else {
        if (params.trim() !== '') {
          return params.substring(1)
        }
      }
    }
    return ''
  },
  refreshBrowserGetParams: function (getParams: Array<IComParamScopeItem>): void {
    const searchString: string = this.getRequestParamString(getParams)
    const hash: string = window.location.hash
    if (typeof window.history.pushState === 'function') {
      history.replaceState({}, 'Data Search', '?' + searchString)
      if (hash !== '') window.location.hash = hash
    } else {
      // as local anchor
      window.location.hash = '#' + searchString
    }
  },
  getRequestParamString: function (source: Array<IComParamScopeItem>): string {
    let searchString = ''
    let separator = ''
    for (let i = 0; i < source.length; i++) {
      for (const name in source[i]) {
        searchString += separator + name + '=' + encodeURIComponent(source[i][name])
        separator = '&'
      }
    }
    return searchString
  },
  getGetParamByName: function (name: string): string | null {
    let params: string = window.location.search
    if (params !== '') {
      params = params.substr(1, params.length - 1)
      const arrParams: Array<string> = params.split('&')
      for (let i = 0; i < arrParams.length; i++) {
        const param: Array<string> = arrParams[i].split('=')
        if (param[0].toLowerCase() === name.toLowerCase()) return decodeURIComponent(param[1].replace(/\+/g, '%20'))
      }
      return null
    } else return null
  },
  getPathParamByNumber: function (num: number): string | null {
    const foo: Array<string> = window.location.pathname.split('/')
    if (typeof foo[num] !== 'undefined') return foo[num]
    return null
  },
  merge: function (target: Array<IComParamScopeItem>, source: Array<IComParamScopeItem>): Array<IComParamScopeItem> {
    const result: Array<IComParamScopeItem> = []
    // target in result
    for (let i = 0; i < target.length; i++) {
      result.push(target[i])
    }
    // source in result
    for (let sourceIndex = 0; sourceIndex < source.length; sourceIndex++) {
      for (const sourceName in source[sourceIndex]) {
        let sourceFoundInTarget = false
        for (let targetIndex = 0; targetIndex < target.length; targetIndex++) {
          for (const targetName in target[targetIndex]) {
            if (sourceName === targetName) {
              sourceFoundInTarget = true
              result[targetIndex] = source[sourceIndex]
            }
          }
        }
        if (!sourceFoundInTarget) result.push(source[sourceIndex])
      }
    }
    return result
  }
}
