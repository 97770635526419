import jQuery from 'jquery'

declare global {
  interface JQuery {
    string(arg: any): {[key: string]: any};
  }
  interface JQueryStatic {
    string: {[key: string]: any};
  }
}
(function ($) {
  $.string = {
    replaceAll: function (subject: string, pattern: string, replace: string):string {
      let counter = 0
      do {
        subject = subject.replace(pattern, replace)
        counter++
      } while ((subject.search(pattern) !== -1) && (counter < 100))
      return subject
    },
    replaceAllFromArray: function (subject: string, replaceTable: Array<any>): string {
      let result = subject
      for (let i = 0; i < replaceTable.length; i++) {
        result = this.replaceAll(result, replaceTable[i].s, replaceTable[i].r)
      }
      return result.trim()
    },
    replacePlaceHolders: function (subject: string, data: {[key: string]: any}): string {
      const replace = (subject: string, prop: string, propPrefix: string, data: {[key: string]: any}) => {
        const pattern = new RegExp('<#' + propPrefix + prop + '#>')
        subject = this.replaceAll(subject, pattern, data[prop])
        return subject.trim()
      }
      function recursion (subject: string, data: {[key: string]: any}, propPrefix: string): string {
        for (const prop in data) {
          if ((typeof data[prop] === 'string') || (typeof data[prop] === 'number')) {
            subject = replace(subject, prop, propPrefix, data)
          } else {
            subject = recursion(subject, data[prop], (propPrefix + prop + '.'))
          }
        }
        return subject
      }
      return recursion(subject, data, '').trim()
    }
  }
}(jQuery))
