let instance: any = null

class EventBus {
  constructor() {
    if (!instance) {
      this.events = {}
      instance = this
    }
    return instance
  }

  private events: {[key: string]: any} = {}

  $emit (event, message?) {
    if (!this.events[event]) return
    const callbacks = this.events[event]
    for (let i = 0, l = callbacks.length; i < l; i++) {
      const callback = callbacks[i]
      callback.call(this, message)
    }
  }

  $on (event, callback) {
    if (!this.events[event]) this.events[event] = []
    this.events[event].push(callback)
  }
}

export default new EventBus()