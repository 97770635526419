<template>
  <div class="form-group">
    <label v-bind:for="id" v-bind:class="{required:isRequired}" class="input-text">{{field.label}}</label>
    <input type="file"
        class="form-control"
        v-bind:data-type="dataType"
        v-bind:id="id"
        v-bind:name="field.name"
        v-bind:multiple="multiple"
        v-bind:accept="accept"
        v-bind:required="isRequired"
        v-bind:readonly="isReadonly"
        v-bind:disabled="isDisabled"
        v-bind:data-error-message-selector="errorMessageSelector"
        v-bind:data-error-message="errorMessage"
        v-bind:data-error-type-message="errorMessageType"
        v-on:change="onChange"
    >
    <div class="error-message" v-bind:id="errorMessageID"></div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { IFormFile } from '@/types/app.d'
import { generalService } from '@/scripts/services/GeneralService'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'

export default Vue.extend({
  name: 'f-input-file',
  data (): {[key: string]: any} {
    return {
      guid: ''
    }
  },
  props: {
    field: Object as PropType<IFormFile>,
    type: String,
    multiple: Boolean,
    disabled: String,
    accept: String,
    errorMessageRequired: String,
    errorMessageInvalidInput: String
  },
  mounted (): void {
    this.guid = generalService.createGuid()
  },
  computed: {
    id (): string {
      return  typeof this.field !== 'undefined' ? 'id_' + this.field.name.replace(/\./g, '_') + '_' + this.guid : 'id_undefined_' + this.guid
    },
    dataType (): string {
      return this.type === undefined ? 'text' : this.type
    },
    isRequired (): boolean {
      return typeof this.field === 'undefined' ? false : this.field.required
    },
    isReadonly (): boolean {
      return typeof this.field === 'undefined' ? true : this.field.readonly
    },
    isDisabled (): boolean {
      return this.disabled === 'true'
    },
    errorMessage (): string {
      return (this.errorMessageRequired) ? this.errorMessageRequired : (this.field.errorMessage === undefined ? 'required' : this.field.errorMessage)
    },
    errorMessageType (): string {
      return (this.errorMessageInvalidInput) ? this.errorMessageInvalidInput : (this.errorMessageInvalidInput === undefined ? 'invalid input' : this.errorMessageInvalidInput)
    },
    errorMessageID (): string {
      return 'error_' + this.id
    },
    errorMessageSelector (): string {
      return '#' + this.errorMessageID
    }
  },
  methods: {
    $emit4AllParents (eventName: string, data: any): void {
      vueTemplateService.$emit4AllParents(this, eventName, data)
    },
    onChange (e: any): void {
      this.$emit4AllParents('uploadFieldChanged', {
        name: this.field.name,
        value: e.originalTarget.value
      })
    }
  }
})
</script>
