/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NavigationItem = {
    /**
     * the API path (type PAGE only)
     */
    _self?: string;
    name?: string;
    /**
     * if this is the default page? (type PAGE only)
     */
    default?: boolean;
    path?: string;
    active?: boolean;
    type?: NavigationItem.type;
    items?: Array<NavigationItem>;
};

export namespace NavigationItem {

    export enum type {
        SITE = 'SITE',
        APP = 'APP',
        PAGE = 'PAGE',
    }


}
