import Vue from 'vue'
import { globalVar } from '@/scripts/own/_globalVar'
import { IComponentData } from '@/types/app.d'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'
import { generalService } from '@/scripts/services/GeneralService'
import { dataService } from '@/scripts/services/DataService'
import { uiService } from '@/scripts/services/UIService'
import { polling } from '@/scripts/own/polling'

export default Vue.extend({
  props: {
    sourceURL: String,
    dontShowImmediately: Boolean,
    collapsed: Boolean
  },
  data (): IComponentData {
    return {
      id: '',
      guid: '',
      originId: '',
      title: '',
      filter: {},
      setFilters: [],
      field: {},
      links: [],
      pagination: null,
      params: {},
      permissions: {},
      results: [],
      sort: [],
      labels: {},
      layout: {},
      messages: null,
      images: null,
      currentUrl: '',
      isDataRequestError: null
    }
  },
  watch: {
    dontShowImmediately (val: boolean, oldVal: boolean): void {
      if (((val === false) && (this.id === '')) || ((oldVal === true) && (val === false))) {
        this.startFetchData()
      }
    }
  },
  computed: {
    locale (): string {
      return generalService.getLocale()
    },
    componentSourceURL (): string {
      let sourceURL = ''
      if (typeof this.sourceURL === 'undefined') {
        const currentRoute: any = this.$router.currentRoute
        sourceURL = ((typeof currentRoute.meta !== 'undefined') && (typeof currentRoute.meta.sourceURL !== 'undefined')) ? currentRoute.meta.sourceURL : ''
      } else sourceURL = this.sourceURL
      return sourceURL
    },
    labelsEnabled (): boolean {
      return (this.labels !== null && (Object.keys(this.labels).length > 0))
    },
    filterEnabled (): boolean {
      return (this.filter !== null && (Object.keys(this.filter).length > 0))
    },
    formId (): string {
      return this.id + '_' + this.guid
    },
    isFilterSet (): boolean {
      return vueTemplateService.isFilterSet(this.filter)
    },
    sortPageParamName ():string {
      return this.originId !== '' ? 'sort' + this.originId.charAt(0).toUpperCase() + this.originId.slice(1) : ''
    },
    sortParamValue ():string {
      // sortApplications=name:asc;pageSize:25;page=0
      if (this.sort !== null) {
        let sort = ''
        if (this.sort.length > 0) {
          for (let i = 0; i < this.sort.length; i++) {
            if (this.sort[i].prio !== -1) {
              sort += (sort !== '' ? ';' : '') + this.sort[i].name + ':' + this.sort[i].order
            }
          }
          sort += sort !== '' ? ';' : ''
        }
        return sort
      } else {
        return ''
      }
    },
    pageParamValue ():string {
      // sortApplications=name:asc;pageSize:25;page=0
      if (this.pagination !== null) {
        return 'pageSize:' + this.pagination.size + ';page:' + this.pagination.number
      } else {
        return ''
      }
    },
    headlineEnabled ():boolean {
      return (this.labelsEnabled && this.labels.headline) || this.title
    },
    headline (): string {
      if (this.labelsEnabled && this.labels.headline) return this.labels.headline
      else return this.title
    },
    showLoader ():boolean {
      return (this.id === '' && !this.isDataRequestError)
    },
    isPaginationEnabled (): boolean {
      if (typeof this.pagination?.totalItems !== 'undefined' && this.pagination?.totalItems >= 10) return true
      return false
    },
    dummyPictureUrl (): string {
      return globalVar.appEngine.images.dummyPicture
    },
    isAuthenticated (): boolean {
      return this.$store.state.user.authenticated
    },
    isUserPageOwner (): boolean {
      return this.isAuthenticated && this.$store.state.user.nickName === this.$router.currentRoute.params.nickName
    }
  },
  created (): void {
    generalService.log('Datasource component "' + this.$options.name + '" created')
    this.$nextTick((): void => {
      this.$on('setSort', (args: {[key: string]: string}) => {
        this.setSort(args.fieldName, args.order)
      })
      this.$on('setPage', (args: {[key: string]: number}) => {
        this.setPage(args.number)
      })
      this.$on('setPageSize', (args: {[key: string]: number}) => {
        this.setPageSize(args.size)
      })
      this.$on('afterChangeDatasource', (e: any): void => {
        this.callbackAfterChangeDatasource(e)
      })
    })
  },
  mounted (): void {
    generalService.log('Datasource component "' + this.$options.name + '" mounted !', this.sourceURL)
    generalService.log('Datasource component "' + this.$options.name + '": Get data ...')

    this.guid = generalService.createGuid()
    
    this.$on('onChangeFilterState', (filterName: string) => {
      vueTemplateService.resetFilterField(this.formId, filterName)
    })

    this.$on('onClearFilterState', () => {
      //empty
    })

    if (this.dontShowImmediately !== true) {
      this.startFetchData()
    }
  },
  updated (): void {
    this.$nextTick((): void => {
      generalService.log('Datasource component "' + this.$options.name + '" updated')
      if (this.id !== '') {
        uiService.initForm(this, '#' + this.formId, (data: any) => {
          this.callbackSubmitForm(data)
        }).then(() => {
          this.callbackAfterInitForm()
        })
        if (typeof polling !== 'undefined') {
          polling.init({ selector: '.polling' })
        }
      }
      generalService.initMentioningLinks(this)
    })
  },
  methods: {
    getServiceUrl (sourceId: string): string {
      return dataService.getServiceUrl(this, sourceId)
    },
    startFetchData (): void {
      this.fetchData()
    },
    fetchData (): void {
      if (this.componentSourceURL) {
        dataService.getContentData4Datasource(this, this.componentSourceURL, {}, (data: any) => {
          dataService.updateComponentData(this, data)
        })
      }
    },
    callbackSubmitForm (data: any): void {
      generalService.log('submitForm Callback', data)
      dataService.updateComponentData(this, data)
    },
    callbackAfterInitForm (): void {
      return
    },
    callbackAfterChangeDatasource (e: any): void {
      this.refreshContent()
    },
    registerRequestState (code: number): void {
      this.isDataRequestError = code !== 200 ? true : false
      this.$emit4AllParents('setRequestState', {
        name: this.$options.name,
        code: code
      })
    },
    onSend (): void {
      if (this.pagination !== null) {
        this.pagination.number = 0
      }
    },
    setSort (fieldName: string, order: string): void {
      function compare (a: any, b: any): number {
        if (a.prio < b.prio) {
          return -1
        }
        if (a.prio > b.prio) {
          return 1
        }
        return 0
      }
      for (let i = 0; i < this.sort.length; i++) {
        if (this.sort[i].name === fieldName) {
          this.sort[i].order = order
          if (this.sort[i].prio === -1) {
            this.sort[i].prio = this.getCurrectMaxSortPrio() + 1
          }
        }
      }
      this.sort.sort(compare)
      this.refreshContent()
    },
    getCurrectMaxSortPrio (): number {
      let r = -1
      for (let i = 0; i < this.sort.length; i++) {
        if (this.sort[i].prio > r) r = this.sort[i].prio
      }
      return r
    },
    setPage (number: number): void {
      if (this.pagination !== null) {
        this.pagination.number = number
        this.refreshContent()
      }
    },
    setPageSize (size: number): void {
      if (this.pagination !== null) {
        this.pagination.number = 0
        this.pagination.size = size
        this.refreshContent()
      }
    },
    refreshContent (): void {
      this.$nextTick((): void => {
        vueTemplateService.submitForm(this.formId)
      })
    },
    getButtonLabel (name: string): string {
      return (this.labels !== null) && (typeof this.labels.button !== 'undefined') && (typeof this.labels.button[name] !== 'undefined') ? this.labels.button[name] : (name.charAt(0).toUpperCase() + name.slice(1))
    },
    $emit4AllParents (eventName: string, data: any): void {
      vueTemplateService.$emit4AllParents(this, eventName, data)
    },
    $emit4Children (eventName: string, data: any): void {
      vueTemplateService.$emit4Children(this, eventName, data)
    },
    convertTextNewLines (text: string): any {
      return uiService.convertTextNewLines(text)
    },
    waitForContentRenderingEnd (selector: string, callback: () => void, counter?: number): void {
      generalService.waitForContentRenderingEnd(selector, callback)
    },
    searchForContentItem (id: any, callback: () => void): void {
      generalService.searchForContentItem(this, id, callback)
    },
    scrollToContentItem (item: any): void {
      const jItem = jQuery(item)
      if (typeof jItem !== 'undefined') {
        //in scroll-panel?
        if (jItem.parents('.scroll-panel.scroll-vertical').length > 0) {
          const jScrollPanel = jItem.parents('.scroll-vertical.scroll-panel')

          const scrollPanelOffset = jScrollPanel.offset()
          const bodyScrollTop = jQuery('html,body').scrollTop()
          let scrollPanelOffsetTop = 0
          const scrollPanelScrollTop = jScrollPanel.scrollTop()
          if (typeof scrollPanelOffset !== 'undefined' && typeof bodyScrollTop !== 'undefined') {
            scrollPanelOffsetTop = scrollPanelOffset.top - bodyScrollTop
          }

          const lastItemOffset = jItem.offset()
          let lastItemlOffsetTop = 0
          if (typeof lastItemOffset !== 'undefined' && typeof bodyScrollTop !== 'undefined' && typeof scrollPanelScrollTop !== 'undefined') {
            lastItemlOffsetTop = lastItemOffset.top - bodyScrollTop + scrollPanelScrollTop
          }
          jScrollPanel.animate({ scrollTop: lastItemlOffsetTop - scrollPanelOffsetTop }, 250)

        } else {
          const offset: any = jItem.offset()
          if (typeof offset !== 'undefined') {
            jQuery('html,body').animate({ scrollTop: offset.top }, 250)
          }
        }
      }
    },
    setDataContainerHeight (): void {
      uiService.setDataContainerHeight()
    },
    saveViewState ():void {
      // to implement in component
    },
    handleUserInput (e: any, pageName: string, params: {[key: string]: any}, clickMiddle?: boolean): void {
      this.saveViewState()
      if (clickMiddle) {
        const routeData = this.$router.resolve({name: pageName, params: params})
        window.open(routeData.href, '_blank')
      } else {
        if (e.ctrlKey) {
          const routeData = this.$router.resolve({name: pageName, params: params})
          window.open(routeData.href, '_blank')
        } else {
          this.$router.push({name: pageName, params: params})
        }
      }
      e.preventDefault()
    }
  }
})