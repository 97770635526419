import Vue from 'vue'
import { globalVar } from '@/scripts/own/_globalVar'
import { IPageData } from '@/types/app.d'
import { Component as Vuedal } from '@/components/vuedals/main'
import { dataService } from '@/scripts/services/DataService'
import { uiService } from '@/scripts/services/UIService'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'
import appHeader from '@/components/_header.vue'
import appFooter from '@/components/_footer.vue'
import { generalService } from '@/scripts/services/GeneralService'

export default Vue.extend({
  props: {},
  components: {
    appHeader,
    appFooter,
    Vuedal: Vuedal
  },
  data (): IPageData {
    return {
      id: '',
      sections: [],
      navigation: {
        tree: [],
        logout: {}
      },
      metaData: {
        title: null,
        url: null,
        description: null,
        image: null
      },
      dontShowImmediately: {},
      dataRequests: {}
    }
  },
  computed: {
    isAuthenticated (): boolean {
      return this.$store.state.user.authenticated
    },
    isUserPageOwner (): boolean {
      return this.isAuthenticated && this.$store.state.user.nickName === this.$router.currentRoute.params.nickName
    },
    isPandingAccountToDelete (): boolean {
      return sessionStorage.getItem('pendingAccountToDelete') === 'true'
    }
  },
  mounted (): void {
    //set dataRequests object
    if (typeof this.dataRequests === 'undefined') return
    for (let i = 0; i < this.$children.length; i++) {
      const componentName = this.$children[i].$options.name
      if (typeof componentName !== 'undefined' && typeof this.$children[i].$props !== 'undefined' && typeof this.$children[i].$props.sourceURL !== 'undefined') {
        this.dataRequests[componentName] = { successful: null, code: null, message: '' }
      }
    }
  },
  created (): void {
    this.$on('setRequestState', (data: any): void => {
      if (typeof data.name !== 'undefined' && typeof this.dataRequests !== 'undefined' && typeof this.dataRequests[data.name] !== 'undefined') {
        if (data.code === 200) this.dataRequests[data.name].successful = true
        if (data.code === 404) {
          this.dataRequests[data.name].successful = false
          this.dataRequests[data.name].message = '404 - Error: Content (' + data.name + ') was not found. More information is in browser console.'
        }
        this.dataRequests[data.name].code = data.code
      }
      let requestCount = 0
      let responseCount = 0
      let hasErrors = false
      const messages: Array<any> = []
      for (const name in this.dataRequests) {
        requestCount++
        if (this.dataRequests[name].successful !== null) responseCount++
        if (!this.dataRequests[name].successful) {
          hasErrors = true
          messages.push(this.dataRequests[name].message)
        }
      }
      if (requestCount !== 0 && responseCount != 0 && requestCount === responseCount && hasErrors) {
        let content = ''
        for (let i = 0; i < messages.length; i++) {
          content += '<p>' + messages[i] + '</p>'
        }
        uiService.showMessage('Request Error', content, null, 'xl')
      }
      generalService.log('> Requests States:', this.dataRequests)
    })
  },
  methods: {
    getGlobalLabel (type: string, name: string): string {
      return uiService.getGlobalLabel(type, name)
    },
    getNavigationLabel(name: string): string {
      return typeof globalVar.header.labels.item[name] !== 'undefined' ? globalVar.header.labels.item[name] : name
    },
    getServiceUrl (sourceId: string): string {
      return dataService.getServiceUrl(this, sourceId)
    },
    getNickName (): string {
      return typeof this.$router.currentRoute.params.nickName !== 'undefined' && this.$router.currentRoute.params.nickName ? this.$router.currentRoute.params.nickName : ''
    },
    getOwnerNickName (): string {
      return this.$store.state.user.authenticated ? this.$store.state.user.nickName : ''
    },
    getActivityId (): string {
      return typeof this.$router.currentRoute.params.activityId !== 'undefined' && this.$router.currentRoute.params.activityId ? this.$router.currentRoute.params.activityId.toString() : ''
    },
    getCollectionId (): string {
      return typeof this.$router.currentRoute.params.collectionId !== 'undefined' && this.$router.currentRoute.params.collectionId ? this.$router.currentRoute.params.collectionId.toString() : ''
    },
    getPointId (): string {
      return typeof this.$router.currentRoute.params.pointId !== 'undefined' && this.$router.currentRoute.params.pointId ? this.$router.currentRoute.params.pointId.toString() : ''
    },
    getAssetId (): string {
      return typeof this.$router.currentRoute.params.assetId !== 'undefined' && this.$router.currentRoute.params.assetId ? this.$router.currentRoute.params.assetId.toString() : ''
    },
    getTrackerId (): string {
      return typeof this.$router.currentRoute.params.trackerId !== 'undefined' && this.$router.currentRoute.params.trackerId ? this.$router.currentRoute.params.trackerId.toString() : ''
    },
    onActivityDataLoaded (vm: any, data: any): void {
      vm.activityDataLoaded = true
      vm.trackIcon = data !== null && data.results.length > 0 ? data.results[0].fields.trackIcon.value : null
    },
    $emit4Children (eventName: string, data: any): void {
      vueTemplateService.$emit4Children(this, eventName, data)
    },
    setPageHeigth (): void {
      const jHead = jQuery('header')
      const jFooter = jQuery('footer')
      const jPage = jQuery('.page')
      if (jHead.length === 0 || jFooter.length === 0) {
        setTimeout(() => {
          this.setPageHeigth()
        }, 100) 
      } else {
        const windowWidth = jQuery(window).width()
        if (typeof windowWidth !== 'undefined' && windowWidth > 767) {
          const windowHeight = jQuery(window).height()
          const headHeight = jHead.height()
          const footerHeight = jFooter.height()
          if (typeof windowHeight !== 'undefined' && typeof headHeight !== 'undefined' && typeof footerHeight !== 'undefined') {
            jPage.css({
              'height': (windowHeight - headHeight - footerHeight) + 'px'
            })
            this.$emit4Children('pageHeightSetted', {})
          }
        } else {
          jPage.css({
              'height': 'auto'
            })
        }
      }
      return
    }
  }
})