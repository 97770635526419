const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i)
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i)
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  iPhone: function () {
    return navigator.userAgent.match(/iPhone/i)
  },
  iPad: function () {
    return navigator.userAgent.match(/iPad/i)
  },
  iPod: function () {
    return navigator.userAgent.match(/iPod/i)
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i)
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Windows())
  }
}

const isTouchDevice = ('ontouchstart' in window || navigator.maxTouchPoints) ? isMobile.any() : false

class ProcessObserver {
  private isStarted = false
  private processFlag = false

  public inProcess (): void {
    this.processFlag = true
  }

  public start (callback: () => void, interval: number): boolean {
    if (!this.isStarted) {
      this.isStarted = true
      this.locator(callback, interval)
    }
    return false
  }

  private locator (callback: () => void, interval: number): boolean {
    if (this.processFlag === true) {
      this.processFlag = false
      window.setTimeout(() => { this.locator(callback, interval) }, interval)
    } else {
      this.isStarted = false
      callback()
    }
    return false
  }
}

function guid (): string {
  let d = new Date().getTime()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16)
  })
}

function openPopupWindow (tUrl: string, sbars: number, width: number, height: number, bnav: number): Window | null {
  let wx: number
  let wy: number
  let bnavStr: string
  let scrollb: string

  if (bnav === 1) {
    bnavStr = 'menubar=yes,toolbar=yes,'
  } else {
    bnavStr = ''
  }
  if (sbars === 1) {
    scrollb = 'scrollbars=yes,'
  } else {
    scrollb = ''
  }
  if (screen.availWidth >= width) {
    wx = (screen.availWidth - width) / 2
  } else {
    wx = 0
  }
  if (screen.availHeight >= height) {
    wy = (screen.availHeight - height) / 2
  } else {
    wy = 0
  }
  return window.open(tUrl, '', scrollb + bnavStr + 'width=' + width + ',height=' + height + ',left=' + wx + ',top=' + wy + ', resizable=yes')
}

const cookie = {
  get: function (name: string): string | null {
    const start = document.cookie.indexOf(name + '=')
    const len = start + name.length + 1
    if ((!start) && (name !== document.cookie.substring(0, name.length))) return null
    if (start === -1) return null
    let end = document.cookie.indexOf(';', len)
    if (end === -1) end = document.cookie.length
    return decodeURIComponent(document.cookie.substring(len, end))
  },
  set: function (name: string, value: any, expires: number, path?: string, domain?: string, secure?: boolean): void {
    expires = expires * 60 * 60 * 24 * 1000
    const today = new Date()
    const expiresDate = new Date(today.getTime() + (expires))
    const _cookieString = name + '=' + encodeURIComponent(value) +
    ((expires) ? ';expires=' + expiresDate.toUTCString() : '') +
    ((path) ? ';path=' + path : '') +
    ((domain) ? ';domain=' + domain : '') +
    ((secure) ? ';secure' : '')
    document.cookie = _cookieString
  }
}

function supportsVideo (): boolean {
  return !!document.createElement('video').canPlayType
}

function escapeHtml (str: string): string {
  const div = document.createElement('div')
  div.appendChild(document.createTextNode(str))
  return div.innerHTML
}

function unescapeHtml (escapedStr: string): string | null {
  const div = document.createElement('div')
  div.innerHTML = escapedStr
  const child = div.childNodes[0]
  return child ? child.nodeValue : ''
}

function isIFrame (): boolean {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

const supports = (function(): any {  
  const div = document.createElement('div')
  const vendors = 'Khtml Ms O Moz Webkit'.split(' ')
  const vendorsLen = vendors.length

  return function(prop: string) {
     if ( prop in div.style ) return true
     prop = prop.replace(/^[a-z]/, function(val: string) {  
        return val.toUpperCase()
     }); 
     var len = vendorsLen
     while(len-- >= 0) {
        if ( vendors[len] + prop in div.style ) {
           return true
        }  
     }  
     return false
  }  
})()

const prefixed = (function(): any {  
  const div = document.createElement('div')
  const vendors = 'Khtml Ms O Moz Webkit'.split(' ')
  const vendorsLen = vendors.length
  return function(prop: string) {  
     if ( prop in div.style ) return prop
     prop = prop.replace(/^[a-z]/, function(val) {  
        return val.toUpperCase()
     }); 
     var len = vendorsLen;
     while(len-- >= 0) {
        if ( vendors[len] + prop in div.style ) {
           return vendors[len] + prop
        }  
     }  
     return false
  }
})()

export { isMobile, isTouchDevice, ProcessObserver, guid, openPopupWindow, cookie, supportsVideo, escapeHtml, unescapeHtml, isIFrame, supports, prefixed }
