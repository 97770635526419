import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import PageLogin from '../views/PageLogin.vue'
import PageLogout from '../views/PageLogout.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'root',
    component: PageLogin,
    meta: {
      id: 'login',
      serviceURL: {
        login: '/api/v1/action/loginEvent/login',
        profile: '/api/v1/datasource/profile'
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: PageLogin,
    meta: {
      id: 'login',
      serviceURL: {
        login: '/api/v1/action/loginEvent/login',
        profile: '/api/v1/datasource/profile'
      }
    }
  },
  {
    path: '/p/logout',
    name: 'logout',
    component: PageLogout,
    meta: {
      id: 'logout',
      serviceURL: {
        logout: '/api/v1/action/loginEvent/logout'
      }
    }
  },
  {
    path: '/p/sso-login/;username=:username;ts=:ts;sig=:sig',
    name: 'ssoLogin',
    component: () => import('../views/SSOLogin.vue'),
    meta: {
      id: 'ssoLogin',
      serviceURL: {
        ssoLogin: '/api/v1/action/loginEvent/ssoLogin'
      }
    }
  },
  {
    path: '/password-reset',
    name: 'passwordForget',
    component: () => import('../views/PasswordForget.vue'),
    meta: {
      id: 'passwordForget',
      serviceURL: {
        passwordForget: '/api/v1/action/loginEvent/requestResetPassword'
      }
    }
  },
  {
    path: '/password-reset/:nickName/:digest',
    name: 'passwordReset',
    component: () => import('../views/PasswordReset.vue'),
    meta: {
      id: 'passwordReset',
      serviceURL: {
        passwordReset: '/api/v1/action/loginEvent/resetPassword',
        profile: '/api/v1/datasource/profile'
      }
    }
  },
  {
    path: '/signup/confirm/:digest',
    name: 'registration',
    component: () => import('../views/ConfirmRegistration.vue'),
    meta: {
      id: 'registration',
      serviceURL: {
        confirm: '/api/v1/action/registrationEvent/confirm'
      }
    }
  },
  {
    path: '/signup',
    name: 'digestRegistration',
    component: () => import('../views/DigestRegistration.vue'),
    meta: {
      id: 'digestRegistration',
      serviceURL: {
        digestRegistration: '/api/v1/action/registrationEvent/register'
      }
    }
  },
  {
    path: '/friendship/accept/:initiatorNickName/:nickName/:digest',
    name: 'registration',
    component: () => import('../views/AcceptFriendship.vue'),
    meta: {
      id: 'registration',
      serviceURL: {
        acceptFriendshipWithDigest: '/api/v1/action/friendshipEvents/acceptFriendshipWithDigest'
      }
    }
  },
  {
    path: '/p/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      serviceURL: {
        dashboardActivities: '/api/v1/datasource/dashboardActivities',
        activityFeed: '/api/v1/datasource/activityFeed',
        userStats: '/api/v1/datasource/userStats',
        acknowledge: '/api/v1/action/feedEvent/acknowledge',
        addLikeForActivity: '/api/v1/action/likeEvent/addLikeForActivity',
        removeLikeForActivity: '/api/v1/action/likeEvent/removeLikeForActivity',
        commentsForActivity: '/api/v1/datasource/commentsForActivity',
        addCommentForActivity: '/api/v1/action/commentEvent/addCommentForActivity',
        commentsForAsset: '/api/v1/datasource/commentsForAsset',
        addCommentForAsset: '/api/v1/action/commentEvent/addCommentForAsset',
        addLikeForComment: '/api/v1/action/likeEvent/addLikeForComment',
        removeLikeForComment: '/api/v1/action/likeEvent/removeLikeForComment',
        addLikeForAsset: '/api/v1/action/likeEvent/addLikeForAsset',
        removeLikeForAsset: '/api/v1/action/likeEvent/removeLikeForAsset',
        lockComment: '/api/v1/action/commentEvent/lockComment',
        unlockComment: '/api/v1/action/commentEvent/unlockComment',
        socialStats: '/api/v1/datasource/socialStats',
        requestFriendship: '/api/v1/action/friendshipEvents/requestFriendship',
        acceptFriendship: '/api/v1/action/friendshipEvents/acceptFriendship',
        cancelFriendship: '/api/v1/action/friendshipEvents/cancelFriendship',
        follow: '/api/v1/action/followerEvent/follow',
        unfollow: '/api/v1/action/followerEvent/unfollow'
      }
    }
  },
  {
    path: '/p/gallery',
    name: 'gallery',
    component: () => import('../views/MediaGallery.vue'),
    meta: {
      serviceURL: {
        profile: '/api/v1/datasource/profile',
        activity: '/api/v1/datasource/activity',
        galleryPoints: '/api/v1/datasource/galleryPoints',
        userStats: '/api/v1/datasource/userStats',
        commentsForAsset: '/api/v1/datasource/commentsForAsset',
        addCommentForAsset: '/api/v1/action/commentEvent/addCommentForAsset',
        addLikeForComment: '/api/v1/action/likeEvent/addLikeForComment',
        removeLikeForComment: '/api/v1/action/likeEvent/removeLikeForComment',
        addLikeForAsset: '/api/v1/action/likeEvent/addLikeForAsset',
        removeLikeForAsset: '/api/v1/action/likeEvent/removeLikeForAsset',
        lockComment: '/api/v1/action/commentEvent/lockComment',
        unlockComment: '/api/v1/action/commentEvent/unlockComment',
        socialStats: '/api/v1/datasource/socialStats',
        deleteAsset: '/api/v1/action/assetEvents/deleteAsset',
        updateImageCropping: '/api/v1/action/assetEvents/editCropping',
        updateAssetDescription: '/api/v1/action/assetEvents/editDescription',
        updateAssetPositions: '/api/v1/action/assetEvents/updateAssetPositions'
      }
    }
  },  
  {
    path: '/p/settings/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue'),
    meta: {
      id: 'updateProfile',
      serviceURL: {
        updateProfile: '/api/v1/action/usersEvents/updateProfile',
        profile: '/api/v1/datasource/profile'
      }
    }
  },
  {
    path: '/p/settings/tracker',
    name: 'trackers',
    component: () => import('../views/Trackers.vue'),
    meta: {
      id: 'trackers',
      serviceURL: {
        trackers: '/api/v1/datasource/trackers',
        deletePointSource: '/api/v1/action/trackerEvents/deleteTracker',
        tracker: '/api/v1/datasource/tracker/'
      }
    }
  },
  {
    path: '/p/settings/change-email',
    name: 'changeEmail',
    component: () => import('../views/UpdateEmail.vue'),
    meta: {
      id: 'updateEmail',
      serviceURL: {
        updateEmail: '/api/v1/action/emailChangeEvent/changeEmail'
      }
    }
  },
  {
    path: '/p/settings/change-password',
    name: 'changePassword',
    component: () => import('../views/UpdatePassword.vue'),
    meta: {
      id: 'updatePassword',
      serviceURL: {
        updatePassword: '/api/v1/action/loginEvent/requestResetPassword'
      }
    }
  },
  {
    path: '/p/settings/delete-account',
    name: 'deleteAccount',
    component: () => import('../views/DeleteAccount.vue'),
    meta: {
      id: 'deleteAccount',
      serviceURL: {
        deleteAccount: '/api/v1/action/userDeleteRequestEvents/submitDeleteRequest'
      }
    }
  },
  {
    path: '/p/settings/restore-account',
    name: 'restoreAccount',
    component: () => import('../views/RestoreAccount.vue'),
    meta: {
      id: 'restoreAccount',
      serviceURL: {
        restoreAccount: '/api/v1/action/userDeleteRequestEvents/cancelDeleteRequest'
      }
    }
  },  
  {
    path: '/p/profile/invite-users',
    name: 'inviteUsers',
    component: () => import('../views/InviteUsers.vue'),
    meta: {
      id: 'inviteUsers',
      serviceURL: {
        inviteUsers: '/api/v1/action/registrationDigestEvent/inviteUsers'
      }
    }
  },
  {
    path: '/p/change-email/:nickName/:digest',
    name: 'changeEmailConfirm',
    component: () => import('../views/UpdateEmailConfirm.vue'),
    meta: {
      id: 'changeEmailConfirmation',
      serviceURL: {
        updateEmailConfirm: '/api/v1/action/emailChangeEvent/confirmEmail',
        profile: '/api/v1/datasource/profile'
      }
    }
  },
  {
    path: '/p/collections',
    name: 'collections',
    component: () => import('../views/Collections.vue'),
    meta: {
      id: 'collections',
      serviceURL: {
        collections: '/api/v1/datasource/collections',
        deleteCollection: '/api/v1/action/collectionEvent/deleteCollection',
        activities: '/api/v1/datasource/activities'
      }
    }
  },
  {
    path: '/p/collections/create-collection',
    name: 'createCollection',
    component: () => import('../views/CreateCollection.vue'),
    meta: {
      id: 'createCollection',
      serviceURL: {
        createCollection: '/api/v1/action/collectionEvent/createCollection'
      }
    }
  },
  {
    path: '/p/collections/update-collection/:collectionId',
    name: 'updateCollection',
    component: () => import('../views/UpdateCollection.vue'),
    meta: {
      id: 'updateCollection',
      serviceURL: {
        updateCollection: '/api/v1/action/collectionEvent/updateCollection'
      }
    }
  },
  {
    path: '/p/activities',
    name: 'activities',
    component: () => import('../views/Activities.vue'),
    meta: {
      id: 'activities',
      serviceURL: {
        activities: '/api/v1/datasource/activities',
        deleteActivity: '/api/v1/action/activityEvents/deleteActivity'
      }
    }
  },
  {
    path: '/p/activities/activity/:activityId',
    name: 'activity',
    component: () => import('../views/Activity.vue'),
    meta: {
      id: 'activity',
      serviceURL: {
        activity: '/api/v1/datasource/activity?action=update',
        points: '/api/v1/datasource/points',
        addPointBefore: '/api/v1/action/pointEvents/insertBefore',
        addPointAfter: '/api/v1/action/pointEvents/insertAfter',
        deletePoints: '/api/v1/action/pointEvents/deletePoints',
        updatePointPosition: '/api/v1/action/pointEvents/updatePointPosition',
        bulkUpdatePointPosition: '/api/v1/action/pointEvents/bulkUpdatePointPosition',
        pointTimeZone: '/api/v1/datasource/pointTimeZone',
        point: '/api/v1/datasource/point?action=updatePoint',
        mergePoints: '/api/v1/action/pointEvents/mergePoints',
        pointAssets: '/api/v1/datasource/pointAssets',
        updateAssetDescription: '/api/v1/action/assetEvents/editDescription',
        updateAssetPositions: '/api/v1/action/assetEvents/updateAssetPositions',
        deleteAsset: '/api/v1/action/assetEvents/deleteAsset',
        updateImageCropping: '/api/v1/action/assetEvents/editCropping',
        createPointYoutubeAsset: '/api/v1/action/videoEvents/createYoutubeVideo'
      }
    }
  },
  {
    path: '/p/activities/create-activity',
    name: 'createActivity',
    component: () => import('../views/CreateActivity.vue'),
    meta: {
      id: 'createActivity',
      serviceURL: {
        createActivity: '/api/v1/action/activityEvents/createActivity'
      }
    }
  },
  {
    path: '/p/activities/update-activity/:activityId',
    name: 'updateActivity',
    component: () => import('../views/UpdateActivity.vue'),
    meta: {
      id: 'updateActivity',
      serviceURL: {
        updateActivity: '/api/v1/action/activityEvents/updateActivity'
      }
    }
  },
  {
    path: '/p/activities/activity/:activityId/update-point/:pointId',
    name: 'updateActivityPoint',
    component: () => import('../views/UpdatePoint.vue'),
    meta: {
      id: 'updatePoint',
      serviceURL: {
        updatePoint: '/api/v1/action/pointEvents/updatePoint',
        activity: '/api/v1/datasource/activity?action=update',
        points: '/api/v1/datasource/points',
        pointTimeZone: '/api/v1/datasource/pointTimeZone'
      }
    }
  },
  {
    path: '/p/activities/activity/:activityId/create-point',
    name: 'createActivityPoint',
    component: () => import('../views/CreatePoint.vue'),
    meta: {
      id: 'createPoint',
      serviceURL: {
        createPoint: '/api/v1/action/pointEvents/createPoint',
        activity: '/api/v1/datasource/activity?action=update',
        points: '/api/v1/datasource/points',
        pointTimeZone: '/api/v1/datasource/pointTimeZone'
      }
    }
  },
  {
    path: '/p/activities/activity/:activityId/point/:pointId/assets',
    name: 'activityPointAssets',
    component: () => import('../views/Assets.vue'),
    meta: {
      id: 'pointAssets',
      serviceURL: {
        pointAssets: '/api/v1/datasource/pointAssets',
        activity: '/api/v1/datasource/activity?action=update',
        point: '/api/v1/datasource/point?action=updatePoint',
        updateAssetPositions: '/api/v1/action/assetEvents/updateAssetPositions',
        deleteAsset: '/api/v1/action/assetEvents/deleteAsset'
      }

    }
  },
  {
    path: '/p/activities/activity/:activityId/point/:pointId/create-asset',
    name: 'createActivityPointAsset',
    component: () => import('../views/CreatePointAsset.vue'),
    meta: {
      id: 'createPointAsset',
      serviceURL: {
        createPointAsset: '/api/v1/action/assetEvents/createPointAsset',
        activity: '/api/v1/datasource/activity?action=update',
        point: '/api/v1/datasource/point?action=updatePoint'
      }

    }
  },
  {
    path: '/p/activities/activity/:activityId/point/:pointId/update-asset/:assetId',
    name: 'updateActivityPointAsset',
    component: () => import('../views/UpdatePointAsset.vue'),
    meta: {
      id: 'updatePointAsset',
      serviceURL: {
        updatePointAsset: '/api/v1/action/assetEvents/updatePointAsset',
        activity: '/api/v1/datasource/activity?action=update',
        point: '/api/v1/datasource/point?action=updatePoint'
      }
    }
  },
  {
    path: '/p/activities/activity/:activityId/point/:pointId/create-youtube-video-asset',
    name: 'createActivityPointYoutubeVideoAsset',
    component: () => import('../views/CreatePointYoutubeVideoAsset.vue'),
    meta: {
      id: 'createPointYoutubeVideoAsset',
      serviceURL: {
        createPointAsset: '/api/v1/action/videoEvents/createYoutubeVideo',
        activity: '/api/v1/datasource/activity?action=update',
        point: '/api/v1/datasource/point?action=updatePoint'
      }
    }
  },
  {
    path: '/p/activities/activity/:activityId/point/:pointId/update-youtube-video-asset/:assetId',
    name: 'updateActivityPointYoutubeVideoAsset',
    component: () => import('../views/UpdatePointYoutubeVideoAsset.vue'),
    meta: {
      id: 'updatePointYoutubeVideoAsset',
      serviceURL: {
        updatePointAsset: '/api/v1/action/videoEvents/updateYoutubeVideo',
        activity: '/api/v1/datasource/activity?action=update',
        point: '/api/v1/datasource/point?action=updatePoint'
      }
    }
  },
  {
    path: '/p/settings/create-tracker-marlink',
    name: 'createPointSourceMarlink',
    component: () => import('../views/CreateTrackerMarlink.vue'),
    meta: {
      id: 'createPointSource',
      serviceURL: {
        createPointSource: '/api/v1/action/trackerEvents/createTracker?type=MARLINK'
      }
    }
  },
  {
    path: '/p/settings/create-tracker-kml',
    name: 'createPointSourceKML',
    component: () => import('../views/CreateTrackerKML.vue'),
    meta: {
      id: 'createPointSource',
      serviceURL: {
        createPointSource: '/api/v1/action/trackerEvents/createTracker?type=KML'
      }
    }
  },
  {
    path: '/p/settings/update-tracker-marlink/:trackerId',
    name: 'updatePointSourceMarlink',
    component: () => import('../views/UpdateTrackerMarlink.vue'),
    meta: {
      id: 'updatePointSource',
      serviceURL: {
        updatePointSource: '/api/v1/action/trackerEvents/updateTracker?type=MARLINK'
      }
    }
  },
  {
    path: '/p/settings/update-tracker-mobile/:trackerId',
    name: 'updatePointSourceMobile',
    component: () => import('../views/UpdateTrackerMobile.vue'),
    meta: {
      id: 'updatePointSource',
      serviceURL: {
        updatePointSource: '/api/v1/action/trackerEvents/updateTracker?type=MOBILE'
      }
    }
  },
  {
    path: '/p/settings/update-tracker-kml/:trackerId',
    name: 'updatePointSourceKML',
    component: () => import('../views/UpdateTrackerKML.vue'),
    meta: {
      id: 'updatePointSource',
      serviceURL: {
        updatePointSource: '/api/v1/action/trackerEvents/updateTracker?type=KML'
      }
    }
  },
  {
    path: '/p/import-activity-points/:activityId',
    name: 'import-activity-points',
    component: () => import('../views/ImportActivityPoints.vue'),
    meta: {
      id: 'importActivityPoints',
      serviceURL: {
        activity: '/api/v1/datasource/activity?action=update',
        points: '/api/v1/datasource/points',
        upload: '/api/v1/action/fileImportEvents/uploadFiles/',
        review: '/api/v1/datasource/fileImportCustomizeFiles',
        resizeActivity: '/api/v1/action/fileImportEvents/activityResize',
        submitFiles: '/api/v1/action/fileImportEvents/submitFiles',
        fileImportPoints: '/api/v1/datasource/fileImportCustomizePoints',
        commitImport: '/api/v1/action/fileImportEvents/commitImport',
        cancelImport: '/api/v1/action/fileImportEvents/cancelImport',
        removeItem: '/api/v1/action/fileImportEvents/removeFiles',
        updateFilePoints: '/api/v1/action/fileImportEvents/updatePoints',
        confirmFiles: '/api/v1/action/fileImportEvents/confirmFiles',
        updatePointAsset: '/api/v1/action/fileImportEvents/updatePointAsset',
        updatePointAssetDescription: '/api/v1/action/fileImportEvents/updatePointAssetDescription'
      }
    }
  },  
  {
    path: '/p/profile/friends',
    name: 'friends',
    component: () => import('../views/Friends.vue'),
    meta: {
      id: 'trackers',
      serviceURL: {
        friends: '/api/v1/datasource/friends',
        acceptFriendship: '/api/v1/action/friendshipEvents/acceptFriendship',
        cancelFriendship: '/api/v1/action/friendshipEvents/cancelFriendship',
        waitingFriendshipRequests: '/api/v1/datasource/waitingFriendshipRequests',
        pendingFriendshipRequests: '/api/v1/datasource/pendingFriendshipRequests'
      }
    }
  },
  {
    path: '/p/profile/circles',
    name: 'groups',
    component: () => import('../views/Groups.vue'),
    meta: {
      id: 'groups',
      serviceURL: {
        groups: '/api/v1/datasource/groups',
        deleteGroup: '/api/v1/action/groupEvents/deleteGroup'
      }
    }
  },
  {
    path: '/p/profile/circles/create-circle',
    name: 'createGroup',
    component: () => import('../views/CreateGroup.vue'),
    meta: {
      id: 'createGroup',
      serviceURL: {
        createGroup: '/api/v1/action/groupEvents/createGroup'
      }
    }
  },
  {
    path: '/p/profile/circles/update-circle/:id',
    name: 'updateGroup',
    component: () => import('../views/UpdateGroup.vue'),
    meta: {
      id: 'updateGroup',
      serviceURL: {
        updateGroup: '/api/v1/action/groupEvents/editGroup'
      }
    }
  },
  {
    path: '/p/profile/follow',
    name: 'follow',
    component: () => import('../views/Follow.vue'),
    meta: {
      id: 'follow',
      serviceURL: {
        followers: '/api/v1/datasource/followers',
        followed: '/api/v1/datasource/followed',
        follow: '/api/v1/action/followerEvent/follow',
        unfollow: '/api/v1/action/followerEvent/unfollow'
      }
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue'),
    meta: {
      serviceURL: {
        login: '/api/v1/action/loginEvent/login'
      }
    }
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/Help.vue'),
    meta: {
      serviceURL: {
        login: '/api/v1/action/loginEvent/login'
      }
    }
  },
  {
    path: '/help/contact',
    name: 'publicContact',
    component: () => import('../views/PublicContact.vue'),
    meta: {
      id: 'publicConatct',
      serviceURL: {
        createContact: '/api/v1/action/contactEvent/createContact'
      }
    }
  },
  {
    path: '/help/complaint',
    name: 'publicComplaint',
    component: () => import('../views/PublicComplaint.vue'),
    meta: {
      id: 'publicComplaint',
      serviceURL: {
        createComplaint: '/api/v1/action/contactEvent/createComplaint'
      }
    }
  },
  {
    path: '/legal/data-protection-settings',
    name: 'cookieSettings',
    component: () => import('../views/CookieSettings.vue'),
    meta: {
      serviceURL: {
        login: '/api/v1/action/loginEvent/login'
      }
    }
  },
  {
    path: '/legal/imprint',
    name: 'imprint',
    component: () => import('../views/Imprint.vue'),
    meta: {
      serviceURL: {
        login: '/api/v1/action/loginEvent/login'
      }
    }
  },
  {
    path: '/legal/privacy',
    name: 'privacy',
    component: () => import('../views/Privacy.vue'),
    meta: {
      serviceURL: {
        login: '/api/v1/action/loginEvent/login'
      }
    }
  },
  {
    path: '/legal/terms',
    name: 'terms',
    component: () => import('../views/Terms.vue'),
    meta: {
      serviceURL: {
        login: '/api/v1/action/loginEvent/login'
      }
    }
  },
  {
    path: '/newsletter/unsubscribe/:nickName/:digest',
    name: 'unsubscribe_newsletter',
    component: () => import('../views/UnsubscribeNewsletter.vue'),
    meta: {
      serviceURL: {
        unsubscribe: '/api/v1/action/usersEvents/unsubscribeNewsletter'
      }
    }
  },
  {
    path: '/widget/:nickName/collection/:collectionId',
    name: 'publicCollectionWidget',
    component: () => import('../views/PublicCollectionWidget.vue'),
    meta: {
      id: 'publicCollection',
      serviceURL: {
        collection: '/api/v1/datasource/publicCollection',
        activities: '/api/v1/datasource/publicActivities',
        collectionPoints: '/api/v1/datasource/collectionPoints'
      }
    }
  },
  {
    path: '/widget/:nickName/activity/:activityId',
    name: 'publicActivityWidget',
    component: () => import('../views/PublicActivityWidget.vue'),
    meta: {
      id: 'publicActivity',
      serviceURL: {
        activity: '/api/v1/datasource/publicActivity',
        mainActivity: '/api/v1/datasource/mainActivity',
        points: '/api/v1/datasource/points'
      }
    }
  },
  {
    path: '/widget/:nickName/collection/:collectionId/activity/:activityId',
    name: 'publicActivityOfCollectionWidget',
    component: () => import('../views/PublicActivityWidget.vue'),
    meta: {
      id: 'publicActivity',
      serviceURL: {
        activity: '/api/v1/datasource/publicActivity',
        mainActivity: '/api/v1/datasource/mainActivity',
        points: '/api/v1/datasource/points'
      }
    }
  },
  {
    path: '/widget/:nickName/activity/:activityId/point/:pointId',
    name: 'publicPointWidget',
    component: () => import('../views/PublicPointWidget.vue'),
    meta: {
      id: 'publicPoint',
      serviceURL: {
        point: '/api/v1/datasource/showPoint',
        pointAssets: '/api/v1/datasource/pointAssets',
        points: '/api/v1/datasource/points'
      }
    }
  },
  {
    path: '/widget/:nickName/collection/:collectionId/activity/:activityId/point/:pointId',
    name: 'publicPointOfCollectionWidget',
    component: () => import('../views/PublicPointWidget.vue'),
    meta: {
      id: 'publicPointWidget',
      serviceURL: {
        point: '/api/v1/datasource/showPoint',
        pointAssets: '/api/v1/datasource/pointAssets',
        points: '/api/v1/datasource/points'
      }
    }
  },  
  {
    path: '/:nickName',
    name: 'publicPage',
    component: () => import('../views/PublicPage.vue'),
    meta: {
      id: 'publicPage',
      serviceURL: {
        userProfileActivity: '/api/v1/datasource/userProfileActivity',
        userProfileActivityFeed: '/api/v1/datasource/userProfileActivityFeed',
        profile: '/api/v1/datasource/profile',
        updateAboutMe: '/api/v1/action/usersEvents/updateAboutMe',
        deleteProfileImage: '/api/v1/action/usersEvents/deleteProfileImage',
        requestFriendship: '/api/v1/action/friendshipEvents/requestFriendship',
        acceptFriendship: '/api/v1/action/friendshipEvents/acceptFriendship',
        cancelFriendship: '/api/v1/action/friendshipEvents/cancelFriendship',
        commentsForUser: '/api/v1/datasource/commentsForUser',
        addCommentForUser: '/api/v1/action/commentEvent/addCommentForUser',
        commentsForAsset: '/api/v1/datasource/commentsForAsset',
        addCommentForAsset: '/api/v1/action/commentEvent/addCommentForAsset',
        addLikeForComment: '/api/v1/action/likeEvent/addLikeForComment',
        removeLikeForComment: '/api/v1/action/likeEvent/removeLikeForComment',
        addLikeForAsset: '/api/v1/action/likeEvent/addLikeForAsset',
        removeLikeForAsset: '/api/v1/action/likeEvent/removeLikeForAsset',
        lockComment: '/api/v1/action/commentEvent/lockComment',
        unlockComment: '/api/v1/action/commentEvent/unlockComment',
        follow: '/api/v1/action/followerEvent/follow',
        unfollow: '/api/v1/action/followerEvent/unfollow',
        userStats: '/api/v1/datasource/userStats',
        socialStats: '/api/v1/datasource/socialStats'
      }
    }
  },
  {
    path: '/:nickName/collections',
    name: 'publicCollections',
    component: () => import('../views/PublicCollections.vue'),
    meta: {
      id: 'publicCollections',
      serviceURL: {
        collections: '/api/v1/datasource/publicCollections',
        profile: '/api/v1/datasource/profile',
        requestFriendship: '/api/v1/action/friendshipEvents/requestFriendship',
        acceptFriendship: '/api/v1/action/friendshipEvents/acceptFriendship',
        cancelFriendship: '/api/v1/action/friendshipEvents/cancelFriendship',
        deleteCollection: '/api/v1/action/collectionEvent/deleteCollection',
        follow: '/api/v1/action/followerEvent/follow',
        unfollow: '/api/v1/action/followerEvent/unfollow'
      }
    }
  },
  {
    path: '/:nickName/collection/:collectionId/legacy',
    name: 'publicCollectionLegacy',
    component: () => import('../views/PublicCollectionLegacy.vue'),
    meta: {
      id: 'publicCollectionLegacy',
      serviceURL: {
        collection: '/api/v1/datasource/publicCollection',
        activities: '/api/v1/datasource/publicActivities',
        collectionPoints: '/api/v1/datasource/collectionPoints',
        profile: '/api/v1/datasource/profile'
      }
    }
  },
  {
    path: '/:nickName/:urlTitle/collection/:collectionId',
    name: 'publicCollection',
    component: () => import('../views/PublicCollection.vue'),
    meta: {
      id: 'publicCollection',
      serviceURL: {
        collection: '/api/v1/datasource/publicCollection',
        activity: '/api/v1/datasource/publicActivity',
        activities: '/api/v1/datasource/publicActivities',
        collectionPoints: '/api/v1/datasource/collectionPoints',
        profile: '/api/v1/datasource/profile',
        addLikeForActivity: '/api/v1/action/likeEvent/addLikeForActivity',
        removeLikeForActivity: '/api/v1/action/likeEvent/removeLikeForActivity',
        commentsForActivity: '/api/v1/datasource/commentsForActivity',
        addCommentForActivity: '/api/v1/action/commentEvent/addCommentForActivity',
        commentsForAsset: '/api/v1/datasource/commentsForAsset',
        addCommentForAsset: '/api/v1/action/commentEvent/addCommentForAsset',
        addLikeForComment: '/api/v1/action/likeEvent/addLikeForComment',
        removeLikeForComment: '/api/v1/action/likeEvent/removeLikeForComment',
        addLikeForAsset: '/api/v1/action/likeEvent/addLikeForAsset',
        removeLikeForAsset: '/api/v1/action/likeEvent/removeLikeForAsset',
        lockComment: '/api/v1/action/commentEvent/lockComment',
        unlockComment: '/api/v1/action/commentEvent/unlockComment',
        socialStats: '/api/v1/datasource/socialStats',
        deleteAsset: '/api/v1/action/assetEvents/deleteAsset',
        updateImageCropping: '/api/v1/action/assetEvents/editCropping',
        updateAssetDescription: '/api/v1/action/assetEvents/editDescription',
        updateAssetPositions: '/api/v1/action/assetEvents/updateAssetPositions',
        requestFriendship: '/api/v1/action/friendshipEvents/requestFriendship',
        acceptFriendship: '/api/v1/action/friendshipEvents/acceptFriendship',
        cancelFriendship: '/api/v1/action/friendshipEvents/cancelFriendship',
        follow: '/api/v1/action/followerEvent/follow',
        unfollow: '/api/v1/action/followerEvent/unfollow',
        updateActivityProps: '/api/v1/action/activityEvents/updateDescription',
        updatePointProps: '/api/v1/action/pointEvents/updatePointDescription'
      }
    }
  },
  {
    path: '/:nickName/collection/:collectionId',
    name: 'publicCollectionOld',
    component: () => import('../views/PublicCollection.vue'),
    meta: {
      id: 'publicCollectionOld',
      serviceURL: {
        collection: '/api/v1/datasource/publicCollection',
        activity: '/api/v1/datasource/publicActivity',
        activities: '/api/v1/datasource/publicActivities',
        collectionPoints: '/api/v1/datasource/collectionPoints',
        profile: '/api/v1/datasource/profile',
        addLikeForActivity: '/api/v1/action/likeEvent/addLikeForActivity',
        removeLikeForActivity: '/api/v1/action/likeEvent/removeLikeForActivity',
        commentsForActivity: '/api/v1/datasource/commentsForActivity',
        addCommentForActivity: '/api/v1/action/commentEvent/addCommentForActivity',
        commentsForAsset: '/api/v1/datasource/commentsForAsset',
        addCommentForAsset: '/api/v1/action/commentEvent/addCommentForAsset',
        addLikeForComment: '/api/v1/action/likeEvent/addLikeForComment',
        removeLikeForComment: '/api/v1/action/likeEvent/removeLikeForComment',
        addLikeForAsset: '/api/v1/action/likeEvent/addLikeForAsset',
        removeLikeForAsset: '/api/v1/action/likeEvent/removeLikeForAsset',
        lockComment: '/api/v1/action/commentEvent/lockComment',
        unlockComment: '/api/v1/action/commentEvent/unlockComment',
        socialStats: '/api/v1/datasource/socialStats',
        follow: '/api/v1/action/followerEvent/follow',
        unfollow: '/api/v1/action/followerEvent/unfollow'
      }
    }
  },
  {
    path: '/:nickName/collection/:collectionId/activity/:activityId/point/:pointId',
    name: 'publicPointOfCollection',
    component: () => import('../views/PublicPoint.vue'),
    meta: {
      id: 'publicPointOfCollection',
      serviceURL: {
        point: '/api/v1/datasource/showPoint',
        pointAssets: '/api/v1/datasource/pointAssets',
        points: '/api/v1/datasource/points',
        profile: '/api/v1/datasource/profile',
        activity: '/api/v1/datasource/publicActivity'
      }
    }
  },
  {
    path: '/:nickName/activities',
    name: 'publicActivities',
    component: () => import('../views/PublicActivities.vue'),
    meta: {
      id: 'publicActivities',
      serviceURL: {
        profile: '/api/v1/datasource/profile',
        requestFriendship: '/api/v1/action/friendshipEvents/requestFriendship',
        acceptFriendship: '/api/v1/action/friendshipEvents/acceptFriendship',
        cancelFriendship: '/api/v1/action/friendshipEvents/cancelFriendship',
        mainActivity: '/api/v1/datasource/mainActivity',
        recentActivities: '/api/v1/datasource/recentActivities',
        points: '/api/v1/datasource/points',
        commentsForActivity: '/api/v1/datasource/commentsForActivity',
        addCommentForActivity: '/api/v1/action/commentEvent/addCommentForActivity',
        addLikeForActivity: '/api/v1/action/likeEvent/addLikeForActivity',
        removeLikeForActivity: '/api/v1/action/likeEvent/removeLikeForActivity',
        addLikeForComment: '/api/v1/action/likeEvent/addLikeForComment',
        removeLikeForComment: '/api/v1/action/likeEvent/removeLikeForComment',
        activity: '/api/v1/datasource/publicActivity',
        lockComment: '/api/v1/action/commentEvent/lockComment',
        unlockComment: '/api/v1/action/commentEvent/unlockComment',
        socialStats: '/api/v1/datasource/socialStats',
        deleteActivity: '/api/v1/action/activityEvents/deleteActivity',
        follow: '/api/v1/action/followerEvent/follow',
        unfollow: '/api/v1/action/followerEvent/unfollow'
      }
    }
  },
  {
    path: '/:nickName/:urlTitle/activity/:activityId',
    name: 'publicActivity',
    component: () => import('../views/PublicActivity.vue'),
    meta: {
      id: 'publicActivity',
      serviceURL: {
        profile: '/api/v1/datasource/profile',
        activity: '/api/v1/datasource/publicActivity',
        mainActivity: '/api/v1/datasource/mainActivity',
        points: '/api/v1/datasource/points',
        addLikeForActivity: '/api/v1/action/likeEvent/addLikeForActivity',
        removeLikeForActivity: '/api/v1/action/likeEvent/removeLikeForActivity',
        commentsForActivity: '/api/v1/datasource/commentsForActivity',
        addCommentForActivity: '/api/v1/action/commentEvent/addCommentForActivity',
        commentsForAsset: '/api/v1/datasource/commentsForAsset',
        addCommentForAsset: '/api/v1/action/commentEvent/addCommentForAsset',
        addLikeForComment: '/api/v1/action/likeEvent/addLikeForComment',
        removeLikeForComment: '/api/v1/action/likeEvent/removeLikeForComment',
        addLikeForAsset: '/api/v1/action/likeEvent/addLikeForAsset',
        removeLikeForAsset: '/api/v1/action/likeEvent/removeLikeForAsset',
        lockComment: '/api/v1/action/commentEvent/lockComment',
        unlockComment: '/api/v1/action/commentEvent/unlockComment',
        socialStats: '/api/v1/datasource/socialStats',
        follow: '/api/v1/action/followerEvent/follow',
        unfollow: '/api/v1/action/followerEvent/unfollow',
        deleteAsset: '/api/v1/action/assetEvents/deleteAsset',
        updateImageCropping: '/api/v1/action/assetEvents/editCropping',
        updateAssetDescription: '/api/v1/action/assetEvents/editDescription',
        updateAssetPositions: '/api/v1/action/assetEvents/updateAssetPositions',
        updateActivityProps: '/api/v1/action/activityEvents/updateDescription',
        updatePointProps: '/api/v1/action/pointEvents/updatePointDescription'
      }
    }
  },
  {
    path: '/:nickName/activity/:activityId',
    name: 'publicActivityOld',
    component: () => import('../views/PublicActivity.vue'),
    meta: {
      id: 'publicActivityOld',
      serviceURL: {
        profile: '/api/v1/datasource/profile',
        activity: '/api/v1/datasource/publicActivity',
        mainActivity: '/api/v1/datasource/mainActivity',
        points: '/api/v1/datasource/points',
        addLikeForActivity: '/api/v1/action/likeEvent/addLikeForActivity',
        removeLikeForActivity: '/api/v1/action/likeEvent/removeLikeForActivity',
        commentsForActivity: '/api/v1/datasource/commentsForActivity',
        addCommentForActivity: '/api/v1/action/commentEvent/addCommentForActivity',
        commentsForAsset: '/api/v1/datasource/commentsForAsset',
        addCommentForAsset: '/api/v1/action/commentEvent/addCommentForAsset',
        addLikeForComment: '/api/v1/action/likeEvent/addLikeForComment',
        removeLikeForComment: '/api/v1/action/likeEvent/removeLikeForComment',
        addLikeForAsset: '/api/v1/action/likeEvent/addLikeForAsset',
        removeLikeForAsset: '/api/v1/action/likeEvent/removeLikeForAsset',
        lockComment: '/api/v1/action/commentEvent/lockComment',
        unlockComment: '/api/v1/action/commentEvent/unlockComment',
        socialStats: '/api/v1/datasource/socialStats',
        follow: '/api/v1/action/followerEvent/follow',
        unfollow: '/api/v1/action/followerEvent/unfollow'
      }
    }
  },
  {
    path: '/:nickName/activity/:activityId/legacy',
    name: 'publicActivityLegacy',
    component: () => import('../views/PublicActivityLegacy.vue'),
    meta: {
      id: 'publicActivityLegacy',
      serviceURL: {
        activity: '/api/v1/datasource/publicActivity',
        mainActivity: '/api/v1/datasource/mainActivity',
        points: '/api/v1/datasource/points',
        profile: '/api/v1/datasource/profile'
      }
    }
  },
  {
    path: '/:nickName/activity/:activityId/point/:pointId',
    name: 'publicPoint',
    component: () => import('../views/PublicPoint.vue'),
    meta: {
      id: 'publicPoint',
      serviceURL: {
        activity: '/api/v1/datasource/publicActivity',
        point: '/api/v1/datasource/showPoint',
        pointAssets: '/api/v1/datasource/pointAssets',
        points: '/api/v1/datasource/points',
        profile: '/api/v1/datasource/profile'
      }
    }
  },
  {
    path: '/:nickName/no-account',
    name: 'customer-404',
    component: () => import('../views/No-Account.vue'),
    meta: {
      id: 'customer-404',
      serviceURL: {
      }
    }
  },
  {
    path: '/:nickName/user-not-authorized-or-resource-not-exist',
    name: 'customer-473',
    component: () => import('../views/Not-Authorized-Exist.vue'),
    meta: {
      id: 'customer-473',
      serviceURL: {
      }
    }
  },
  {
    path: '/p/*',
    name: '404-page',
    component: () => import('../views/404-Page.vue'),
    meta: {
      id: '404-page',
      serviceURL: {
      }
    }
  },
  {
    path: '/:nickName/*',
    name: 'customer-page-404',
    component: () => import('../views/404-Page.vue'),
    meta: {
      id: 'customer-page-404',
      serviceURL: {
      }
    }
  }
]
export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
