<template>
  <div class="person-search container" >
    <h1 v-html="headerLabels.searchForm.headline"></h1>
    <div class="row mb-4">
      <div class="col-12">
        <input id="person-search" name="q" v-model="q" type="text" :placeholder="headerLabels.searchForm.placeholder" class="form-control" @keydown="(e) => onKeyDown(e)">
      </div>
    </div>
    <div class="results" v-bind:class="{loading: showLoader}">
      <fragment v-for="item in results" :key="item.nickName">
        <router-link class="results-item"  v-bind:to="{name: 'publicPage', params: {nickName: item.nickName}}" event @click.native.prevent="(e) => onClickCard(e, {nickName: item.nickName, realName: item.realName, image: item.image})">
          <profilePictureThumbnail :linking="false" :nickName="item.nickName" :pictureUrl="item.image"></profilePictureThumbnail>
          <div class="text" v-html="getName(item.nickName, item.realName)"></div>
        </router-link>
      </fragment>
      <div v-if="results.length === 0 && getSearchResultsFromStorage().length > 0">
        <h4 v-html="headerLabels.searchForm.lastResults"></h4>
        <fragment v-for="item in getSearchResultsFromStorage()" :key="item.nickName">
          <router-link class="results-item"  v-bind:to="{name: 'publicPage', params: {nickName: item.nickName}}" event @click.native.prevent="(e) => onClickCard(e, {nickName: item.nickName, realName: item.realName, image: item.image})">
            <profilePictureThumbnail :linking="false" :nickName="item.nickName" :pictureUrl="item.image"></profilePictureThumbnail>
            <div class="text" v-html="getName(item.nickName, item.realName)"></div>
          </router-link>
        </fragment>
      </div>
    </div>
    <ContentErrorMessage v-if="isDataRequestError"></ContentErrorMessage>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import axios from 'axios'
import { globalVar } from '@/scripts/own/_globalVar'
import { Bus as VuedalsBus } from '@/components/vuedals/main'
import ContentErrorMessage from '@/components/_content_error_message.vue'
import profilePictureThumbnail from '@/components/_profile_picture_thumbnail.vue'

export default Vue.extend({
  name: 'DatasourcePersonSearch',
  components: {
    ContentErrorMessage,
    profilePictureThumbnail
  },
  data (): any {
    return {
      q: '',
      keyDowned: false,
      showLoader: false,
      isDataRequestError: null,
      results: []
    }
  },
  computed: {
    headerLabels (): {[key: string]: any} {
      return globalVar.header.labels
    },
  },
  updated (): void {
    if (this.keyDowned) {
      if (this.q.length >= 3) this.fetchData(globalVar.serviceURL.users, this.q)
      else this.results = []
    }
    this.keyDowned = false
  },
  mounted (): void {
    setTimeout(() => {
      document.getElementById('person-search')?.focus()
    }, 100)
  },
  methods: {
    fetchData (serviceUrl: string, q?: string): void {
      this.showLoader = true
      axios({
          url: serviceUrl,
          method: 'get',
          params: {
            q: q
          }
        })
        .then((response) => {
          //console.info('axios response !!!', response)
          if (response.status === 200) {
            this.results = []
            for (let i = 0; i < response.data.length; i++) {
              this.results.push(response.data[i])
            }
          }
          this.showLoader = false
        })
        .catch((error) => {
          this.showLoader = false
        })
    },
    onKeyDown (e: any): void {
      this.keyDowned = true
    },
    getName(nickName: string, realName?: string): string {
      const regEx = new RegExp('(' + this.q + ')', "ig")
      nickName = nickName.replace(regEx, '<strong>$1</strong>')
      if (typeof realName !== 'undefined' && realName !== '') realName = realName.replace(regEx, '<strong>$1</strong>')
      return realName && realName !== '' ? realName + ' (' + nickName + ')' : nickName
    },
    getNickName (): string {
      return this.$store.state.user.authenticated ? this.$store.state.user.nickName : 'anonymous$'
    },
    addSearchResultInStorage(item: any): void {
      if (typeof localStorage !== 'undefined') {
        const nickName = this.getNickName()
        let jsonState: {[key: string]: any} = {}
        let uf = false

        const savedState = localStorage.getItem('LAST-SEARCH-RESULTS')
        if (savedState) {
          jsonState = JSON.parse(savedState)
          if (jsonState) {
            for (const user in jsonState) {
              if (user === nickName) {
                uf = true
                if (typeof jsonState[user] === 'object') {
                  let foundedIndex
                  for (let i = 0; i < jsonState[user].length; i++) {
                    if (jsonState[user][i].nickName === item.nickName) {
                      foundedIndex = i
                    }
                  }
                  if (typeof foundedIndex !== 'undefined') jsonState[user].splice(foundedIndex, 1)
                  let r = jsonState[user].slice()
                  r = r.reverse()
                  r.push(item)
                  r = r.reverse()
                  r = r.slice(0, 5)
                  jsonState[user] = r
                }
              }
            }
          }
        }
        if (!uf) {
          jsonState[nickName] = []
          jsonState[nickName].push(item)
        }
        localStorage.setItem('LAST-SEARCH-RESULTS', JSON.stringify(jsonState))
      }
    },
    onClickCard (e: any, item: any): void {
      try {
        VuedalsBus.$emit('dismiss')
      } catch (error: any) {
        console.error(error)
      }
      this.addSearchResultInStorage(item)
      if (e.ctrlKey) {
        const routeData = this.$router.resolve({name: 'publicPage', params: {nickName: item.nickName}})
        window.open(routeData.href, '_blank')
      } else {
        if (this.$router.currentRoute.name !== 'publicPage' || (this.$router.currentRoute.name === 'publicPage' && this.$router.currentRoute.params.nickName !== item.nickName)) this.$router.push({name: 'publicPage', params: {nickName: item.nickName}})
      }
    },
    getSearchResultsFromStorage(): Array<any> {
      if (typeof localStorage !== 'undefined') {
        const nickName = this.getNickName()
        let jsonState: {[key: string]: any} = {}
        const savedState = localStorage.getItem('LAST-SEARCH-RESULTS')
        if (savedState) {
          jsonState = JSON.parse(savedState)
          if (jsonState) {
            for (const user in jsonState) {
              if (user === nickName) {
                return jsonState[user]
              }
            }
          }
        }
      }
      return []
    }
  }
})
</script>
