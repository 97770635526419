import Vue, { PropType }  from 'vue'
import {Bus as VuedalsBus } from '@/components/vuedals/main'
import { IComponentPopupData } from '@/types/app.d'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'
import { generalService } from '@/scripts/services/GeneralService'
import { dataService } from '@/scripts/services/DataService'
import { uiService } from '@/scripts/services/UIService'

export default Vue.extend({
  props: {
    sourceURL: String,
    componentToUpdate: Object,
    contentText: String,
    executeDirectly: Boolean,
    parameters: Object as PropType<{ [key: string]: any }>
  },
  data(): IComponentPopupData {
    return {
      id: '',
      guid: '',
      originId: '',
      execute: '',
      title: '',
      filter: {},
      setFilters: [],
      field: {},
      links: [],
      pagination: null,
      params: {},
      permissions: {},
      results: [],
      sort: [],
      labels: {},
      layout: {},
      messages: null,
      images: null,
      submited: false,
      isDataRequestError: null
    }
  },
  computed: {
    locale (): string {
      return generalService.getLocale()
    },
    componentSourceURL (): string {
      let sourceURL = ''
      if (typeof this.sourceURL === 'undefined') {
        const currentRoute: any = this.$router.currentRoute
        sourceURL = ((typeof currentRoute.meta !== 'undefined') && (typeof currentRoute.meta.sourceURL !== 'undefined')) ? currentRoute.meta.sourceURL : ''
      } else sourceURL = this.sourceURL
      return sourceURL
    },
    fieldsEnabled (): boolean {
      return (this.field !== null && (Object.keys(this.field).length > 0))
    },
    labelsEnabled (): boolean {
      return (this.labels !== null && (Object.keys(this.labels).length > 0))
    },
    formId (): string {
      return this.id + '_' + this.guid
    },
    showLoader ():boolean {
      return (this.id === '' && !this.isDataRequestError)
    },
    confirmationText(): string {
      return this.labels.title ? this.labels.title : this.contentText
    },
    executeUrl (): string {
      if (typeof this.execute !== 'undefined') {
        let r = this.execute.split(';')[0]
        if (r.substring(r.length - 1, 1) === '/') r = r.substring(0, r.length-1)
        return r
      } else {
        return ''
      }
    }
  },
  created(): void {
    this.$on('afterSendData', (data: any): void => {
      generalService.log('Event "afterSendData" for "' + data.id + '"')
      const successfully = generalService.wasRequestSuccessfully(data)
      if (!generalService.haveFieldsErrors(data.field) && successfully) {
        try {
          VuedalsBus.$emit('dismiss')
        } catch (error: any) {
          VuedalsBus.$emit('close')
        }
        this.componentToUpdate.$emit('afterChangeDatasource', { successfully: successfully, data: data })
      }
    })
  },
  mounted (): void {
    generalService.log('Action component "' + this.$options.name + '" mounted !', this.componentSourceURL, this.componentToUpdate)
    
    this.guid = generalService.createGuid()
    this.submited = false

    this.fetchData()
  },
  updated(): void {
    this.$nextTick((): void => {
      generalService.log('Action component "' + this.$options.name + '*" updated', this.parameters)
      if (this.id !== '') {
        uiService.initForm(this, '#' + this.formId, (data) => {
          generalService.log('submitForm Callback', data)
          dataService.updateComponentData(this, data)
        }).then(() => {
          if (this.executeDirectly === true) {
            if (!this.submited) {
              vueTemplateService.submitForm(this.formId)
              this.submited = true
            }
          }
        })
      }
    })
  },
  methods: {
    getRequestParams (): {[key: string]: any} {
      return this.parameters ? this.parameters : {}
    },
    fetchData (): void {
      if (this.componentSourceURL) {
        const params = this.getRequestParams()
        dataService.getContentData4Action(this, this.componentSourceURL, params, (data: any) => {
          dataService.updateComponentData(this, data)
        })
      }
    },
    cancel (e: any): void {
      e.stopPropagation()
      try {
        VuedalsBus.$emit('dismiss')
      } catch (error: any) {
        VuedalsBus.$emit('close')
      }
    },
    $emit4AllParents (eventName: string, data: any): void {
      vueTemplateService.$emit4AllParents(this, eventName, data)
    },
    registerRequestState (code: number): void {
      this.isDataRequestError = code !== 200 ? true : false
      this.$emit4AllParents('setRequestState', {
        name: this.$options.name,
        code: code
      })
    },
  }
})