/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The possible types for a field/action-field.
 */
export enum FieldType {
    TEXT = 'text',
    LONGTEXT = 'longtext',
    RICHTEXT = 'richtext',
    PASSWORD = 'password',
    URL = 'url',
    INT = 'int',
    LONG = 'long',
    DECIMAL = 'decimal',
    CHECKBOX = 'checkbox',
    COORDINATE = 'coordinate',
    DATE = 'date',
    FILE = 'file',
    FILE_MULTIPLE = 'file_multiple',
    IMAGE = 'image',
    LINKPANEL = 'linkpanel',
    LIST_CHECKBOX = 'list_checkbox',
    LIST_RADIO = 'list_radio',
    LIST_SELECT = 'list_select',
    LIST_TEXT = 'list_text',
    OBJECT = 'object',
    LIST_OBJECT = 'list_object',
}
