import jQuery from 'jquery'
import { ISortItem } from '../../types/app.d'

class VueTemplateService {
  public getDatasourceState (response: any, newPaginationState: any, newSortState: any): any {
    const r: any = {}

    const filterState = this.getFilterState(response)
    const paginationState = this.getPaginationState(response, newPaginationState)
    const sortState = this.getSortState(response, newSortState)

    const paginationSortState = this.converInMatrixParam(this.concatStateData(paginationState, sortState))

    jQuery.extend(true, r, filterState, paginationSortState)

    return r
  }
  public getPaginationSortState (response: any, newPaginationState: any, newSortState: any): any {
    const r: any = {}

    const paginationState = this.getPaginationState(response, newPaginationState)
    const sortState = this.getSortState(response, newSortState)
    const paginationSortState = this.concatStateData(paginationState, sortState)

    jQuery.extend(true, r, paginationSortState)

    return this.converInMatrixParam(r)
  }
  private converInMatrixParam (params: any): any {
    const output: any = {}

    for (const param in params) {
      if (typeof params[param] !== 'object') {
        output[param] = params[param]
      } else {
        output[param] = ''
        for (let i = 0; i < params[param].length; i++) {
          output[param] += params[param][i] + ';'
        }
      }
    }

    return output
  }
  public getFilterState (response: any): any {
    const r: any = {}

    if ((typeof response.datasource !== 'undefined') && (typeof response.datasource.filter !== 'undefined')) {
      for (let i = 0; i < response.datasource.filter.length; i++) {
        switch (response.datasource.filter[i].type) {
          case 'SELECT': {
            for (let j = 0; j < response.datasource.filter[i].options.length; j++) {
              if (response.datasource.filter[i].options[j].selected) {
                if (typeof r[response.datasource.filter[i].name] === 'undefined') r[response.datasource.filter[i].name] = []
                r[response.datasource.filter[i].name].push(response.datasource.filter[i].options[j].value)
              }
            }
            break
          }
          default: {
            r[response.datasource.filter[i].name] = response.datasource.filter[i].value
          }
        }
      }
    }
    return r
  }
  public getPaginationState (response: any, newPaginationState: any): any {
    const r: any = {}

    if (typeof response.datasource !== 'undefined') {
      const id = response.datasource.id.substring(0, 1).toUpperCase() + response.datasource.id.substring(1)
      const page = ((newPaginationState !== null) && (typeof newPaginationState !== 'undefined') && (typeof newPaginationState.page !== 'undefined')) ? newPaginationState.page : response.datasource.resultset.chunk
      const pageSize = ((newPaginationState !== null) && (typeof newPaginationState !== 'undefined') && (typeof newPaginationState.pageSize !== 'undefined')) ? newPaginationState.pageSize : response.datasource.resultset.chunksize

      r['sort' + id] = []
      r['sort' + id].push('page:' + page)
      r['sort' + id].push('pageSize:' + pageSize)
    }
    return r
  }
  public getSortState (response: any, newSortState: any): any {
    const r: any = {}
    const id = response.datasource.id.substring(0, 1).toUpperCase() + response.datasource.id.substring(1)

    if ((typeof newSortState === 'undefined') || (newSortState === null)) {
      if ((typeof response.datasource !== 'undefined') && (typeof response.datasource.sort !== 'undefined') && (response.datasource.sort.length > 0)) {
        r['sort' + id] = []

        for (let i = 0; i < response.datasource.sort.length; i++) {
          if (response.datasource.sort[i].order !== '') r['sort' + id].push(response.datasource.sort[i].name + ':' + response.datasource.sort[i].order.toLowerCase())
        }
      }
    } else {
      r['sort' + id] = []

      for (const name in newSortState) {
        r['sort' + id].push(name + ':' + newSortState[name].toLowerCase())
      }
    }
    return r
  }
  public resetFilterField (formId: any, fieldName: any): any {
    function foo (oForm: any): void {
      jQuery(oForm).find('[name="' + fieldName + '"]').each(function () {
        if (((this as HTMLInputElement).type === 'text') || (this.tagName.toLowerCase() === 'textarea')) jQuery(this).val('')
        if (this.tagName.toLowerCase() === 'select') jQuery(this).prop('selectedIndex', 0)
        if ((this as HTMLInputElement).type === 'radio') jQuery(this).removeProp('checked')
        if ((this as HTMLInputElement).type === 'checkbox') jQuery(this).prop('checked', false)
        if (fieldName == 'f_from' || fieldName == 'f_to') {
          jQuery(this).val('')
          jQuery(this).trigger('change')
          jQuery(oForm).find('[name="' + fieldName + 'UTC"]').val('')
        }
        jQuery(oForm).trigger('submit')
      })
    }
    jQuery('#' + formId).each(function () {
      foo(this)
    })
  }
  public getSortDataForField (fieldName: string, sort: Array<ISortItem>): {[key: string]: any} {
    const r: {[key: string]: any} = {
      enabled: false,
      cssClass: '',
      currentOrder: '',
      nextOrder: ''
    }
    if ((typeof fieldName !== 'undefined') && fieldName && (typeof sort !== 'undefined') && sort) {
      for (let i = 0; i < sort.length; i++) {
        if (sort[i].name === fieldName) {
          r.enabled = true
          r.cssClass = r.enabled ? 'sort' : ''
          r.currentOrder = sort[i].order ? sort[i].order : 'none'

          switch (r.currentOrder) {
            case 'none':
              r.nextOrder = 'asc'
              break
            case 'asc':
              r.nextOrder = 'desc'
              break
            case 'desc':
              r.nextOrder = ''
              break
          }
          return r
        }
      }
      return r
    } else {
      return r
    }
  }
  private concatStateData (obj1: any, obj2: any): any {
    const r = obj1
    for (const prop2 in obj2) {
      if (typeof r[prop2] !== 'undefined') {
        for (let i = 0; i < obj2[prop2].length; i++) {
          r[prop2].push(obj2[prop2][i])
        }
      } else {
        r[prop2] = obj2[prop2]
      }
    }
    return r
  }
  public isFilterSet (filter: {[key: string]: any}): boolean {
    let result = false
    if (filter === null) return false
    for (const filterParam in filter) {
      if ((filter[filterParam].value !== null) && (filter[filterParam].value !== '')) result = true
    }
    return result
  }
  public submitForm (formId: string): void {
    jQuery('#' + formId).trigger('submit')
  }
  public resetForm (formId: string): void {
    const jForm = jQuery('#' + formId)
    if (jForm.length !== 0) (jForm.get(0) as HTMLFormElement).reset()
  }
  public $emit4AllParents (vm: Vue, eventName: string, data: any): void {
    function emitForParent ($parent: Vue, eventName: string, data: any): void {
      if (typeof $parent !== 'undefined') {
        $parent.$emit(eventName, data)
        if (typeof $parent.$parent !== 'undefined' && $parent.$parent !== null) {
          emitForParent($parent.$parent, eventName, data)
        }
      }
    }
    if (vm.$parent) emitForParent(vm.$parent, eventName, data)
  }
  public $emit4Children (vm: Vue, eventName: string, data: any): void {
    for (let i = 0; i < vm.$children.length; i++) {
      vm.$children[i].$emit(eventName, data);
    }
  }
  public $emit4AllChildren (vm: Vue, eventName: string, data: any): void {
    function emitForChild ($child: Vue, eventName: string, data: any): void {
      if (typeof $child !== 'undefined') {
        $child.$emit(eventName, data)
        if (typeof $child.$children !== 'undefined' && $child.$children !== null) {
          for (let i = 0; i < $child.$children.length; i++) {
            emitForChild($child.$children[i], eventName, data)
          }
        }
      }
    }
    for (let i = 0; i < vm.$children.length; i++) {
      emitForChild(vm.$children[i], eventName, data)
    }
  }
}
export const vueTemplateService = new VueTemplateService()