import Vue from 'vue'
import jQuery from 'jquery'
import '@/scripts/own/jquery.string'
import { globalVar } from '../own/_globalVar'
import { generalService } from './GeneralService'
import { Bus as VuedalsBus } from '@/components/vuedals/main'
import { isIFrame, isMobile } from '../own/ait-common'
import { dateTimeService } from '@/scripts/services/DateTimeService'
import { ProcessObserver } from '@/scripts/own/ait-common'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'

interface ICommunicationLoaderVue extends Vue {
  hidden: boolean
}
interface IMentionLinkVue extends Vue {
  label: string,
  href: string,
  tooltip: any
}
let vmCommunicationLoader: ICommunicationLoaderVue
let vmMentionLink: IMentionLinkVue

class UIService {
  public setMentionLink (el:any, nickName: string, href: URL): void {
    const mentionTooltip = '<div class="card-container">\
        <div class="card-item w30">\
          <#imageTag#><#monogrammTag#>\
        </div>\
        <div class="card-item w70">\
            <div class="nick-name"> @<#nickname#></div>\
            <div class="real-name"><#name#></div>\
        </div>\
    </div>'
    jQuery(el).html('<div id="mention-link_'+nickName+'"></div>')
    jQuery.ajax({
      type: 'GET',
      dataType: 'json',
      url: '/api/v1/users',
      data: { q: nickName },
      success: (data: any) => {
        if(typeof data === undefined || data.length === 0) return    
        const item = data.filter(item => item.nickName === nickName)[0]
        const imageTag = item.image ? '<img class="avatar-picture" src="'+ item.image +'">' : ''
        const monogrammTag = item.image ? '' : '<span class="avatar-icon"><strong>' + nickName.substring(0,1).toUpperCase() + '</strong></span>'
        const name = item.realName ? item.realName : ''
        new Vue({
          el: '#mention-link_'+nickName,
          data: {
            label: nickName,
                href: href,
                tooltip: {content: jQuery.string.replacePlaceHolders(mentionTooltip, {
                  imageTag: imageTag,
                  monogrammTag: monogrammTag,
                  nickname: nickName,
                  name: name
                }), trigger: 'hover'}
          },
          render: function (h) {            
            return h('mention-link', {
              props: {
                label: this.label,
                href: this.href,
                tooltip: this.tooltip
              }
            })
          }
        })
      }
    })
  }

  public showCommunicationLoader (): void {
    if ((jQuery('#communication-loader').length > 0) && (vmCommunicationLoader)) {
      vmCommunicationLoader.hidden = false
    } else {
        jQuery('body').append('<div id="communication-loader"></div>')
        if (!vmCommunicationLoader) {
        vmCommunicationLoader = new Vue({
          el: '#communication-loader',
          data: {
            hidden: false
          },
          render: function (h) {
            return h('communication-loader', {
              props: {
                hidden: this.hidden
              }
            })
          }
        })
      }
    }
  }
  public removeCommunicationLoader (): void {
    if (vmCommunicationLoader) vmCommunicationLoader.hidden = true
  }
  public scrollTo (position: number): void {
    jQuery('html,body').animate({ scrollTop: position }, 500)
  }
  public initForm (vm: Vue, formId: string, callback: (data: any) => void): any {
    return new Promise((resolve, reject?) => {
      const getFormsScript = () => import('../own/forms')
      getFormsScript().then((e: any): void => {
        e.forms.init(vm, formId, callback)
        this.initUploadFields()
        resolve(e.forms)
      })
      .catch((e: any) => {
        if (typeof reject === 'function') reject(e)
      })
    })
  }
  private initUploadFields (): void {
    jQuery('input[type="file"][accept="image/*"]:not([data-initialized="true"])').each((index, that) => {
      jQuery(that).attr('data-initialized', 'true')

      const fileField: HTMLInputElement = that as HTMLInputElement
      const jDropArea = jQuery(that).parents('.dd-upload-container')
      const jForm = jQuery(that).parents('form')

      if (window.File && window.FileReader && window.FileList && window.Blob) {
        jForm.on('drag dragstart dragend dragover dragenter dragleave drop', function (e: any) {
          e.preventDefault()
          e.stopPropagation()
        })

        jForm.on('dragover dragenter', function (e: any) {
          jDropArea.addClass('dragover')
        })

        jForm.on('drop dragleave dragend', function (e: any) {
          jDropArea.removeClass('dragover')
        })
        const elmForm = jForm.get(0)
        if (typeof elmForm !== 'undefined') {
          elmForm.addEventListener('drop', function (e: any) {
            const droppedFiles = e.dataTransfer.files
            if (droppedFiles.length > 0) {
              fileField.files = droppedFiles
              jQuery(fileField).trigger('change')
            }
          })
        }
      }
    })
  }
  public showModal (data: {[key: string]: any}): void {
    const ModalHeader = {
      name: 'modal-header',
      props: ['headerText', 'footer'],
      methods: {
        close: function (): void {
          try {
            VuedalsBus.$emit('dismiss')
          } catch (e: any) {
            VuedalsBus.$emit('close')
          }
          const that = this as {[key: string]: any}
          if (data.isMessage && that && that.footer && Array.isArray(that.footer.buttons) && (that.footer.buttons.length > 0)) {
            const messageCallback = that.footer.buttons[0].action
            if (typeof messageCallback === 'function') messageCallback()
          }
        }
      },
      template: `
        <div>
          <span class="title" v-html="headerText"></span>
          <span @click="close()" class="close">&times;</span>
        </div>
      `
    }

    const ModalComponent = {
      name: 'modal-dialog',
      props: ['content', 'footer'],
      template: `
      <div class="wrapper">
        <div class="modal-body" v-html="content">
        </div>
        <div v-if="footer" class="modal-footer">
          <button v-for="button in footer.buttons" type="button" class="btn" v-bind:class="button.class" @click="button.action">{{button.label}}</button>
        </div>
      </div>
      `
    }

    let size = typeof data.size !== 'undefined' ? data.size : 'md'
    size = typeof data.title === 'undefined' || data.title === '' ? size + ' without-header' : size

    VuedalsBus.$emit('new', {
      size: size,
      closeOnBackdrop: false,
      escapable: true,
      header: {
        component: ModalHeader,
        props: {
          headerText: data.title,
          footer: data.footer
        }
      },
      component: ModalComponent,
      props: {
        content: data.content,
        footer: data.footer
      }
    })
    setTimeout(() => {
      jQuery('.vuedals').trigger('focus')
    }, 50)
  }
  public showMessage (title: string, content: string, callbackOnClose: any, size?: string) {
    this.showModal({
      isMessage: true,
      title: title,
      content: content,
      footer: {
        buttons: [
          {
            label: globalVar.appEngine.labels.button.close,
            class: 'btn-secondary',
            action: function (): void {
              VuedalsBus.$emit('close')
              if (callbackOnClose !== null && typeof callbackOnClose === 'function') callbackOnClose()
            }
          }
        ]
      },
      size: size
    })
  }
  private getMessagesState (messages: [{[key: string]: any}]): {[key: string]: number} {
    let multipleMessagesOK = 0
    let multipleMessagesError = 0
    let multipleMessagesWarning = 0

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].level === 'OK') multipleMessagesOK++
      if (messages[i].level === 'ERROR') multipleMessagesError++
      if (messages[i].level === 'NOTICE') multipleMessagesWarning++
    }
    return {
      OK: multipleMessagesOK,
      ERROR: multipleMessagesError,
      NOTICE: multipleMessagesWarning
    }
  }
  public showMessages (messages: [{[key: string]: any}], callback?: (messagesState: {[key: string]: number}) => void): void {
    if (messages.length > 0) {
      this.showMessage('', this.getMessageHtmlMarkup(messages), () => {
        if (typeof callback === 'function') callback(this.getMessagesState(messages))
      })
    }
  }
  private getMessageHtmlMarkup (messages: [{[key: string]: any}]): string {
    let strMessages = ''
    for (let i = 0; i < messages.length; i++) {
      const messageContent: string = ((typeof globalVar !== 'undefined') && (typeof globalVar.message[messages[i].text] !== 'undefined')) ? globalVar.message[messages[i].text] : messages[i].text

      if (messages[i].level === 'OK') strMessages += '<div class="alert alert-success" role="alert">' + messageContent + '</div>'
      if (messages[i].level === 'ERROR') strMessages += '<div class="alert alert-danger" role="alert">' + messageContent + '</div>'
      if (messages[i].level === 'INVALID') strMessages += '<div class="alert alert-warning" role="alert">' + messageContent + '</div>'
    }
    return strMessages
  }
  public showPopupMessages (resourceId: string, messages: Array<{[key: string]: string}>): void {
    let jAlertMessagePanel = jQuery('#alert-message-panel')
    let jErrorMessagePanel = jQuery('#error-message-panel-' + resourceId)

    if (jAlertMessagePanel.length === 0) {
      jQuery('body').append('<div id="alert-message-panel" class="alert-message-panel"></div>')
      jAlertMessagePanel = jQuery('#alert-message-panel')
    }
    if (jErrorMessagePanel.length === 0) {
      const jForm = jQuery('form[name="'+resourceId+'"]')
      if (jForm.length !== 0) {
        jForm.prepend('<div id="error-message-panel-' + resourceId + '" class="error-message-panel"></div>')
        jErrorMessagePanel = jQuery('#error-message-panel-' + resourceId)
      } else {
        jErrorMessagePanel = jAlertMessagePanel
      }
    } else {
      jErrorMessagePanel.find('*').remove()
    }

    for (let i = 0; i < messages.length; i++) {
      const itemId = 'message-id-' + generalService.createGuid()
      let alertType = ''
      switch (messages[i].level) {
        case "OK": {
          alertType = 'success'
          break
        }
        case "ERROR": {
          alertType = 'danger'
          break
        }
        case "NOTICE": {
          alertType = 'info'
          break
        }       
      }
      if (alertType === 'danger') {
        const html = '<div id="' + itemId + '" class="alert alert-'+ alertType +' icon close" role="alert">' + messages[i].text + '</div>'
        jErrorMessagePanel.append(html)
        jQuery('#' + itemId).on('click', function () { jQuery(this).remove() })
        if (jAlertMessagePanel !== jErrorMessagePanel) {
          uiService.scrollTo(0)
        }
      } else {
        const html = '<div id="' + itemId + '" class="alert alert-'+ alertType +' icon close" role="alert">' + messages[i].text + '</div>'
        jAlertMessagePanel.append(html)
        jQuery('#' + itemId).on('click', function () { jQuery(this).remove() })
        setTimeout(() => {
          jQuery('#' + itemId).fadeOut(300, function () { jQuery(this).remove() })
        }, 4000)
      }
    }
  }
  public showAlertMessage (message: string, type?: string): void {
    let jAlertMessagePanel = jQuery('#alert-message-panel')
    if (jAlertMessagePanel.length === 0) {
      jQuery('body').append('<div id="alert-message-panel" class="alert-message-panel"></div>')
      jAlertMessagePanel = jQuery('#alert-message-panel')
    }
    const itemId = 'message-id-' + generalService.createGuid()
    const html = '<div id="' + itemId + '" class="alert alert-'+ (typeof type !== 'undefined' ? type : 'primary') +'" role="alert">' + message + '</div>'
    jAlertMessagePanel.append(html)
    setTimeout(() => {
      jQuery('#' + itemId).fadeOut(300, function () { jQuery(this).remove() })
    }, 2000)
  }
  public initMapPlaceholder (): void {
    jQuery('.map-placeholder').each((i: number, e: HTMLElement): void => {
      if (!isIFrame()) { // not widget
        if (jQuery('.full-content-map').length === 1 ) { 
          jQuery(e).parent().find('> img').css('max-height', 'none')
          const pageHeight = jQuery('.page .tab-box > *').outerHeight()
          if (typeof pageHeight !== 'undefined') {
            jQuery(e).parent().find('> img').css('max-height', pageHeight + 'px')
          }
        } else if (jQuery('.full-screen-map').length === 1) { // map in 'full-screen-map'
          jQuery(e).parent().find('> img').css({height: '100%', width: '100%'})
        } else { //normal map
          // map is not heighter as window
          jQuery(e).parent().find('> img').css('max-height', 'none')
          const windowHeight = jQuery(window).height()
          const windowWidth = jQuery(window).width()
          const mapHeight = jQuery(e).parent().find('> img').height()
          if ((typeof windowHeight !== 'undefined') && (typeof mapHeight !== 'undefined') && (mapHeight > windowHeight)) {
            jQuery(e).parent().find('> img').css('max-height', (windowHeight - 50) + 'px')
          }
          if (isMobile.any() && (typeof windowHeight !== 'undefined') && (typeof windowWidth !== 'undefined') && (windowWidth < windowHeight)) {
            jQuery(e).parent().find('> img').css('height', windowHeight * 3 / 4)
          } else {
            jQuery(e).parent().find('> img').css('height', 'auto')
          }
        }
      } else { // Widget
        const item = e as any
        if (isMobile.any() && (item.parentWindowWidth < item.parentWindowHeight)) {
          jQuery(e).parent().find('> img').css('height', item.parentWindowHeight * 3 / 4)
        } else {
          jQuery(e).parent().find('> img').css('height', 'auto')
        }
      }
    })
  }
  public initVerticalScrolling (): void {
    if (jQuery('.full-screen-map').length === 1) {
      jQuery('.scroll-vertical').each((i: number, e: HTMLElement): void => {
        const contentHeight = jQuery(e).parents('.content').height()
        const infosHeight = jQuery(e).parents('.content').find('.top-panel').height()
        if (contentHeight && contentHeight > 0) {
          const height = contentHeight - (typeof infosHeight !== 'undefined' ? infosHeight : 0)
          jQuery(e).css('height', height)
        }
        //event 'scrollend'
        if (typeof  jQuery(e).attr('data-event-initialized') === 'undefined') {
          const processObserverScrollEnd = new ProcessObserver()
          jQuery(e).on('scroll', (e: any): void => {
            processObserverScrollEnd.inProcess()
            processObserverScrollEnd.start((): void => {
              jQuery(e.target).trigger('scrollend')
            }, 300)
          })
          jQuery(e).attr('data-event-initialized', 'true')
        }
      })
    } else if (jQuery('.full-content-map').length === 1) {
      jQuery('.scroll-vertical').each((i: number, e: HTMLElement): void => {
        const jTopFixedPanel = jQuery(e).parents('.scroll-vertical-with-top-fixed-panel').find('.top-fixed-panel')
        let siblingsHeight = 0
        if (jTopFixedPanel.length > 0) {
          jQuery(e).parents('.scroll-wrapper').css({
            'padding-top': jTopFixedPanel.height() + 'px'
          })
        } else {
          jQuery(e).siblings().each((j: number, objSibling: HTMLElement) => {
            const sh = jQuery(objSibling).height()
            if (typeof sh !== 'undefined') siblingsHeight += sh
          })
        }
        const parentHeight = jQuery(e).parent().height()
        if (parentHeight) jQuery(e).css('height', (parentHeight - siblingsHeight) + 'px')
      })
    } else { // default
      jQuery('.scroll-vertical').each((i: number, e: HTMLElement): void => {
        const jTopFixedPanel = jQuery(e).parents('.scroll-vertical-with-top-fixed-panel').find('.top-fixed-panel')
        if (jTopFixedPanel.length > 0) {
          jQuery(e).parents('.scroll-wrapper').css({
            'padding-top': jTopFixedPanel.height() + 'px'
          })
        }
        const mapHeight = jQuery(e).parents('.row').find('.map-container').height()
        if (mapHeight) jQuery(e).css('height', mapHeight)
      })
    }
  }
  public setDataContainerHeight (): void {
    jQuery('.data-container').css({'max-height': 'none'})
    const parentHeight = jQuery('.tab-box > .component-box').height()
    const topPanelHeight = jQuery('.tab-box .top-panel').height()
    if (typeof parentHeight !== 'undefined' && typeof topPanelHeight !== 'undefined') {
      jQuery('.data-container').css({
        'height': (parentHeight - topPanelHeight) + 'px'
      })
      const windowHeight = jQuery(window).height()
      const mapContainerPosition = jQuery('.tab-box .map-container').css('position')
      if (mapContainerPosition === 'relative' && typeof windowHeight !== 'undefined') {
        jQuery('.data-container').css({
          'max-height': windowHeight * 0.8 + 'px'
        })
      }
    }
    uiService.initVerticalScrolling()
  }
  public showViewInPopup (componentName: string, sourceURL: string, params: {[key: string]: any}, content: {[key: string]: string}, referrerComponent: any, popupSize: string | undefined): void {
    const ModalHeader: {[key: string]: any} = {
      name: 'modal-header',
      props: ['headerText'],
      methods: {
        close: function (e: any): void {
          e.stopPropagation()
          try {
            VuedalsBus.$emit('dismiss')
          } catch (e: any) {
            VuedalsBus.$emit('close')
          }
        }
      }
    }
    ModalHeader.template = '<div><span class="title">{{ headerText }}</span><span @click="(e) => close(e)" class="close">&times;</span></div>'

    const props = {
      sourceURL: sourceURL,
      componentToUpdate: referrerComponent,
      contentText: typeof content.body !== 'undefined' ? content.body : null,
      parameters: params
    }
    jQuery.extend(true, props, params)

    let size = typeof popupSize !== 'undefined' ? popupSize : 'm'
    size = typeof content.title == 'undefined' || content.title === '' ? size + ' without-header' : size

    VuedalsBus.$emit('new', {
      size: size,
      escapable: true,
      dismissable: false,
      closeOnBackdrop: true,
      header: {
        component: ModalHeader,
        props: {
          headerText: typeof content.title !== 'undefined' ? content.title : ''
        }
      },
      component: Vue.component(componentName),
      props: props,
      onClose (data: any): any {
        referrerComponent.$emit('childPopupClosed', {
          componentName: componentName,
          sourceURL: sourceURL,
          params: params
        })
      },
      onDismiss() {
        referrerComponent.$emit('childPopupClosed', {
          componentName: componentName,
          sourceURL: sourceURL,
          params: params
        })
      }
    })
    setTimeout(() => {
      jQuery('.vuedals').trigger('focus')
    }, 50)
  }
  public generateIntegrationCode (vm: Vue, mode: string, itemId: string ): string {
    const origin = window.location.origin
    const guid = generalService.createGuid()
    const userName = vm.$store.state.user.nickName
    return '<script type="text/javascript" src="' + origin + '/resources/widget/js/tmw.js"></script><script id="id-' + guid + '" type="text/javascript">(function(t,r,a,v,e,l,map,ly){tmw.init(t,r,a,v,e,l,map,ly)})(window, document, "' + origin + '", "id-' + guid + '", "' + userName + '", "' + mode + '","' + itemId + '")</script>'
  }
  public initInfinityScrolling (vm: any): void {
    const onWindowScrollEnd =  (vm: any): void => {
      const scrollTop = jQuery(window).scrollTop()
      const windowsHeight = jQuery(window).height()
      const documentHeight = jQuery(document).height()
      // console.info('>>>', windowsHeight, documentHeight)
      if ((typeof scrollTop !== 'undefined') &&  (typeof windowsHeight !== 'undefined') && (typeof documentHeight !== 'undefined')) {
        const delta = Math.round(windowsHeight / 4)
        if (((scrollTop + windowsHeight) >= (documentHeight - delta)) && !vm.loadingInProgress) {
          generalService.loadNextDatasourcePage(vm)
        }
      }
    }
    const validateAndSetInfinityScrollingState = (vmi: any): void => {
      setTimeout(() => {
        const windowsHeight = jQuery(window).height()
        const documentHeight = jQuery(document).height()
        if (typeof windowsHeight !== 'undefined' && typeof documentHeight !== 'undefined' && windowsHeight >= documentHeight && vm.pagination !== null && vm.pagination.number < (vm.pagination.totalPages - 1) ) {
          onWindowScrollEnd(vmi)
          setTimeout(() => {
            validateAndSetInfinityScrollingState(vmi)
          }, 1000)
        }
      }, 1000)
    }

    jQuery(window).on(generalService.getScrollendEventName(), (e:any) => {
      onWindowScrollEnd(vm)
    })
    validateAndSetInfinityScrollingState(vm)
  }
  public initInfinityScrollingInScrollContainer (vm: any, selector: string): void {
    const onScrollEnd =  (vm: any, e:any): void => {
      const scrollTop = jQuery(e).scrollTop()
      const containerHeight = jQuery(e).height()
      const contentHeight = jQuery(e).find('> *:first-child').height()
      if (typeof scrollTop !== 'undefined' && typeof containerHeight !== 'undefined' && typeof contentHeight !== 'undefined') {
        const delta = Math.round(containerHeight / 10)
        if (((scrollTop + containerHeight) >= (contentHeight - delta)) && !vm.loadingInProgress) {
          vm.$emit('loadNextDatasourcePage', e)
        }
      }
    }
    const validateAndSetInfinityScrollingState = (vmi: any, selector: string): void => {
      setTimeout(() => {
        const containerHeight = jQuery(selector).height()
        const contentHeight = jQuery(selector).find('> *:first-child').height()
        if (typeof containerHeight !== 'undefined' && typeof contentHeight !== 'undefined' && containerHeight >= contentHeight && vm.pagination !== null && vm.pagination.number < (vm.pagination.totalPages - 1)) {
          onScrollEnd(vmi, jQuery(selector).get(0))
          setTimeout(() => {
            validateAndSetInfinityScrollingState(vmi, selector)
          }, 1000)
        }
      }, 1000)
    }
    jQuery(selector).on(generalService.getScrollendEventName(), (e:any) => {
      onScrollEnd(vm, e.target)
    })
    validateAndSetInfinityScrollingState(vm, selector)
  }
  public getDistanceInfo (data: {[key: string]: any}): string {
    const userUnitsType = this.getUserUnitsType()
    const isNautic = typeof data.nautic !== 'undefined' && typeof data.nautic.value !== 'undefined' ? data.nautic.value : false
    if (isNautic) {
      return data.metrics.value.nautic.value.distance.value + ' NM'
    } else {
      switch (userUnitsType) {
        case 'IMPERIAL': {
          return data.metrics.value.imperial.value.distance.value + ' mi'
        }
        default: {
          return data.metrics.value.metric.value.distance.value + ' km'
        }
      }      
    }
  }
  public getSpeedInfo (data: {[key: string]: any}): string {
    const userUnitsType = this.getUserUnitsType()
    const isNautic = typeof data.nautic !== 'undefined' && typeof data.nautic.value !== 'undefined' ? data.nautic.value : false
    if (isNautic) {
      return data.metrics.value.nautic.value.speed.value + ' kn'  
    } else {
      switch (userUnitsType) {
        case 'IMPERIAL': {
          return data.metrics.value.imperial.value.speed.value + ' mph'
        }
        default: {
          return data.metrics.value.metric.value.speed.value + ' km/h'
        }
      }      
    }
  }
  public getSpeedInfoTooltip (data: {[key: string]: any}): string {
    const userUnitsType = this.getUserUnitsType()
    const isNautic = typeof data.nautic !== 'undefined' && typeof data.nautic.value !== 'undefined' ? data.nautic.value : false
    if (isNautic) {
      switch (userUnitsType) {
        case 'IMPERIAL': {
          return data.metrics.value.imperial.value.speed.value + ' mph'
        }
        default: {
          return data.metrics.value.metric.value.speed.value + ' km/h'
        }
      }            
    } else return ''
  }
  public getUserUnitsType (): string {
    const imperialUnitsCountries = ["America/Adak","America/Anchorage","America/Atka","America/Boise","America/Chicago","America/Denver","America/Detroit","America/Fort_Wayne",
    "America/Indianapolis","America/Knox_IN","America/Los_Angeles","America/Louisville","America/Menominee","America/Metlakatla","America/New_York","America/Nome",
    "America/Phoenix","America/Shiprock","America/Sitka","America/Yakutat","America/Atikokan","America/Blanc-Sablon","America/Cambridge_Bay","America/Coral_Harbour",
    "America/Creston","America/Dawson","America/Dawson_Creek","America/Edmonton","America/Fort_Nelson","America/Glace_Bay","America/Goose_Bay","America/Halifax","America/Inuvik",
    "America/Iqaluit","America/Moncton","America/Montreal","America/Nipigon","America/Pangnirtung","America/Rainy_River","America/Rankin_Inlet","America/Regina",
    "America/Resolute","America/St_Johns","America/Swift_Current","America/Thunder_Bay","America/Toronto","America/Vancouver","America/Whitehorse","America/Winnipeg",
    "America/Yellowknife","Africa/Monrovia","Asia/Rangoon","Asia/Yangon","America/Belize","America/Nassau","America/Jamaica","America/St_Kitts","America/St_Vincent",
    "America/Antigua","America/Grenada","America/Puerto_Rico","America/Dominica","America/St_Lucia","Europe/London","Europe/Belfast"]
    let userUnitsType = localStorage.getItem('userUnitsType')
    if (!userUnitsType) {
      userUnitsType = imperialUnitsCountries.includes(dateTimeService.getLocalTimeZoneName()) ? 'IMPERIAL' : 'METRIC'
    }
    return userUnitsType
  }
  public getGlobalMessageText (index: string): string {
    return typeof globalVar.message[index] !== 'undefined' ? globalVar.message[index] : index
  }
  public getOkMessage (data: any): Array<string> {
    const OKMessage: Array<string> = []
    for (let i = 0; i < data.messages.length; i++) {
      if (data.messages[i].level === 'OK') {
        OKMessage.push(this.getGlobalMessageText(data.messages[i].text))
      }
    }
    return OKMessage
  }
  public getErrorMessage (data: any): Array<string> {
    const errorMessage: Array<string> = []
    for (let i = 0; i < data.messages.length; i++) {
      if (data.messages[i].level === 'ERROR') {
        errorMessage.push(this.getGlobalMessageText(data.messages[i].text))
      }
    }
    return errorMessage
  }
  public showOkMessageInPopup (vm: Vue, headline: string, data: any, placeholderData: any, callback?: () => void): void {
    headline = headline === '' ? '' : headline
    const messages = this.getOkMessage(data)
    let message = ''
    for (let i = 0; i < messages.length; i++) {
      message += (i > 0 ? '<br/>' : '') + jQuery.string.replacePlaceHolders(messages[i], placeholderData)
    }
    const content = '<div class="alert alert-success" role="alert">' + message + '</div>'
    jQuery('#alert-message-panel *').remove()
    this.showMessage(headline, content, () => {
      if (typeof callback !== 'undefined') callback()
    }, 'xl')
  }
  public getTrackIcon(L: any, map: any, setId: string, iconId: string, fillColor?: string, strokeColor?:string) : any {
    setId = setId ? setId : 'default'
    iconId = iconId ? iconId : 'default'
    const iconSet =  map.c.polylineDecoIcon[setId]
    const trackIconSettings = {
      // ex. fillColor = #2981CA; strokeColor = #ffffff
      colorString: (typeof fillColor != 'undefined' && fillColor != '') ? ' fill="'+fillColor+'"' : '', 
      strokeString: (typeof strokeColor != 'undefined' && strokeColor != '') ? ' stroke="'+strokeColor+'" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" stroke-opacity="1" ' : '',
      iconAnchor: typeof iconSet[iconId].iconAnchor !=  'undefined' ? iconSet[iconId].iconAnchor : [16, 16],
      iconSize: typeof iconSet[iconId].iconSize !=  'undefined' ? iconSet[iconId].iconSize : [32, 32]
    }
    let trackIcon = iconSet[iconId].html
    trackIcon = jQuery.string.replacePlaceHolders(trackIcon, {
      fill: trackIconSettings.colorString,
      stroke: trackIconSettings.strokeString
    })
    return L.divIcon({
      className: 'leaflet-data-marker',
      html: trackIcon,
      iconAnchor: trackIconSettings.iconAnchor,
      iconSize: trackIconSettings.iconSize,
    })
  }
  public getMarkerIcon(L: any, map: any, iconType: number, color: string | null, thumbnailUrl?: string, letter?: string, assetsCount?: number, thumbnailHref?: string): any {
    if (map.c.iconType[iconType].marker.url === '' && map.c.iconType[iconType].marker.html !== '') {
      const htmlIconColor = (typeof color === 'string' && color !== '') ? 'fill="' + color + '"' : 'fill="#2981CA"'
      const htmlIconStroke = 'stroke="#333333" stroke-width="20" stroke-opacity=".2"'
      let thumbnail = ''
      if (typeof thumbnailUrl !== 'undefined' && thumbnailUrl) {
        const countHtml = assetsCount ? '<div class="count">' + assetsCount + '</div>' : ''
        if (typeof thumbnailHref !== 'undefined' && thumbnailHref) thumbnail = '<div class="thumbnail"><a href="'+thumbnailHref+'"><img src="' + thumbnailUrl + '" alt=""></a></div>' + countHtml
        else thumbnail = '<div class="thumbnail"><img src="' + thumbnailUrl + '" alt=""> </div>' + countHtml
      } else {
        thumbnailUrl = ''
      }
      if (typeof letter !== 'undefined') {
        if (typeof thumbnailHref !== 'undefined' && thumbnailHref) {
            letter = jQuery.string.replacePlaceHolders('<a href="<#thumbnailHref#>"><span><strong><#letter#></strong></span></a>', {
            thumbnailHref: thumbnailHref,
            letter: letter
          })
        } else letter = jQuery.string.replacePlaceHolders('<span><strong><#letter#></strong></span>', { letter: letter })
      } else letter = ''

      const htmlIconHtml = jQuery.string.replacePlaceHolders(map.c.iconType[iconType].marker.html, {
        fill: htmlIconColor,
        stroke: htmlIconStroke,
        thumbnail: thumbnail,
        thumbnailUrl: thumbnailUrl,
        letter: letter
      })
      return L.divIcon({
        className: "leaflet-data-marker" + (' type-' + iconType.toString()),
        html: htmlIconHtml,
        iconSize: [map.c.iconType[iconType].marker.width, map.c.iconType[iconType].marker.height],
        iconAnchor: [map.c.iconType[iconType].marker.anchorPosition.x, map.c.iconType[iconType].marker.anchorPosition.y],
        popupAnchor: map.c.iconType[iconType].marker.popupAnchor
      })
    } else {
      return L.icon({
        iconUrl: map.c.iconType[iconType].marker.url,
        iconSize: [map.c.iconType[iconType].marker.width, map.c.iconType[iconType].marker.height],
        iconAnchor: [map.c.iconType[iconType].marker.anchorPosition.x, map.c.iconType[iconType].marker.anchorPosition.y],
        popupAnchor: map.c.iconType[iconType].marker.popupAnchor
      })
    }
  }
  public getAverageSpeedString(averageKnots: any, averageKmh: any, averageMph: any): string {
    const userUnitsType = this.getUserUnitsType()
    const isNautic = averageKnots !== null ? true : false
    // normally it should not happen that one of these has null value
    if (averageKmh == null || averageMph == null) {
      //generalService.log("average speed is null")
      return ''
    }
    let averageKnotsString = ''
    if (isNautic) {
      averageKnotsString = (!Number.isInteger(averageKnots) ? averageKnots.toFixed(2).replace('.', ',') : averageKnots ) + ' kn'
    }
    const averageKmhString = (!Number.isInteger(averageKmh) ? averageKmh.toFixed(2).replace('.', ',') : averageKmh ) + ' km/h'
    const averageMphString = (!Number.isInteger(averageMph) ? averageMph.toFixed(2).replace('.', ',') : averageMph ) + ' mph'

    switch (userUnitsType) {
      case 'METRIC': {
        if (isNautic)
          return averageKnotsString + ' (' + averageKmhString + ')'
        else
          return averageKmhString
      }
      case 'IMPERIAL': {
        if (isNautic)
          return averageKnotsString + ' (' + averageMphString + ')'
        else
          return averageMphString
      }
      default: {
        return ''
      }
    }
  }
  public getWeatherDataMarkup(data: { [key: string]: any }): string {
    if ((data.weatherCondition === null) && (data.weatherTemp === null) && (data.weatherWindSpeed === null) && (data.weatherWindDirection === null)) return ''
    let conditionIcon = ''
    switch (data.weatherCondition) {
      case "CLEAR": {
        conditionIcon = 'icon clear_day'
        break
      }
      case "PARTLY_CLOUDY": {
        conditionIcon = 'icon partly_cloudy'
        break
      }
      case "CLOUDY":
      case "OVERCAST": {
        conditionIcon = 'icon cloudy'
        break
      }
      case "FOG": {
        conditionIcon = 'icon foggy'
        break
      }
      case "DRIZZLE":
      case "RAIN": {
        conditionIcon = 'icon rainy'
        break
      }
      case "SNOW":
      case "BLIZZARD": {
        conditionIcon = 'icon snowflake'
        break
      }
      case "ICE_PELLETS": {
        conditionIcon = 'icon snowing'
        break
      }
      case "THUNDERSTORM": {
        conditionIcon = 'icon thunderstorm'
        break
      }
      case "SANDSTORM":
      case "HURRICANE": {
        conditionIcon = 'icon storm'
        break
      }
      case "TORNADO": {
        conditionIcon = 'icon tornado'
        break
      }
      case "UNKNOWN": {
        conditionIcon = 'icon question_mark'
        break
      }
    }
    const weatherWindSpeedKn = data.weatherWindSpeedKn !== null ? Number.parseInt(data.weatherWindSpeedKn) : null
    const weatherWindSpeed = Number.parseInt(data.weatherWindSpeed)
    const weatherWindSpeedMph = Number.parseInt(data.weatherWindSpeedMph)
    const averageSpeedString = this.getAverageSpeedString(weatherWindSpeedKn, weatherWindSpeed, weatherWindSpeedMph)
    const temperatur = data.weatherTemp !== null ? '<span class="' + conditionIcon + '">' + data.weatherTemp + ' °C</span>':''
    let windSpeed = ''
    if (averageSpeedString) {
      windSpeed =  '<span class="icon wind">' + averageSpeedString + ((data.weatherWindDirection != null) ? ' (' + data.weatherWindDirection + ')' : '') + '</span>'
    }
    if (data.weatherCondition === 'INVALID') return ''
    return '<div class="weather">'+ temperatur + windSpeed + '</div>'
  }
  public convertTextNewLines (text: string): any {
    return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
  }
  public closeAllDropdownOnVuedalClick (e: any): void  {
    if (jQuery(e.target).parent().attr('data-bs-toggle') === 'dropdown') {
      const jDropdownButton = jQuery(e.target).parent()
      const dropdownPanelId = jDropdownButton.attr('href')?.substring(1)
      jQuery(e.target).parents('.vuedals').find('[data-bs-toggle="dropdown"] ~ .dropdown-menu.show').each((index: number, that: HTMLElement): void => {
        if (that.id !== dropdownPanelId) {
          if (that) new bootstrap.Dropdown(that).hide()
        }
      })
    } else {
      jQuery(e.target).parents('.vuedals').find('[data-bs-toggle="dropdown"] ~ .dropdown-menu.show').each((index: number, that: HTMLElement): void => {
        if (that) new bootstrap.Dropdown(that).hide()
      })
    }
  }
  public getGlobalLabel (type: string, name: string): string {
    return typeof globalVar.view.default.labels[type] !== 'undefined' && typeof globalVar.view.default.labels[type][name] !== 'undefined' ? globalVar.view.default.labels[type][name] : name
  }
  public initVerticalScrollingInVuedal (): void {
    //set comment container height & scrolling
    
    jQuery('.vuedal .scroll-vertical').each((index: number, that: HTMLElement): void => {
      const jParentPopup = jQuery(that).parents('.vuedal')
      if (jParentPopup.length > 0) {
        jParentPopup.each((pIndex: number, obj: any): void => {
          if (jQuery(that).find('.description').length === 0) {

            const jTopFixedPanel = jQuery(that).parents('.scroll-vertical-with-top-fixed-panel').find('.top-fixed-panel')
            let topFixedPanelHeight = 0
            if (jTopFixedPanel.length > 0) {
              const tfph =  jTopFixedPanel.height()
              if (typeof tfph !== 'undefined') {
                topFixedPanelHeight = tfph
              }
              jQuery(that).parents('.scroll-wrapper').css({
                'padding-top': topFixedPanelHeight + 'px'
              })
            }

            let scrollPanelHeight = 0
            const modalOuterHeight = jQuery(obj).outerHeight(true)
            const modalInnerHeight = jQuery(obj).innerHeight()
            let delta = 0
            if (typeof modalOuterHeight !== 'undefined' && typeof modalInnerHeight !== 'undefined') {
              delta = modalOuterHeight - modalInnerHeight + 20
            }
            let headerHeight = jQuery(obj).find('header').outerHeight(true)
            if (typeof headerHeight === 'undefined') {
              headerHeight = 0
            }
            const windowHeight = jQuery(window).height()
            if (typeof windowHeight !== 'undefined') {
              scrollPanelHeight = windowHeight - headerHeight - delta
              jQuery(that).css({
                'max-height': scrollPanelHeight - topFixedPanelHeight + 'px'
              })
            }
          } else {
            //vertical scroll for description
            let scrollPanelHeight = 0
            const modalOuterHeight = jQuery(obj).outerHeight(true)
            const modalInnerHeight = jQuery(obj).innerHeight()
            let delta = 0
            if (typeof modalOuterHeight !== 'undefined' && typeof modalInnerHeight !== 'undefined') {
              delta = modalOuterHeight - modalInnerHeight + 20
            }
            let headerHeight = jQuery(obj).find('header').outerHeight(true)
            if (typeof headerHeight === 'undefined') {
              headerHeight = 0
            }
            let itemsHeight = 0
            jQuery(that).parents('.datasource').find('.card').each((oItemIndex: number, oItem: any): void => {
              if (!jQuery(oItem).hasClass('scroll-vertical')) {
                const oItemHeigh = jQuery(oItem).height()
                if (typeof oItemHeigh !== 'undefined') {
                  itemsHeight = itemsHeight + oItemHeigh
                }
              }
            })
            const windowHeight = jQuery(window).height()
            if (typeof windowHeight !== 'undefined') {
              scrollPanelHeight = windowHeight - headerHeight - itemsHeight - delta
              const thatHeight = jQuery(that).height()
              if (typeof thatHeight !== 'undefined') {
                if (thatHeight <= scrollPanelHeight) {
                  jQuery(that).removeClass('scroll-panel scroll-vertical')
                } else {
                  jQuery(that).css({
                    'max-height': scrollPanelHeight + 'px'
                  })
                }
              }
            }
          }
        })
      }
    })
  }
  public getContentForDeleteDialog (datasourceId: string): {[key: string]: string} {
    return {
      title: (typeof globalVar.view[datasourceId] !== 'undefined') && (typeof globalVar.view[datasourceId].labels !== 'undefined') ? globalVar.view[datasourceId].labels.headline : 'Delete',
      body: (typeof globalVar.view[datasourceId] !== 'undefined') && (typeof globalVar.view[datasourceId].labels !== 'undefined') ? globalVar.view[datasourceId].labels.questionDelete : 'Are you sure?'
    }
  }
  public getContentForDialog (datasourceId: string): {[key: string]: string} {
    return {
      title: (typeof globalVar.view[datasourceId] !== 'undefined') && (typeof globalVar.view[datasourceId].labels !== 'undefined') ? globalVar.view[datasourceId].labels.headline : '',
      body: (typeof globalVar.view[datasourceId] !== 'undefined') && (typeof globalVar.view[datasourceId].labels !== 'undefined') ? globalVar.view[datasourceId].labels.question : ''
    }
  }
  public waitForCloseDescriptionEditor (selector: any, callback: () => void): void {
    if (jQuery('#left-panel-slide-show .displayWindow .description .action').length > 0 ) {
      setTimeout(() => { this.waitForCloseDescriptionEditor(selector, callback) }, 100)
    } else {
      setTimeout(() => { callback() }, 250)
    }
  }
}
export const uiService = new UIService()
