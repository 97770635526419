/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * A textual message with a certain level.
 */
export type Message = {
    /**
     * The level of the message.
     */
    level?: Message.level;
    /**
     * The actual message.
     */
    text?: string;
    /**
     * A key for this message
     */
    key?: string;
};

export namespace Message {

    /**
     * The level of the message.
     */
    export enum level {
        OK = 'OK',
        NOTICE = 'NOTICE',
        INVALID = 'INVALID',
        ERROR = 'ERROR',
    }


}
