import moment from "moment"

function getLocalizationData (locale: string): any {
  const localLocale = moment()
  localLocale.locale(locale)
  return  {
    date: {
      dateFormat: localLocale.localeData().longDateFormat('L')
    },
    time: {
      timeFormat: localLocale.localeData().longDateFormat('LTS')
    },
    number: {
      format: '#.##',
      groupingSeparator: ', ',
      decimalSeparator: '.'
    }
  }
}
export { getLocalizationData }
