import { generalService } from "./GeneralService"
import { getLocalizationData } from '../own/ait-dateLocalization'
import moment from 'moment'
import 'moment-timezone'

class DateTimeService {
  public formatDateTimeOutput (dateTime: string): string {
    if ((typeof dateTime == 'undefined') || (typeof dateTime != 'string')) return ''
    return dateTime.substring(0, 10).replaceAll('-',' ')
  }
  public getLocalDateTimeUTC (): number {
    const now = new Date()
    return moment(now).tz(this.getLocalTimeZoneName()).utc().valueOf()
  }
  public getLocalTimeZoneName (): string {
    return this.convertEtcToUtcTimeZoneName(moment.tz.guess())
  }

  public convertEtcToUtcTimeZoneName (timezoneName: string): string {
    const convertedTimeZoneName = timezoneName.startsWith('Etc/GMT') ? ((timezoneName === 'Etc/GMT') ? moment.tz(timezoneName).format('<#pr#>') : moment.tz(timezoneName).format('<#pr#>z')) : timezoneName            
    return convertedTimeZoneName.replace('<#pr#>','GMT')
  }
  
  public getDateTimeString (vm: Vue, dateTimeUTC: number, timeZoneName: string, dateTimeFormat?: string): string {
    //Ex. 2023-07-19 09:35 (Europe/Berlin)
    return this.getDateTimeUTCFieldValue(vm, dateTimeUTC, timeZoneName, dateTimeFormat) + ' (' + this.getPointTimezoneString(dateTimeUTC, this.convertEtcToUtcTimeZoneName(timeZoneName)) +')'
  }
  public getDateTimeTimezoneString (vm: Vue, dateTimeUTC: number, timeZoneName: string): string {
    //Ex. 2023-07-19 09:35 Europe/Berlin
    return this.getDateTimeUTCFieldValue(vm, dateTimeUTC, timeZoneName) + ' ' + this.convertEtcToUtcTimeZoneName(timeZoneName)
  }
  public getTimezoneString (dateTimeUTC: number, timeZoneName: string) {
    timeZoneName = typeof timeZoneName === 'undefined' || !timeZoneName ? 'Etc/GMT' : timeZoneName
    // Ex. (UTC+02:00)
    return moment(dateTimeUTC).tz(timeZoneName).format('(UTCZ)')
  }
  public getPointTimezoneString (dateTimeUTC: number, timeZoneName: string) {
    //Ex. Europe/Berlin
    return this.convertEtcToUtcTimeZoneName(timeZoneName)
  }
  public getDateTimeUTCFieldValue (vm: Vue, dateTimeUTC: number, timeZoneName?: string, dateTimeFormat?: string): string {
    const locale = generalService.getLocale()
    if (typeof timeZoneName === 'undefined' || !dateTimeUTC) return ''
    timeZoneName = typeof timeZoneName === 'undefined' || !timeZoneName ? 'Etc/GMT' : timeZoneName
    const format = typeof dateTimeFormat !== 'undefined' ? dateTimeFormat : getLocalizationData(locale).date.dateFormat + ' ' + getLocalizationData(locale).time.timeFormat
    //Ex. 2023-07-19 09:35
    return moment(dateTimeUTC).tz(timeZoneName).format(format)
  }
  public getUTCUnixTimestamp (vm: Vue, value: string, timeZoneName: string): number {
    timeZoneName = typeof timeZoneName === 'undefined' || !timeZoneName ? 'Etc/GMT' : timeZoneName
    let locale = generalService.getLocale()
    locale = locale !== null ? locale : 'en'
    const format = getLocalizationData(locale).date.dateFormat + ' ' + getLocalizationData(locale).time.timeFormat
    //Ex. 1689752100000
    return moment.tz(value, format, timeZoneName).utc().valueOf()
  }
  public getTimezonesListOptions (slectedIANATimezone: any): any {
    const tzList: Array<any> = []
    const IANATimezone = moment.tz.names().filter((tz) => {
      return tz.includes('/') &&
          !tz.startsWith('US') &&
          !tz.startsWith('Brazil') &&
          !tz.startsWith('Canada') &&
          !tz.startsWith('Chile') &&
          !tz.startsWith('Indian') &&
          !tz.startsWith('Mexico') &&
          !tz.startsWith('America/Indiana/') &&
          !tz.startsWith('America/North_Dakota/') &&
          !tz.startsWith('America/Argentina/') &&
          !tz.startsWith('America/Kentucky/') &&
          !tz.startsWith('America/Canada/') &&
          !tz.startsWith('Etc/Greenwich') &&
          !tz.startsWith('Etc/UCT') &&
          !tz.startsWith('Etc/UTC') &&
          !tz.startsWith('Etc/Universal') &&
          !tz.startsWith('Etc/Zulu') &&
          !tz.startsWith('Etc/GMT-0') &&
          !tz.startsWith('Etc/GMT0') &&
          !tz.startsWith('Etc/GMT+0')
    })
    for (let i=0; i < IANATimezone.length; i++) {
      const selected = (IANATimezone[i] === slectedIANATimezone) ? true : false
      tzList.push({
            label: this.convertEtcToUtcTimeZoneName(IANATimezone[i]),
            value: IANATimezone[i],
            selected: selected
          })
    }
    return tzList
  }
  public getRelativeDateTimeString (dateTimeUTC: number): string {
    return moment(dateTimeUTC).fromNow()
  }
  public isTimezoneValid (timezoneName: string): boolean {
    const timezones = this.getTimezonesListOptions(timezoneName)
    return timezones.findIndex((item) => item.selected) !== -1
  }
  public getDateTimeFormatWithoutSeconds (locale: string): string {
    const localization = getLocalizationData(locale)
    localization.time.timeFormat = localization.time.timeFormat.replaceAll(':ss', '')
    return localization.date.dateFormat + ' ' + localization.time.timeFormat
  }
}
export const dateTimeService = new DateTimeService()