<template>
  <div class="action" v-bind:class="{loading: showLoader}">
    <h1 v-if="headlineEnabled" v-html="headline"></h1>
      <form v-bind:action="execute" v-bind:name="id" v-bind:id="formId" enctype="multipart/form-data" class="form-framework" v-bind:data-locale="locale" v-bind:data-sourceid="id" data-send-method="ajax">
        <fieldset>
        </fieldset>
        <fieldset>
          <div class="row">
            <div class="form-block col-sm">
              <input type="hidden" value="" name="ghost">
              <button type="submit" class="btn btn-primary">Logout</button>
            </div>
          </div>
        </fieldset>
        <div v-for="(value, key) in params" v-bind:key="key+'_'+value">
          <input type="hidden" v-bind:name="key" v-bind:value="value">
        </div>
        <input type="hidden" name="form_action" :value="originId">
      </form>
    <ContentErrorMessage v-if="isDataRequestError"></ContentErrorMessage>
  </div>
</template>

<script lang="ts">
import mixins from 'vue-typed-mixins'
import MXActionGeneral from '@/components/mixins/actionGeneral'
import jQuery from 'jquery'
import { generalService } from '@/scripts/services/GeneralService'
import { dataService } from '@/scripts/services/DataService'
import ContentErrorMessage from '@/components/_content_error_message.vue'

export default mixins(MXActionGeneral).extend({
  name: 'ActionLogou',
  components: {
    ContentErrorMessage
  },
  methods: {
    callbackSubmitForm (data: any): void {
      generalService.log('submitForm Callback', data)
      dataService.updateComponentData(this, data)
    },
    callbackAfterInitForm (): void {
      jQuery('form#' + this.formId).trigger('submit')
    },
    callbackAfterSendData (data: any): void {
      generalService.log('> afterSendData callback', this.$parent)
      if (!generalService.haveFieldsErrors(data.field) && generalService.wasRequestSuccessfully(data)) {
        this.$emit4AllParents('afterSendActionData', data)
      }
    }
  }
})
</script>
