import jQuery from 'jquery'
import axios from 'axios'
import { comParam, IComParamScopeItem } from './ait-comParam'

interface AppNGConnectorCallback {
  done(response: any, requestParams: any): void;
  fail(error: any): void;
}

export class AppNGConnector {
  getData (url: string, data: any, callback: AppNGConnectorCallback): void {
    const arrBrowserRequestParams: Array<IComParamScopeItem> = (typeof comParam !== 'undefined') ? comParam.getRequestedState() : []
    const objBrowserRequestParams: IComParamScopeItem = {}
    let ajaxParams: any = {}
    let methodeType = ''
    let formActionParamEnabled = false

    if (data instanceof FormData) {
      // FileData API - 'data' is FileData Instance
      for (let i = 0; i < arrBrowserRequestParams.length; i++) {
        for (const paramName in arrBrowserRequestParams[i]) {
          if (!data.has(paramName)) data.append(paramName, arrBrowserRequestParams[i][paramName])
        }
      }

      data.append('rts', Date.now().toString())
      ajaxParams = data
      formActionParamEnabled = (data.has('form_action')) && (data.get('form_action') !== '')
      methodeType = formActionParamEnabled ? 'POST' : 'GET'
    } else {
      // without FileData API
      for (let i = 0; i < arrBrowserRequestParams.length; i++) {
        for (const paramName in arrBrowserRequestParams[i]) {
          if ((paramName !== 'remove') && (typeof arrBrowserRequestParams[i][paramName] !== 'function')) objBrowserRequestParams[paramName] = arrBrowserRequestParams[i][paramName]
        }
      }

      jQuery.extend(ajaxParams, objBrowserRequestParams, data)
      jQuery.extend(ajaxParams, { rts: Date.now().toString() })
      formActionParamEnabled = (typeof data.form_action !== 'undefined') && (data.form_action !== '')
      methodeType = formActionParamEnabled ? 'POST' : 'GET'
    }

    const rp: Array<IComParamScopeItem> = []
    let rpItem: {[key: string]: string} = {}
    if (ajaxParams instanceof FormData) {
      for (const pair of ajaxParams.entries()) {
        if (jQuery.inArray(pair[0], ['ghost', 'form_action', 'ffts', 'rts']) === -1) {
          rpItem = {}
          rpItem[pair[0]] = pair[1].toString()
          rp.push(rpItem)
        }
      }
    } else {
      for (const pair in ajaxParams) {
        if (jQuery.inArray(pair, ['ghost', 'form_action', 'ffts', 'rts']) === -1) {
          rpItem = {}
          rpItem[pair] = ajaxParams[pair]
          rp.push(rpItem)
        }
      }
    }
    // console.info('Request parameters', rp, formActionParamEnabled)
    if ((methodeType === 'GET') && (rp.length > 0) && !formActionParamEnabled) {
      comParam.refreshBrowserGetParams(rp)
    }

    if ((methodeType === 'GET') && (ajaxParams instanceof FormData)) {
      const params: IComParamScopeItem = {}
      for (const pair of ajaxParams.entries()) {
        params[pair[0]] = pair[1].toString()
      }
      ajaxParams = params
    }

    switch (methodeType) {
      case 'GET':
        axios({
          url: url,
          method: 'get',
          params: ajaxParams
        })
          .then((response) => {
            if (typeof callback.done === 'function') callback.done(this.convertData(response.data), ajaxParams)
          })
          .catch((error) => {
            if (typeof callback.fail === 'function') callback.fail(error)
          })
          .finally(() => {
            // console.info('axios finally !!!')
          })
        break

      case 'POST':
        axios({
          url: url,
          method: 'post',
          data: ajaxParams
        })
          .then((response) => {
            if (typeof callback.done === 'function') callback.done(this.convertData(response.data), ajaxParams)
          })
          .catch((error) => {
            if (typeof callback.fail === 'function') callback.fail(error)
          })
          .finally(() => {
            // console.info('axios finally !!!')
          })
        break
    }
  }

  fetchData (url: string, data: any, callback: AppNGConnectorCallback): void {
    axios({
      url: url,
      method: 'get',
      params: data
    })
      .then((response: any) => {
        if (typeof callback.done === 'function') callback.done(response.data, data)
      })
      .catch((error) => {
        if (typeof callback.fail === 'function') callback.fail(error)
      })
      .finally(() => {
        // console.info('axios finally !!!')
      })
  }

  convertData (data: {[key: string]: any}): {[key: string]: any} {
    const r: {[key: string]: any} = {}
    const applicationId = data._self.split('/')[3]
    if (typeof data['event-id'] !== 'undefined') { // action form
      r.action = {}
      r.action._self = data._self
      r.action.execute = data._execute
      r.action.id = applicationId + '_' + data.id + '_' + data['event-id']
      r.action.originId = data.id
      r.action.title = data.title
      r.action.eventId = data['event-id']
      r.action.messages = (typeof data.messages !== 'undefined') ? data.messages : null
      r.action.permissions = this.getPermissions(data.permissions)
      r.action.params = (typeof data.parameters !== 'undefined') ? data.parameters : null
      r.action.layout = (typeof data.layout !== 'undefined') ? data.layout : null
      r.action.userdata = (typeof data.user !== 'undefined') ? data.user : null
      r.action.appNGVersion = (typeof data.appNGVersion !== 'undefined') ? data.appNGVersion : null
      r.action.appVersion = (typeof data.appVersion !== 'undefined') ? data.appVersion : null
      r.action.fields = this.getFieldsData4Action(data.fields)
    } else { // datasource
      r.datasource = {}
      r.datasource.id = applicationId + '_' + data.id
      r.datasource.originId = data.id
      r.datasource.title = data.title
      r.datasource.messages = (typeof data.messages !== 'undefined') ? data.messages : null
      r.datasource.permissions = this.getPermissions(data.permissions)
      r.datasource.fields = this.getFieldsData4Datasource(data.fields)
      r.datasource.filter = this.getFilterData(data.filters)
      r.datasource.links = (typeof data.links !== 'undefined') ? data.links : null
      r.datasource.page = (typeof data.page !== 'undefined') ? data.page : null
      r.datasource.params = (typeof data.parameters !== 'undefined') ? data.parameters : null
      r.datasource.sort = this.getSortData(data.fields)
      r.datasource.layout = (typeof data.layout !== 'undefined') ? data.layout : null
      r.datasource.userdata = (typeof data.user !== 'undefined') ? data.user : null
      r.datasource.appNGVersion = (typeof data.appNGVersion !== 'undefined') ? data.appNGVersion : null
      r.datasource.appVersion = (typeof data.appVersion !== 'undefined') ? data.appVersion : null
      r.datasource.resultset = this.getResultsetData(data)
    }
    return r
  }

  getFieldsData4Action (data: Array<{[key: string]: any}>): Array<{[key: string]: any}> {
    function getFieldObj (data: any): any {
      if (typeof data.fieldType !== 'undefined') {
        data['type'] = data.fieldType.toUpperCase()
        switch (data.fieldType.toUpperCase()) {
          case 'LIST_OBJECT': {
            const r: {[key: string]: any} = {
              type: 'LIST_OBJECT',
              props: []
            }
            if (data.fields !== null) {
              for (let i = 0; i < data.fields.length; i++) {
                r.props.push(getFieldObj(data.fields[i]))
              }
            }
            return r
          }
          case 'OBJECT': {
            const r: {[key: string]: any} = {}
            if (data.fields !== null) {
              for (let i = 0; i < data.fields.length; i++) {
                const arrN = data.fields[i].name.split('.')
                const objPropName = arrN[arrN.length - 1]
                r[objPropName] = getFieldObj(data.fields[i])
              }
            }
            return r
          }
          default: {
            data.rules = getFieldRules(data.rules)
            return data
          }
        }
      } else {
        return data
      }
    }
    function getFieldRules (rules: Array<{[key: string]: any}> | null | undefined): {[key: string]: any} | null {
      if ((typeof rules === 'undefined') || (rules === null)) return null
      const r = {}
      for (let k = 0; k < rules.length; k++) {
        const arrType = rules[k].type.split('.')
        if (arrType.length > 0) {
          const type = arrType[arrType.length - 1]
          r[type] = rules[k]
        }
      }
      return r
    }

    const fields: Array<{[key: string]: any}> = []
    if ((typeof data !== 'undefined') && (data !== null)) {
      for (let i = 0; i < data.length; i++) {
        let invalidField = false
        const field: {[key: string]: any} = {}
        field.index = i
        field.name = data[i].name
        field.type = data[i].visible ? data[i].fieldType.toUpperCase() : 'HIDDEN'
        field.format = (typeof data[i].format !== 'undefined') ? data[i].format : null
        field.label = (typeof data[i].label !== 'undefined') ? data[i].label : null
        field.readonly = data[i].readonly
        field.visible = data[i].visible
        field.messages = (typeof data[i].messages !== 'undefined') ? data[i].messages : null
        field.rules = getFieldRules(data[i].rules)
        field.fields = (typeof data[i].fields !== 'undefined') ? data[i].fields : null
        if ((field.type === 'RICHTEXT') && (typeof data[i].format === 'string')) {
          field.format = data[i].format
        }
        switch (field.type) {
          case 'SELECT':
          case 'RADIO':
          case 'LIST_SELECT':
          case 'LIST_CHECKBOX':
          case 'LIST_RADIO': {
            field.options = data[i].options
            if ((typeof field.options !== 'undefined') && (field.options !== null)) {
              if (field.options.multiple) {
                field.value = []
                if (typeof data[i].options.entries !== 'undefined' && data[i].options.entries !== null) {
                  for (let j = 0; j < data[i].options.entries.length; j++) {
                    if (typeof data[i].options.entries[j].options === 'undefined') {
                      if (data[i].options.entries[j].selected) {
                        field.value.push(data[i].options.entries[j].value)
                      }
                    } else { // option groups
                      for (let k = 0; k < data[i].options.entries[j].options.length; k++) {
                        if (data[i].options.entries[j].options[k].selected) {
                          field.value.push(data[i].options.entries[j].options[k].value)
                        }
                      }
                    }
                  }
                }
              } else {
                field.value = ''
                if (typeof data[i].options.entries !== 'undefined' && data[i].options.entries !== null) {
                  for (let j = 0; j < data[i].options.entries.length; j++) {
                    if (data[i].options.entries[j].selected) {
                      field.value = data[i].options.entries[j].value
                    }
                  }
                }
                field.value = field.value === '' ? null : field.value
              }
            } else {
              invalidField = true
            }
            break
          }
          case 'LIST_OBJECT': {
            field.props = []
            for (let j = 0; j < field.fields.length; j++) {
              field.props.push(getFieldObj(field.fields[j]))
            }
            break
          }
          case 'OBJECT': { // ToDo: Test
            field.props = {}
            for (let j = 0; j < field.fields.length; j++) {
              const arrN = field.fields[j].name.split('.')
              const objPropName = arrN[arrN.length - 1]
              field.props[objPropName] = getFieldObj(field.fields[j])
            }
            break
          }
          default: {
            field.value = data[i].value
            break
          }
        }
        if (!invalidField) {
          fields.push(field)
        } else {
          field.type = 'HIDDEN'
          fields.push(field)
        }
      }
    }
    return fields
  }

  getFieldsData4Datasource (data: Array<{[key: string]: any}>): Array<{[key: string]: any}> {
    const fields: Array<{[key: string]: any}> = []
    if (typeof data !== 'undefined') {
      for (let i = 0; i < data.length; i++) {
        const field: any = {}
        field.name = data[i].name
        field.type = data[i].fieldType.toUpperCase()
        field.label = (typeof data[i].label !== 'undefined') ? data[i].label : null
        field.format = (typeof data[i].format !== 'undefined') ? data[i].format : null
        field.fields = (typeof data[i].fields !== 'undefined') ? data[i].fields : null
        fields.push(field)
      }
    }
    return fields
  }

  getResultsetData (data: {[key: string]: any}): Array<{[key: string]: any}> | null {
    return (typeof data.items !== 'undefined') && (data.items !== null) ? data.items : (typeof data.item !== 'undefined' && data.item !== null ? [data.item] : null)
  }

  getDataRowByKeyName (data: any, key: string, value: string): any | null {
    if (typeof data !== 'object') return null
    for (let i = 0; i < data.length; i++) {
      if (data[i][key] === value) {
        return data[i]
      }
    }
    return null
  }

  getPermissions (list: Array<{[key: string]: any}> | null): {[key: string]: any} | null {
    const result: any = {}
    if ((typeof list === 'undefined') || (list === null)) return null
    for (let i = 0; i < list.length; i++) {
      result[list[i].ref] = {
        value: (typeof list[i].value !== 'undefined') ? list[i].value : null,
        mode: list[i].mode
      }
    }
    return result
  }

  getFilterData (data: Array<{[key: string]: any}> | null): Array<{[key: string]: any}> | null {
    const fields: Array<{[key: string]: any}> = []
    if ((typeof data === 'undefined') || (data === null)) return null
    for (let i = 0; i < data.length; i++) {
      const field: {[key: string]: any} = {}
      field.name = data[i].name
      field.label = data[i].label
      field.type = data[i].type.toUpperCase()
      field.format = (typeof data[i].format !== 'undefined') ? data[i].format : null
      switch (field.type) {
        case 'SELECT':
        case 'RADIO':
        case 'CHECKBOX':
        case 'LIST_SELECT':
        case 'LIST_RADIO':
        case 'LIST_CHECKBOX': {
          field.options = data[i].options
          if (field.options.multiple && field.type !== 'CHECKBOX') {
            field.value = []
            for (let j = 0; j < data[i].options.entries.length; j++) {
              if (typeof data[i].options.entries[j].options === 'undefined') {
                if (data[i].options.entries[j].selected) {
                  field.value.push(data[i].options.entries[j].value)
                }
              } else { // option groups
                for (let k = 0; k < data[i].options.entries[j].options.length; k++) {
                  if (data[i].options.entries[j].options[k].selected) {
                    field.value.push(data[i].options.entries[j].options[k].value)
                  }
                }
              }
            }
          } else {
            field.value = field.type !== 'CHECKBOX' ? '' : null
            for (let j = 0; j < data[i].options.entries.length; j++) {
              if (data[i].options.entries[j].selected) {
                field.value = field.type !== 'CHECKBOX' ? data[i].options.entries[j].value : data[i].options.entries[j].value === 'true'
              }
            }
            field.value = field.value === '' ? null : field.value
          }
          break
        }
        default: {
          field.value = data[i].options.entries[0].value
          break
        }
      }
      fields.push(field)
    }
    return fields
  }

  getSortData (data: Array<{[key: string]: any}>): Array<{[key: string]: any}> {
    const sort: Array<{[key: string]: any}> = []
    function compare (a: any, b: any): number {
      if (a.prio < b.prio) {
        return -1
      }
      if (a.prio > b.prio) {
        return 1
      }
      return 0
    }
    if (typeof data !== 'undefined') {
      for (let i = 0; i < data.length; i++) {
        if ((typeof data[i].sort !== 'undefined') && (data[i].sort !== null) && (typeof data[i].sort.pathAsc !== 'undefined')) {
          sort.push({
            name: data[i].name,
            order: typeof data[i].sort.order === 'undefined' ? '' : data[i].sort.order,
            prio: typeof data[i].sort.prio === 'undefined' ? -1 : data[i].sort.prio
          })
        }
      }
      sort.sort(compare)
    }
    return sort
  }
}
