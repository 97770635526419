/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Marks a field as sortable, properties are filled only when sorting has been applied.
 */
export type Sort = {
    /**
     * The priority for this sort, important when a multi-field sort is applied.
     */
    prio?: number;
    /**
     * The order for this sort (ascending or descending).
     */
    order?: Sort.order;
    /**
     * the path to sort ascending
     */
    pathAsc?: string;
    /**
     * the path to sort descending
     */
    pathDesc?: string;
};

export namespace Sort {

    /**
     * The order for this sort (ascending or descending).
     */
    export enum order {
        ASC = 'asc',
        DESC = 'desc',
    }


}
