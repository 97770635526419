<template>
  <fragment>
    <div v-if="field != null && (field.type == 'TEXT' || field.type == 'PASSWORD' || field.type == 'EMAIL' || field.type == 'INT' || field.type == 'LONG' || field.type == 'DECIMAL' || field.type == 'URL' || field.type == 'DATE')" v-bind:class="getClass()" class="mb-2">
      <f-input-text v-bind:field="field" v-bind:disabledRanges="disabledRanges" v-bind:startDateFieldName="startDateFieldName" v-bind:endDateFieldName="endDateFieldName" v-bind:error-message-required="errorMessageRequired" v-bind:error-message-invalid-input="errorMessageInvalidInput" v-bind:passwordStrengthMeter="passwordStrengthMeter" v-bind:autocomplete="autocomplete"></f-input-text>
    </div>
    <div v-else-if="field != null && (field.type == 'LONGTEXT' || field.type == 'RICHTEXT')" v-bind:class="getClass()" class="mb-2">
      <f-textarea v-bind:field="field" v-bind:error-message-required="errorMessageRequired" v-bind:error-message-invalid-input="errorMessageInvalidInput" v-bind:placeholder="placeholder"></f-textarea>
    </div>
    <div v-else-if="field != null && field.type == 'CHECKBOX'" v-bind:class="getClass()" class="mb-2">
      <f-input-checkbox v-bind:field="field" v-bind:error-message-required="errorMessageRequired"></f-input-checkbox>
    </div>
    <div v-else-if="field != null && (field.type == 'LIST_SELECT' || field.type == 'SELECT')" v-bind:class="getClass()" class="mb-2">
      <f-select v-bind:field="field" v-bind:error-message-required="errorMessageRequired"></f-select>
    </div>
    <div v-else-if="field != null && (field.type == 'LIST_RADIO' || field.type == 'RADIO')" v-bind:class="getClass()" class="mb-2">
      <f-input-radio v-bind:field="field" v-bind:error-message-required="errorMessageRequired"></f-input-radio>
    </div>
    <div v-else-if="field != null && (field.type == 'LIST_CHECKBOX' || field.type == 'CHECKBOX')" v-bind:class="getClass()" class="mb-2">
      <f-input-checkbox-list v-bind:field="field" v-bind:error-message-required="errorMessageRequired"></f-input-checkbox-list>
    </div>
    <div v-else-if="field != null && (field.type == 'FILE' || field.type == 'FILE_MULTIPLE')" v-bind:class="getClass()" class="mb-2">
      <f-input-file v-bind:field="field" v-bind:multiple="field.type == 'FILE_MULTIPLE'" v-bind:accept="accept" v-bind:error-message-required="errorMessageRequired" v-bind:error-message-invalid-input="errorMessageInvalidInput"></f-input-file>
    </div>
    <div v-else-if="field != null && field.type == 'IMAGE'" v-bind:class="getClass()" class="mb-2">
      <f-image v-bind:field="field" default-image="/assets/images/profil-dummy-pic.svg" width="250px"></f-image>
    </div>
    <div v-else-if="field != null && field.type == 'COORDINATE'" v-bind:class="getClass()" class="mb-2">
      <div class="col-sm-6" v-if="field.fields != null">
        <f-input-text v-bind:field="field.fields[0]" v-bind:error-message-required="errorMessageRequired" v-bind:error-message-invalid-input="errorMessageInvalidInput"></f-input-text>
        <f-input-text v-bind:field="field.fields[1]" v-bind:error-message-required="errorMessageRequired" v-bind:error-message-invalid-input="errorMessageInvalidInput"></f-input-text>
      </div>
    </div>
    <div v-else-if="field != null && field.type == 'LIST_TEXT'" v-bind:class="getClass()" class="mb-2">
      <div class="col-sm-12" v-if="field.fields != null">
        <f-input-text v-for="fieldItem in field.fields" v-bind:key="fieldItem.name" v-bind:field="fieldItem" v-bind:error-message-required="errorMessageRequired" v-bind:error-message-invalid-input="errorMessageInvalidInput"></f-input-text>
      </div>
    </div>
    <div v-else-if="field != null && field.type == 'HIDDEN'">
      <input type="hidden" v-bind:name="field.name" v-bind:value="field.value">
    </div>
    <div v-else v-bind:class="getClass()">
      <strong>Unknown element: </strong>
      {{ field.name }} | {{ field.type }} <br/>
    </div>
    <!-- TODO: OBJECT, LIST_OBJECT -->
  </fragment>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { IFormField } from '@/types/app.d'
import fInputText from '@/components/form/f-input-text.vue'
import fTextarea from '@/components/form/f-textarea.vue'
import fInputCheckbox from '@/components/form/f-input-checkbox.vue'
import fSelect from '@/components/form/f-select.vue'
import fInputRadio from '@/components/form/f-input-radio.vue'
import fInputCheckboxList from '@/components/form/f-input-checkbox-list.vue'
import fInputFile from '@/components/form/f-input-file.vue'
import fImage from '@/components/form/f-image.vue'

export default Vue.extend({
  name: 'f-field',
  components: {
    fInputText,
    fTextarea,
    fInputCheckbox,
    fSelect,
    fInputRadio,
    fInputCheckboxList,
    fInputFile,
    fImage
  },
  props: {
    field: Object as PropType<IFormField>,
    type: String,
    disabled: String,
    errorMessageRequired: String,
    errorMessageInvalidInput: String,
    changeYear: String,
    disabledRanges: String,
    startDateFieldName: String,
    endDateFieldName: String,
    cellSize: String,
    accept: String,
    autocomplete: String,
    passwordStrengthMeter: Boolean,
    placeholder: String
  },
  methods: {
    getClass (): string {
      return 'col-sm-' + (typeof this.cellSize === 'undefined' ? '12' : this.cellSize)
    }
  }
})
</script>
