<template>
  <div class="datasource" v-bind:class="{loading: showLoader}">
    <div v-cloak v-bind:id="'datasource_'+id">
      <div class="scroll-vertical-with-top-fixed-panel">
        <div class="w-100 position-relative">
          <div class="top-fixed-panel">
            <div  class="headline-panel">
              <h2 v-html="labels.headline"></h2>
            </div>
          </div>
        </div>
        <div class="scroll-wrapper">
          <div class="scroll-vertical">
            <div class="pe-2">
              <div class="row mb-5" v-if="results.length == 0">
                <div class="col-sm-12">
                  <p><strong v-html="labels.noNotifications"></strong></p>
                </div>
              </div>
              <div class="feed-item" v-for="row in results" v-bind:key="row.fields.type.value + '_' + row.fields.id.value" v-bind:class="{'without-thumbnail': !isThumbnailEnabled(row), 'is-new': row.fields.isNew.value}" :data-type="row.fields.type.value">
                <profilePictureThumbnail v-if="row.fields.author.value.visible.value" :linking="true" :nickName="row.fields.author.value.nickName.value" :pictureUrl="row.fields.author.value['image.previewPath'].value"></profilePictureThumbnail>
                <img v-else class="profile-picture thumbnail rounded" v-bind:src="getAvatarDummyPicture()" alt="Deleted User " title="Deleted User">
           
                <div class="text" :class="{'with-button-panel': isButtonPanelEnabled(row)}">
                  <div class="mb-2" :class="{'pe-3': row.fields.type.value === 'USER_COMMENT' || row.fields.type.value === 'COMMENT_LIKE_USER' || row.fields.type.value === 'FOLLOWER'}" v-html="getText(row)" @click="(e) => reloadSelfPage(e)"></div>
                  <Fragment v-if="row.fields.message.value">
                    <div v-if="isShortDescription(row.fields.message.value)" class="message quote">
                      <div v-html="row.fields.message.value"></div>
                    </div>
                    <div v-else class="message quote">
                      <div class="short-message"><span v-html="getShortDescription(row.fields.message.value)"></span> <a href="javascript:void(0)" class="icon chevron-down" @click="(e) => showMessage(e)">read more</a></div>
                      <div class="whole-message hidden"><span v-html="row.fields.message.value"></span> <a href="javascript:void(0)" class="icon chevron-up" @click="(e) => hideMessage(e)">read less</a></div>
                    </div>
                  </Fragment>
                  <relativeDateTime :dateTimeUTC="row.fields.dateTimeUTC.value"></relativeDateTime>
                </div>
                <div class="thumbnail" v-if="isThumbnailEnabled(row)">
                  <router-link :to="getFeedItemUrl(row)"><img v-bind:src="getThumbnailPicture(row)" alt="" @click="(e) => reloadSelfPage(e)"></router-link>
                </div>
                <div class="button-panel" v-if="isButtonPanelEnabled(row)">
                  <a v-if="row.fields.type.value === 'FRIENDSHIP_REQUEST'" href="javascript:void(0)" role="button" class="btn me-0 friendship-pending" v-bind:title="labels.tooltip.pending" @click="approveFriendship(row)"><span v-text="labels.tooltip.pending"></span></a>
                </div>
                <div class="button-remove-item">
                  <a href="javascript: void(0)" class="icon close" @click="(e) => removeNotification(e, row.fields.id.value, row.fields.type.value)"></a>
                </div>
              </div>
              <div v-if="loadingInProgress && !dataCompleteLoaded" class="datasource loading compact"></div>
            </div>
          </div>
        </div>
      </div>      
    </div>
    <ContentErrorMessage v-if="isDataRequestError"></ContentErrorMessage>
  </div>
</template>

<script lang="ts">
import mixins from 'vue-typed-mixins'
import MXDatasourceGeneral from '@/components/mixins/datasourceGeneral'
import jQuery from 'jquery'
import { Bus as VuedalsBus } from '@/components/vuedals/main'
import { globalVar } from '@/scripts/own/_globalVar'
import { uiService } from '@/scripts/services/UIService'
import { dataService } from '@/scripts/services/DataService'
import { dateTimeService } from '@/scripts/services/DateTimeService'
import { generalService } from '@/scripts/services/GeneralService'
import { appComponentsStatesService } from '@/scripts/services/AppComponentsStatesService'
import clip from "text-clipper"
import ContentErrorMessage from '@/components/_content_error_message.vue'
import profilePictureThumbnail from '@/components/_profile_picture_thumbnail.vue'
import relativeDateTime from '@/components/_relative-date-time.vue'
import { IComponentDataInfinityScrolling } from '@/types/app.d'

export default mixins(MXDatasourceGeneral).extend({
  name: 'DatasourceNotifications',
  components: {
    ContentErrorMessage,
    profilePictureThumbnail,
    relativeDateTime
  },
  data (): IComponentDataInfinityScrolling {
    return {
      id: '',
      guid: '',
      originId: '',
      title: '',
      filter: {},
      setFilters: [],
      field: {},
      links: [],
      pagination: null,
      params: {},
      permissions: {},
      results: [],
      sort: [],
      labels: {},
      layout: {},
      messages: null,
      images: null,
      currentUrl: '',
      isDataRequestError: null,
      pageSize: 10,
      loadingInProgress: false,
      dataCompleteLoaded: false
    }
  },
  mounted (): void {
    this.$on('afterChangeDatasource', (e: any): void => {
      if (e.data.id === 'voyoa_acceptFriendship_friendshipEvents') {
        //replace placeholder and show message
        const data = e.data
        if (!generalService.haveFieldsErrors(data.field) && generalService.wasRequestSuccessfully(data)) {
          jQuery('#alert-message-panel *').remove()
          const messages = uiService.getOkMessage(data)
          let responseMessage = ''
          for (let i = 0; i < messages.length; i++) {
            responseMessage += (i > 0 ? '<br/>' : '') + jQuery.string.replacePlaceHolders(messages[i], {
              nickName: this.$store.state.user?.nickName,
              initiatorNickName: data.params.nickName
            })
          }
          if (responseMessage !== '') uiService.showAlertMessage(responseMessage, 'success')
        }
      }
    })

    const waitForOpenInViedals = (callback) => {
      if (jQuery('.vuedal .scroll-vertical').length === 0) {
        setTimeout(() => waitForOpenInViedals(callback), 100)
      } else {
        if (typeof callback === 'function') callback()
      }
    }
    waitForOpenInViedals(() => {
      this.initVerticalScrolling()
      
      jQuery('.vuedals > div').on('click', (e: any): void => {
        uiService.closeAllDropdownOnVuedalClick(e)
      })      
    })
  },
  updated (): void {
    jQuery('a[data-router-link="true"]:not([data-initialized="true"])').each((index: number, that: HTMLElement): void => {
      jQuery(that).attr('data-initialized', 'true').on('click', (e: any) => {
        if (!e.originalEvent.ctrlKey) {
          e.preventDefault()
          const href: any = jQuery(e.target).attr('href')
          if (href) this.$router.push(href)
        }
      })
    })
  },
  methods: {
    startFetchData (): void {
      this.initInfinityScrolling()
      this.fetchData()
    },
    fetchData (maxDate?: number, callback?: any): void {
      if (this.componentSourceURL) {
        const params = {
          maxDate: typeof maxDate !== 'undefined' ? maxDate : dateTimeService.getLocalDateTimeUTC(), 
          days: 14
        }
        dataService.getContentData4Datasource(this, this.componentSourceURL, params, (data: any) => {
          if (typeof maxDate === 'undefined') { // first page request
            dataService.updateComponentData(this, data)
            this.restoreSavedState()
            if (this.results.length >= 1 && this.results.length < 15) this.loadNextPage()
          } else {
            if (data.results !== null && data.results.length > 0) {
              for (let i = 0; i < data.results.length; i++) {
                this.results.push(data.results[i])
              }
              this.pagination = data.pagination
              if (this.results.length >= 1 && this.results.length < 15) this.loadNextPage()
            } else { // all pages are loaded
              if (typeof callback === 'function') callback({status: 'DATASOURCE_COMPLETE_LOADED'})
              return
            }
          }
          this.$nextTick(() => {
            this.initVerticalScrolling()
          })          
          if (typeof callback === 'function') callback()
        })
      }
    },
    refreshContent (): void {
      this.fetchData()
    },
    initInfinityScrolling (): void {
      jQuery('#datasource_' + this.id + ' .scroll-vertical').on(generalService.getScrollendEventName(), (scroller: any): void => {
        if ((scroller.target.scrollTop + scroller.target.clientHeight + scroller.target.clientHeight / 2) >= scroller.target.scrollHeight) {
          this.loadNextPage()
        }
      })
    },
    loadNextPage (): void {
      if (this.loadingInProgress || this.dataCompleteLoaded) return
      this.loadingInProgress = true
      const resLength = this.results.length
      if (resLength > 0) {
        this.fetchData((this.results[resLength - 1].fields.dateTimeUTC.value - 1000), (e?: {[key: string]: string}): void => {
          if (typeof e !== 'undefined' && e.status === 'DATASOURCE_COMPLETE_LOADED') {
            this.dataCompleteLoaded = true
          }
          this.loadingInProgress = false
        })
      }
    },    
    formatDateTimeOutput (dateTime: string): string {
      return dateTimeService.formatDateTimeOutput(dateTime)
    },
    getAvatarDummyPicture (): string{
      return globalVar.appEngine.images.avatarDummyPicture
    }, 
    getDateTimeTimezoneString(dateTimeUTC: number, timeZoneName: string) {
      return dateTimeService.getDateTimeTimezoneString(this, dateTimeUTC, timeZoneName)
    },
    getDistanceInfo (data: {[key: string]: any}): string {
      return uiService.getDistanceInfo(data)
    },
    getSpeedInfo (data: {[key: string]: any}): string {
      return uiService.getSpeedInfo(data)
    },
    getSpeedInfoTooltip (data: {[key: string]: any}): string {
      return uiService.getSpeedInfoTooltip(data)
    },
    saveViewState ():void {
      appComponentsStatesService.saveViewState('NOTIFICATIONS_VIEW', {
        pagination: {
          page: this.pagination ? this.pagination.number : 0,
          pageSize: this.pagination ? this.pagination.size : 0
        },
        scrollTop: jQuery(window).scrollTop()
      })
    },
    getSavedViewState (): any {
      return appComponentsStatesService.getSavedViewState('NOTIFICATIONS_VIEW')
    },
    restoreSavedState (): void {
      const state = this.getSavedViewState()
      if (state !== null) {
        setTimeout(() => {
          window.scrollTo(0, state.scrollTop)
        }, 100)
        appComponentsStatesService.deleteSavedViewState('NOTIFICATIONS_VIEW')
      }
    },
    isThumbnailEnabled (row: any): boolean {
      const typesWithoutThumbnail = ['USER_COMMENT', 'USER_COMMENT_REPLY', 'COMMENT_LIKE_USER', 'FRIENDSHIP_REQUEST', 'FRIENDSHIP_ACCEPT', 'FRIENDSHIP_CONFIRM', 'MENTION_USER', 'MENTION_COMMENT_USER', 'FOLLOWER']
      return !typesWithoutThumbnail.includes(row.fields.type.value)
    },
    isButtonPanelEnabled (row: any): boolean {
      const typesWithButtonPanel = ['FRIENDSHIP_REQUEST'] //['FRIENDSHIP_REQUEST', 'FRIENDSHIP_ACCEPT', 'FRIENDSHIP_CONFIRM']
      return typesWithButtonPanel.includes(row.fields.type.value)
    },
    getText (row: any): string {
      const typesWithSingleForm = ['ASSET_LIKE', 'ACTIVITY_LIKE', 'COMMENT_LIKE_USER', 'COMMENT_LIKE_ACTIVITY', 'COMMENT_LIKE_ASSET']
      const typesWithoutOtherOwner = ['FOLLOWER', 'FRIENDSHIP_ACCEPT', 'FRIENDSHIP_CONFIRM', 'FRIENDSHIP_REQUEST', 'MENTION_USER', 'MENTION_ACTIVITY', 'MENTION_ASSET', 'MENTION_COLLECTION', 'MENTION_POINT']
      const isSingleForm = row.fields.count.value === 0 && typesWithSingleForm.includes(row.fields.type.value)
      const isReply = row.fields.replyToId?.value !== ''
      const author = row.fields.author?.value?.nickName.value
      const authorVisible = row.fields.author?.value?.visible.value
      const owner = row.fields.owner?.value?.nickName.value
      const isSameOwner =  owner === this.$store.state.user?.nickName
      const isOtherOwner = !typesWithoutOtherOwner.includes(row.fields.type.value) && !isSameOwner
      let messageKey= 'feed.' + row.fields.type.value + (isSingleForm ? '.single' : '') + (isReply ? '.reply' : '') + (isOtherOwner ? '.otherOwner' : '')
      
      const placeholders = {
        authorNickName: (authorVisible) ? '<a href="/' + author +'" data-router-link="true">' + author + '</a>' : '<span style="opacity:0.8;">' + author + '</span>',
        activityTitle: '<a href="' + this.getFeedItemUrl(row) +'" data-router-link="true">' + row.fields.activityName.value + '</a>',
        ownerNickName: '<a href="/' + owner +'" data-router-link="true">' + owner + '</a>'
      }
      switch (row.fields.type.value) {
        case 'ASSET_LIKE':
        case 'ACTIVITY_LIKE':
        case 'COMMENT_LIKE_ASSET':
        case 'COMMENT_LIKE_ACTIVITY': {
          placeholders['numberOfOtherLikes'] = row.fields.count.value
          break
        }
        case 'USER_COMMENT': {
          placeholders['userCommentUrl'] = '/' + this.$store.state.user?.nickName + '#referenceId=commentsForUser;id=' + row.fields.commentId.value + (isReply ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
        case 'USER_COMMENT_REPLY': {
          placeholders['ownerNickName'] = '<a href="/' + owner + '#referenceId=commentsForUser;id=' + row.fields.commentId.value + (isReply ? ';replyToId=' + row.fields.replyToId.value : '') +'">' + owner + '</a>'
          placeholders['userCommentUrl'] = '/' + this.$store.state.user?.nickName + '#referenceId=commentsForUser;id=' + row.fields.commentId.value + (isReply ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }        
        case 'COMMENT_LIKE_USER': {
          placeholders['nickName'] = '<a href="/' + owner + '#referenceId=commentsForUser;id=' + row.fields.commentId.value + (isReply ? ';replyToId=' + row.fields.replyToId.value : '') + '" data-router-link="true">' + owner + '</a>'
          placeholders['numberOfOtherLikes'] = row.fields.count.value
          break
        }
        case 'FRIENDSHIP_REQUEST': {
          placeholders['requestMessage'] = '' //row.fields.message.value ? '<div class="quote">' + this.getMessage(row.fields.message.value) + '</div>' : ''
          break
        }
        case 'MENTION_USER': {
          placeholders['userCommentUrl'] = '/' + owner
          break
        }
        case 'MENTION_COMMENT_USER': {
          messageKey += isSameOwner ? '.sameOwner' : ''
          placeholders['userCommentUrl'] = '/' + owner + '#referenceId=commentsForUser;id=' + row.fields.commentId.value + (isReply ? ';replyToId=' + row.fields.replyToId.value : '')
          placeholders['profile-page-owner'] = '<a href="/' + owner + '#referenceId=commentsForUser;id=' + row.fields.commentId.value + (isReply ? ';replyToId=' + row.fields.replyToId.value : '') + '" data-router-link="true">' + owner + '</a>'
          break
        }
      }
      let content = uiService.getGlobalMessageText(messageKey)
      content = jQuery.string.replacePlaceHolders(content, placeholders)
      return content
    },
    getFeedItemUrl (row: any): string {
      let url = ''
      const activityId = row.fields.activityId?.value;
      const owner = row.fields.owner?.value?.nickName.value
      const urlTitle = row.fields.urlTitle?.value
      switch (row.fields.type.value) {
        case 'ACTIVITY_COMMENT':
        case 'ACTIVITY_COMMENT_REPLY': {
          url = '/'+ owner +'/activities#referenceId=commentsForActivity;id='+ row.fields.commentId.value +';activityId=' + activityId + (row.fields.replyToId?.value ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
        case 'ASSET_COMMENT':
        case 'ASSET_COMMENT_REPLY': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId +'#referenceId=commentsForAsset;id=' + row.fields.commentId.value +';activityId=' + activityId + ';itemId=' + row.fields.assetId.value + (row.fields.replyToId?.value ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
        case 'ASSET_LIKE': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId +'#referenceId=pointAsset;id=' + row.fields.assetId.value +';activityId=' + activityId
          break
        }
        case 'ACTIVITY_LIKE': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId
          break
        }
        case 'COMMENT_LIKE_ACTIVITY': {
          url = '/'+ owner +'/activities#referenceId=commentsForActivity;id='+ row.fields.commentId.value +';activityId=' + activityId + (row.fields.replyToId?.value ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
        case 'COMMENT_LIKE_ASSET': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId +'#referenceId=commentsForAsset;id=' + row.fields.commentId.value +';activityId=' + activityId + ';itemId=' + row.fields.assetId.value + (row.fields.replyToId?.value ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
        case 'MENTION_ASSET': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId +'#referenceId=pointAsset;id=' + row.fields.assetId.value +';activityId=' + activityId
          break
        }
        case 'MENTION_ACTIVITY': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId
          break
        }
        case 'MENTION_COLLECTION': {
          url = '/'+ owner + '/collection/' + row.fields.collectionId.value
          break
        }
        case 'MENTION_COMMENT_ACTIVITY': {
          url = '/'+ owner +'/activities#referenceId=commentsForActivity;id='+ row.fields.commentId.value +';activityId=' + activityId + (row.fields.replyToId?.value ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
        case 'MENTION_COMMENT_ASSET': {
          url = '/'+ owner + '/' + urlTitle + '/activity/' + activityId +'#referenceId=commentsForAsset;id=' + row.fields.commentId.value +';activityId=' + activityId + ';itemId=' + row.fields.assetId.value + (row.fields.replyToId?.value ? ';replyToId=' + row.fields.replyToId.value : '')
          break
        }
      }      
      return url
    },
    getMessage (text: string): string {
      return clip(text, 256, { html: true, maxLines: 3 })
    },
    getThumbnailPicture (row: any): string {
      return row.fields.thumbnailPath.value ? row.fields.thumbnailPath.value : this.dummyPictureUrl
    },
    approveFriendship (row: any): void {
      const message = row.fields.message.value ? '<div class="mb-3">'+ this.labels.dialog.pending.message +' <i>' + row.fields.message.value + '</i></div>' : ''
      let content = '<div>' + this.labels.dialog.pending.text + '</div>' + message
      content = jQuery.string.replacePlaceHolders(content, {
        initiatorNickName: row.fields.author.value.nickName.value
      })
      uiService.showModal({
        title: this.labels.dialog.pending.headline,
        content: content,
        footer: {
          buttons: [
            {
              label: this.labels.dialog.pending.button.approve,
              class: 'btn-primary',
              action: ():void =>  {
                VuedalsBus.$emit('close')
                const sourceURL = this.getServiceUrl('acceptFriendship')
                if (sourceURL) {
                  uiService.showViewInPopup('ActionPopup', sourceURL, {nickName: row.fields.author.value.nickName.value, executeDirectly: true}, {}, this, 'md hidden')
                }
              }
            },
            {
              label: this.labels.dialog.pending.button.decline,
              class: 'btn-primary',
              action: ():void =>  {
                VuedalsBus.$emit('close')
                const sourceURL = this.getServiceUrl('cancelFriendship')
                if (sourceURL) {
                  uiService.showViewInPopup('ActionPopup', sourceURL, {nickName: row.fields.author.value.nickName.value, executeDirectly: true}, {}, this, 'md hidden')
                }
              }
            },
            {
              label: this.labels.dialog.pending.button.cancel,
              class: 'btn-secondary',
              action: ():void => {
                VuedalsBus.$emit('close')
              }
            }
          ]
        }
      })
    },
    removeNotification (e: any, id: number, type: string): void {
      const sourceURL = dataService.getServiceUrl(this, 'acknowledge')
      if (sourceURL) {
        jQuery(e.target).parents('.feed-item').hide()
        dataService.getContentData4Action(this, sourceURL, {}, (data: any) => {
          if (data.messages.length === 0) {
            const requestData = new FormData()
            requestData.append('form_action', data.originId)
            requestData.append('id', id.toString())
            requestData.append('type', type)
            dataService.postData4Action(this, data.execute, requestData, (postResponseData: any) => {
              //nothing 
            }, true)
          }
        })
      }
    },
    trimDescription (text: string): string {
      return generalService.trimHTMLText(text)
    },
    isShortDescription (text: string): boolean {      
      return this.trimDescription(text).length < 128
    },
    getShortDescription (text: string): string {
      //clip(text, 128, { html: true, stripTags: ["br"], maxLines: 3 })
      return clip(this.trimDescription(text), 128, { html: true, maxLines: 3 })
    },
    showMessage (e: any): void {
      jQuery(e.target).parents('.message').find('.short-message').addClass('hidden')
      jQuery(e.target).parents('.message').find('.whole-message').removeClass('hidden')
    },
    hideMessage (e: any): void {
      jQuery(e.target).parents('.message').find('.whole-message').addClass('hidden')
      jQuery(e.target).parents('.message').find('.short-message').removeClass('hidden')
    },
    initVerticalScrolling (): void {
      uiService.initVerticalScrollingInVuedal()
    },
    reloadSelfPage (e: any): void {
      if (e.target.tagName !== 'A' &&  e.target.tagName !== 'IMG') return
      setTimeout(() => {
        location.reload()
      }, 250)
    }
  }
})
</script>
