const polling = {
  options: {
    selector: '',
    errorMessage: 'error: data is undefined'
  },
  getData (options: {[key: string]: any}, obj: HTMLElement): void {
    function escapeHtml (str: string): string {
      const div = document.createElement('div')
      div.appendChild(document.createTextNode(str))
      return div.innerHTML
    }
    function scrollToBottomEnabled (jObj: any):boolean {
      return ((jObj.parent().scrollTop() + jObj.parent().outerHeight()) >= jObj.outerHeight())
    }
    $.get(options.src, (data: any) => {
      const jObj = $(obj)
      const scrollEnabled = scrollToBottomEnabled(jObj)
      jObj.html(escapeHtml(data))
      if (scrollEnabled) {
        if (!jObj.is('textarea')) {
          const scrollValue = jObj.outerHeight()
          if (typeof scrollValue !== 'undefined') jObj.parent().scrollTop(scrollValue)
        } else {
          const scrollValue = jObj.prop('scrollHeight')
          if (typeof scrollValue !== 'undefined') jObj.scrollTop(scrollValue)
        }
      }
    }).fail(() => {
      $(options.selector).html(options.errorMessage)
    })
  },
  pollData (options: {[key: string]: any}, obj: HTMLElement): void {
    this.getData(options, obj)
    if (options.interval) {
      setTimeout(() => { this.pollData(options, obj) }, parseInt(options.interval))
    }
  },
  init (options?: {[key: string]: any}): void {
    const opt = $.extend({}, this.options, options)
    $(opt.selector).each((i: number, that: any) => {
      if (typeof $(that).attr('data-initialized') === 'undefined') {
        $(that).attr('data-initialized', 'true')
        opt.src = $(that).attr('data-polling-src')
        opt.interval = $(that).attr('data-polling-interval')
        this.pollData(opt, that as HTMLElement)
      }
    })
  }
}
export { polling }
