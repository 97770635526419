<template>
  <div class="login">
    <app-header/>
    <div class="page">
      <section class="text-start">
        <div class="tab-box">
          <div class="component-box">
            <ActionLogin :sourceURL="getServiceUrl('login')" :dontShowImmediately="false"></ActionLogin>
          </div>
        </div>
      </section>
      <vuedal></vuedal>
    </div>
    <app-footer/>
  </div>
</template>

<script lang="ts">
import mixins from 'vue-typed-mixins'
import MXPageGeneral from '@/components/mixins/pageGeneral'
import { generalService } from '@/scripts/services/GeneralService'
import { dataService } from '@/scripts/services/DataService'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'
import ActionLogin from '@/components/ActionLogin.vue'

export default mixins(MXPageGeneral).extend({
  name: 'Login',
  components: {
    ActionLogin
  },
  mounted (): void {
    generalService.log('View "LoginPage.vue" mounted')
    if (this.isAuthenticated) this.$router.replace({name: 'dashboard'})
  },
  created (): void {
    this.$nextTick((): void => {
      generalService.log('View "LoginPage.vue" created')
      this.$on('afterSendActionData', (data: {[key: string]: any}) => {
        generalService.log('View "PageLogin.vue" - afterSendActionData', data)

        if (data.user.authenticated) {
          dataService.getContentData4Datasource(this, this.getServiceUrl('profile'), {nickName: data.user.nickName}, (profileData: any) => {
            if (profileData.results && (profileData.results.length > 0) &&  profileData.results[0].fields['image.previewPath'].value) {
              localStorage.setItem('userAvatarPicture', profileData.results[0].fields['image.previewPath'].value)
            } else {
              localStorage.removeItem('userAvatarPicture')
            }

            if (profileData.results && (profileData.results.length > 0) &&  profileData.results[0].fields['unit'].value) {
              localStorage.setItem('userUnitsType', profileData.results[0].fields['unit'].value)
            }
            if (!this.isPendingAccountToDelete(data.messages)) {
              sessionStorage.removeItem('pendingAccountToDelete')
              const redirectUrl = sessionStorage.getItem('redirectUrl')
              if (data.messages && data.messages.length === 0) {
                if (redirectUrl !== null) {
                  this.$router.replace(redirectUrl)
                  sessionStorage.removeItem('redirectUrl')
                } else {
                  this.$router.replace({ name: 'dashboard', params: {} })
                }
              } else {
                const messagesState = dataService.getMessagesState(data.messages)
                if (messagesState.ERROR === 0) {
                  if (redirectUrl !== null) {
                    this.$router.replace(redirectUrl)
                    sessionStorage.removeItem('redirectUrl')
                  } else {
                    this.$router.replace({ name: 'dashboard', params: {} })
                  }
                }
              }
            } else {
              sessionStorage.setItem('pendingAccountToDelete', 'true')
              this.$router.replace({ name: 'restoreAccount', params: {} })
            }

          })
        }
      })
      vueTemplateService.$emit4AllParents(this, 'setViewPortMode', 'default')
    })
  },
  methods: {
    isPendingAccountToDelete (messages: Array<any>): boolean {
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].level === 'NOTICE' && messages[i].text === 'ok.logon.delete_pending') return true
      }
      return false
    }
  }
})
</script>
