/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * A link.
 */
export type Link = {
    /**
     * * page - Links to a page.
 * * extern - Links to an external resource.
 * * intern - Links to an internal resource.
 * * action - Links to an action.
 * * datasource - Links to a datasource
 * 
     */
    type: Link.type;
    /**
     * The ID for this link.
     */
    id?: string;
    /**
     * The label for the link.
     */
    label?: string;
    /**
     * The target for the link.
     */
    target?: string;
    /**
     * A descriptive name for an icon that should be used for this link.
     */
    icon?: string;
    /**
     * Whether or not this link should be considered as default.
     */
    default?: boolean;
    /**
     * A confirmation message that should be displayed to the user before following this link.
     */
    confirmation?: string;
};

export namespace Link {

    /**
     * * page - Links to a page.
 * * extern - Links to an external resource.
 * * intern - Links to an internal resource.
 * * action - Links to an action.
 * * datasource - Links to a datasource
 * 
     */
    export enum type {
        PAGE = 'page',
        EXTERN = 'extern',
        INTERN = 'intern',
        ACTION = 'action',
        DATASOURCE = 'datasource',
    }


}
