<template>
  <div class="form-group">
    <label v-bind:for="id" class="input-text">{{field.label}}</label>
    <img v-if="imageUrl != false" v-bind:src="imageUrl" v-bind:width="width" alt="" title="">
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { IFormImage } from '@/types/app.d'
import { generalService } from '@/scripts/services/GeneralService'

export default Vue.extend({
  name: 'f-image',
  data (): {[key: string]: any} {
    return {
      guid: ''
    }
  },
  props: {
    field: Object as PropType<IFormImage>,
    type: String,
    disabled: String,
    errorMessageRequired: String,
    errorMessageInvalidInput: String,
    defaultImage: String,
    width: String
  },
  mounted (): void {
    this.guid = generalService.createGuid()
  },
  computed: {
    id (): string {
      return  typeof this.field !== 'undefined' ? 'id_' + this.field.name.replace(/\./g, '_') + '_' + this.guid : 'id_undefined_' + this.guid
    },
    imageUrl (): string | boolean {
      return this.field.value ? this.field.value : (this.defaultImage ? this.defaultImage : false)
    }
  }
})
</script>
