<template>
  <div>
    <div class="form-group mark-container form-check" v-bind:class="{'info-tooltip': hasTooltip}">
      <input type="checkbox"
        data-type="checkbox"
        class="form-check-input"
        v-bind:class="{'invalid-backend': isInvalid}"
        v-bind:id="id"
        v-bind:name="fieldName"
        v-bind:required="isRequired"
        v-bind:readonly="isReadonly"
        v-bind:disabled="isReadonly"
        v-model="value"
        value="true"
        v-bind:data-error-message-selector="errorMessageSelector"
        v-bind:data-error-message="errorMessage"
        v-on:change="onChange"
      >
      <label v-bind:for="id" v-bind:class="{required:isRequired}" class="checkbox"><div v-html="fieldLabel"></div></label>
      <span v-if="hasTooltip" class="info-icon" v-tooltip="getTooltipText(tooltip)" v-bind:title="tooltip" @click="onClickInfo" v-html="getInfoIcon()"></span>
      <input v-if="isReadonly && isChecked" type="hidden" v-bind:name="field.name" v-bind:value="field.value">
    </div>
    <div class="error-message" v-bind:id="errorMessageID"></div>
    <div class="error-message backend"><p v-for="message in errorMessages" v-bind:key="message" v-html="getMessageText(message)"></p></div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import jQuery from 'jquery'
import { globalVar } from '@/scripts/own/_globalVar'
import { IFormCheckbox } from '@/types/app.d'
import { generalService } from '@/scripts/services/GeneralService'
import { vueTemplateService } from '@/scripts/services/VueTemplateService'

export default Vue.extend({
  name: 'f-input-checkbox',
  data (): {[key: string]: any} {
    return {
      guid: '',
      value: null
    }
  },
  props: {
    field: Object as PropType<IFormCheckbox>,
    tooltip: String,
    errorMessageRequired: String
  },
  watch: {
    'field.value' (newVal: any): void {
      //console.info('!!! >>> watch new val:', newVal, '|', this.value, this.field.name)
      if (typeof newVal !== 'undefined') {
        if ((newVal === null) || (this.value === null) || (newVal !== this.value)) {
          this.value = newVal
        }
      }
    }
  },
  updated (): void {
    this.$emit4AllParents('CheckboxUpdated', {
      $props: this.$props,
      value: this.value,
      guid: this.guid
    })
  },
  mounted (): void {
    this.guid = generalService.createGuid()
    this.value = this.field.value
  },
  computed: {
    id (): string {
      return  typeof this.field !== 'undefined' ? 'id_' + this.field.name.replace(/\./g, '_') + '_' + this.guid : 'id_undefined_' + this.guid
    },
    isChecked (): boolean {
      return typeof this.field !== 'undefined' ? (this.field.value === true) : false
    },
    fieldName (): string {
      const fieldName = typeof this.field !== 'undefined' ? this.field.name : 'undefined'
      return !this.isReadonly ? fieldName : (fieldName + '_disabled')
    },
    isRequired (): boolean {
      return typeof this.field === 'undefined' ? false : this.field.required
    },
    isReadonly (): boolean {
      return typeof this.field === 'undefined' ? true : this.field.readonly
    },
    errorMessage (): string {
      return this.errorMessageRequired ? this.errorMessageRequired : (this.field.errorMessage === undefined ? 'required' : this.field.errorMessage)
    },
    errorMessageID (): string {
      return 'error_' + this.id
    },
    errorMessageSelector (): string {
      return '#' + this.errorMessageID
    },
    fieldLabel (): string | undefined {
      return this.$slots.default !== undefined ? this.$slots.default[0].text : (typeof this.field !== 'undefined' ? this.field.label : '')
    },
    errorMessages (): string | null {
      const r: any = []
      if ((typeof this.field !== 'undefined') && (this.field.messages !== null)) {
        for (let i = 0; i < this.field.messages.length; i++) {
          if (this.field.messages[i].level === 'ERROR') r.push(this.field.messages[i].text)
        }
        return r
      } return null
    },
    isInvalid (): boolean {
      if ((typeof this.field !== 'undefined') && (this.field.messages !== null)) {
        for (let i = 0; i < this.field.messages.length; i++) {
          if (this.field.messages[i].level === 'ERROR') return true
        }
      } return false
    },
    hasTooltip (): boolean {
      return typeof this.tooltip !== 'undefined' && this.tooltip !== null
    }
  },
  methods: {
    onChange (e: any): void {
      this.value = e.target.checked
    },
    getMessageText (index: string): string {
      return typeof globalVar.message[index] !== 'undefined' ? globalVar.message[index] : index
    },
    getTooltipText(tooltip: any): string | object {
      if (tooltip) {
        return {content: tooltip, trigger: 'click'}
      } else return ''
    },
    getInfoIcon(): string {
      return globalVar.appEngine.images.infoIcon
    },
    onClickInfo(e :any): void {
      jQuery('.has-tooltip.v-tooltip-open').trigger('click')
    },
    $emit4AllParents (eventName: string, data: any): void {
      vueTemplateService.$emit4AllParents(this, eventName, data)
    }
  }
})
</script>
