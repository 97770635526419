/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The possible types for an option.
 */
export enum OptionType {
    SELECT = 'select',
    SELECT_MULTIPLE = 'select_multiple',
    RADIO = 'radio',
    CHECKBOX = 'checkbox',
    TEXT = 'text',
    DATE = 'date',
}
