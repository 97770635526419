class Password {
  private password: string
  private spcChars: string

  private lcaseCount: number
  private ucaseCount: number
  private numCount: number
  private scharCount: number
  private length: number
  private strength: number
  private runScore: number
  private verdict: string

  private verdictConv: {[key: string]: number}
  private flc: number
  private fuc: number
  private fnm: number
  private fsc: number

  constructor (password: string | null, spcChars: string) {
    this.password = password === null ? '' : password
    this.spcChars = spcChars

    this.lcaseCount = 0
    this.ucaseCount = 0
    this.numCount = 0
    this.scharCount = 0
    this.length = 0
    this.strength = 0
    this.runScore = 0
    this.verdict = ''

    this.verdictConv = { veryweak: 2, weak: 5, medium: 53, strong: 150, verystrong: 250 }
    this.flc = 1.0
    this.fuc = 1.0
    this.fnm = 1.3
    this.fsc = 1.5
  }

  public getPwStrength (): any {
    if ((this.runScore = this.detectPwRuns()) <= 1) {
      this.strength = 1
    } else {
      const regexSc = new RegExp('[' + this.spcChars + ']', 'g')

      const lcaseCount = this.password.match(/[a-z]/g)
      this.lcaseCount = lcaseCount ? lcaseCount.length : 0

      const ucaseCount = this.password.match(/[A-Z]/g)
      this.ucaseCount = ucaseCount ? ucaseCount.length : 0

      const numCount = this.password.match(/[0-9]/g)
      this.numCount = numCount ? numCount.length : 0

      const scharCount = this.password.match(regexSc)
      this.scharCount = scharCount ? scharCount.length : 0

      this.length = this.password.length

      const avg = this.length / 4

      this.strength = ((this.lcaseCount * this.flc + 1) * (this.ucaseCount * this.fuc + 1) * (this.numCount * this.fnm + 1) * (this.scharCount * this.fsc + 1)) / (avg + 1)
    }

    if (this.strength > this.verdictConv.verystrong) {
      this.verdict = 'verystrong'
    } else if (this.strength > this.verdictConv.strong) {
      this.verdict = 'strong'
    } else if (this.strength > this.verdictConv.medium) {
      this.verdict = 'medium'
    } else if (this.strength > this.verdictConv.weak) {
      this.verdict = 'weak'
    } else if (this.strength > this.verdictConv.veryweak) {
      this.verdict = 'veryweak'
    } else {
      this.verdict = 'empty'
    }

    this.strength = this.strength > this.verdictConv.verystrong ? this.verdictConv.verystrong : this.strength
    let percent = this.getPwPercent()
    percent = percent < 0 ? 0 : percent

    return { verdict: this.verdict, percent: percent }
  }

  private getPwPercent (): number {
    return this.strength <= 0 ? 0 : Math.floor((this.strength / this.verdictConv.verystrong) * 100) - 1
  }

  private detectPwRuns (): number {
    const pwParts = this.password.split('')

    const ords: Array<any> = []
    for (let i = 0; i < pwParts.length; i++) {
      ords[i] = pwParts[i].charCodeAt(0)
    }

    let accum = 0
    const lasti = ords.length - 1

    for (let i = 0; i < lasti; ++i) {
      accum += Math.abs(ords[i] - ords[i + 1])
    }
    return (accum / lasti)
  }
}
export { Password }
