<template>
  <div class="dateTime" v-bind:title="getDateTimeString(dateTimeUTC)">
    <span v-if="mode === 0" class="cursor-pointer" @click="showAbsoluteDateTime()">{{ getRelativeDateTime(dateTimeUTC) }}</span>
    <span v-if="mode === 1">{{ getDateTimeString(dateTimeUTC) }}</span>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { dateTimeService } from '@/scripts/services/DateTimeService'

export default Vue.extend({
  name: 'relativeDateTime',
  props: {
    dateTimeUTC: Number
  },
  data (): any {
    return {
      mode: 0
    }
  },
  watch: {
    dateTimeUTC (val: boolean, oldVal: boolean): void {
      this.mode = 0
    }
  },
  methods: {
    getDateTimeString(dateTimeUTC: number): string {
      return dateTimeService.getDateTimeString(this, dateTimeUTC, dateTimeService.getLocalTimeZoneName()).replaceAll(/ (\(.+\))/g, '')
    },
    getRelativeDateTime (dateTimeUTC: number): string {
      return dateTimeService.getRelativeDateTimeString(dateTimeUTC)
    },
    showAbsoluteDateTime (): void {
      this.mode = 1
    }
  }
})
</script>
