/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * A permission.
 */
export type Permission = {
    /**
     * The mode
     */
    mode?: Permission.mode;
    /**
     * The reference id of the permission.
     */
    ref?: string;
    /**
     * The value, true if the permission is present.
     */
    value?: boolean;
};

export namespace Permission {

    /**
     * The mode
     */
    export enum mode {
        READ = 'READ',
        SET = 'SET',
    }


}
