<template>
    <footer v-if="appVersion && frontendVersion && appNGVersion">
      <nav class="navbar navbar-expand-lg navbar-light d-flex justify-content-between">
        <ul class="navbar-nav">
          <li class="nav-item"><router-link v-bind:to="{name: 'digestRegistration', params: {}}" class="nav-link"><span v-html="headerLabels.item.registration"></span></router-link></li>
          <li v-if="!isAuthenticated" class="nav-item"><router-link v-bind:to="{name: 'login', params: {}}" class="nav-link"><span v-html="headerLabels.item.login"></span></router-link></li>
          <!-- li class="nav-item"><router-link v-bind:to="{name: 'about', params: {}}" class="nav-link"><span v-html="headerLabels.item.about"></span></router-link></li -->
          <li class="nav-item"><router-link v-bind:to="{name: 'help', params: {}}" class="nav-link"><span v-html="headerLabels.item.help"></span></router-link></li>
          <li class="nav-item"><router-link v-bind:to="{name: 'publicContact', params: {}}" class="nav-link"><span v-html="headerLabels.item.contact"></span></router-link></li>
          <li class="nav-item"><router-link v-bind:to="{name: 'publicComplaint', params: {}}" class="nav-link"><span v-html="headerLabels.item.complaint"></span></router-link></li>
          <li class="nav-item"><router-link v-bind:to="{name: 'privacy', params: {}}" class="nav-link"><span v-html="headerLabels.item.privacyPolicy"></span></router-link></li>
          <li class="nav-item"><router-link v-bind:to="{name: 'terms', params: {}}" class="nav-link"><span v-html="headerLabels.item.terms"></span></router-link></li>
          <li class="nav-item"><router-link v-bind:to="{name: 'imprint', params: {}}" class="nav-link"><span v-html="headerLabels.item.imprint"></span></router-link></li>
          <li class="nav-item"><router-link v-bind:to="{name: 'cookieSettings', params: {}}" class="nav-link"><span v-html="headerLabels.item.cookieSettings"></span></router-link></li>
        </ul>
      </nav>
      <div class="version">
        <span class="small">v{{appVersion}} / v{{frontendVersion}} (appNG: v{{appNGVersion}})&nbsp;</span>
      </div>
    </footer>
</template>

<script lang="ts">
import Vue from 'vue'
import { globalVar } from '@/scripts/own/_globalVar'

export default Vue.extend({
  name: 'appFooter',
  computed: {
    headerLabels (): {[key: string]: any} {
      return globalVar.header.labels
    },
    appVersion (): string {
      return this.$store.state.version.app
    },
    appNGVersion (): string {
      return this.$store.state.version.appNG
    },
    frontendVersion (): string {
      return this.$store.state.version.frontend
    },
    isAuthenticated (): boolean {
      return this.$store.state.user.authenticated
    }
  },
  methods: {
    getNickName (): string {
      return this.$store.state.user.nickName
    }
  }
})
</script>
