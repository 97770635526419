/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Action } from '../models/Action';
import type { Datasource } from '../models/Datasource';
import type { ErrorModel } from '../models/ErrorModel';
import type { Navigation } from '../models/Navigation';
import type { PageDefinition } from '../models/PageDefinition';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AppNgService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param site the site to call
     * @param application the application to call
     * @returns Navigation datasource response
     * @throws ApiError
     */
    public getNavigation(
site: string,
application: string,
): CancelablePromise<Navigation> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/navigation',
            path: {
                'site': site,
                'application': application,
            },
        });
    }

    /**
     * @param site the site to call
     * @param application the application to call
     * @param id the ID of the page
     * @param pageUrlParams the URL path to parametrize the page with.
     * @param sect a list of section IDs to fill
     * @returns PageDefinition datasource response
     * @throws ApiError
     */
    public getPageWithUrlParams(
site: string,
application: string,
id: string,
pageUrlParams: string,
sect?: Array<string>,
): CancelablePromise<PageDefinition> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/page/{id}/{pageUrlParams}',
            path: {
                'site': site,
                'application': application,
                'id': id,
                'pageUrlParams': pageUrlParams,
            },
            query: {
                '_sect': sect,
            },
        });
    }

    /**
     * Returns a datasource by id
     * @param site the site to call
     * @param application the application to call
     * @param id The id of the datasource.
     * @param params The id of the datasource.
 * Parameters (including sorting and filtering parameters) are passed as matrix style parameters.
 * Note that names and values of these matrix parameters must be URL-Encoded
 * 
     * @returns Datasource datasource response
     * @returns ErrorModel unexpected error
     * @throws ApiError
     */
    public getDatasource(
site: string,
application: string,
id: string,
params: string,
): CancelablePromise<Datasource | ErrorModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/datasource/{id}/{params}',
            path: {
                'site': site,
                'application': application,
                'id': id,
                'params': params,
            },
        });
    }

    /**
     * Returns an action. To be used for initially retrieving the action.
     * @param site the site to call
     * @param application the application to call
     * @param eventId event-id of the action
     * @param id The ID of the action.
     * @param params Parameters (including sorting and filtering parameters) are passed as matrix style parameters. Note that names and values of these matrix parameters must be URL-Encoded
     * @returns Action action response
     * @returns ErrorModel unexpected/unrecoverable error
     * @throws ApiError
     */
    public getAction(
site: string,
application: string,
eventId: string,
id: string,
params: string,
): CancelablePromise<Action | ErrorModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/action/{event-id}/{id}/{params}',
            path: {
                'site': site,
                'application': application,
                'event-id': eventId,
                'id': id,
                'params': params,
            },
            errors: {
                400: `action response, in case an recoverable error occurred`,
            },
        });
    }

    /**
     * Performs an action.
     * @param site the site to call
     * @param application the application to call
     * @param eventId event-id of the action
     * @param id The ID of the action.
     * @param params Parameters (including sorting and filtering parameters) are passed as matrix style parameters. Note that names and values of these matrix parameters must be URL-Encoded
     * @param formData For each field of an action, a multipart parameter must be present .
     * @returns Action action response
     * @returns ErrorModel unexpected/unrecoverable error
     * @throws ApiError
     */
    public performActionMultiPart(
site: string,
application: string,
eventId: string,
id: string,
params: string,
formData?: Record<string, any>,
): CancelablePromise<Action | ErrorModel> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/action/multipart/{event-id}/{id}/{params}',
            path: {
                'site': site,
                'application': application,
                'event-id': eventId,
                'id': id,
                'params': params,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `action response, in case an recoverable error occurred`,
            },
        });
    }

}
