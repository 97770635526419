import Vue from 'vue'
import { globalVar } from '../own/_globalVar'
import { generalService } from './GeneralService'
import { vueTemplateService } from './VueTemplateService'
import { uiService } from './UIService'
import { AppNGConnector } from '../own/ait-appNGConnector'
import { AppClient } from '../../openAPI/index'
import { IPageServiceUrlParams } from '../../types/app'
import { comParam, IComParamScopeItem } from '../own/ait-comParam'

interface VueExt extends Vue {
  registerRequestState: (code: number) => void
}

class DataService {
  private fetchLocalizationContent (locale: string, callback: () => void): void {
    if (locale) {
      import('./_localizationContent-' + locale)
        .then((data) => {
          generalService.log('# localizationContent: Loaded!', data)
          $.extend(true, globalVar, data.globalVar)
          generalService.log('# localizationContent: globalVar', globalVar)
          callback()
        })
        .catch(() => {
          generalService.log('# localizationContent: Error - localizationContent was not found: ', locale)
          callback()
        })
    }
  }
  public getServiceUrl (vue: Vue, sourceId: string): string {
    generalService.log('> Communication: getServiceUrl for "' + sourceId + '"', vue.$router.currentRoute.meta ? vue.$router.currentRoute.meta.serviceURL : null)
    return ((typeof vue.$router.currentRoute.meta !== 'undefined') && (typeof vue.$router.currentRoute.meta.serviceURL !== 'undefined') && (typeof vue.$router.currentRoute.meta.serviceURL[sourceId] !== 'undefined')) ? vue.$router.currentRoute.meta.serviceURL[sourceId] : ''
  }
  public getContentData4Datasource (vm: Vue, sourceURL: string, requestData: {[key: string]: any} | FormData, callback: (data: any) => void): void {
    // data for datasource
    if (!sourceURL) {
      return
    }

    const urlParam: {[key: string]: string} = {
      site: '',
      application: '',
      id: '',
      params: ''
    }
    const arrUrlParams = sourceURL.match(/^\/api\/v1\/datasource\/([a-zA-Z0-9-_]+)(\/*)(.*)$/)
    if ((arrUrlParams !== null) && (arrUrlParams.length >= 3)) {
      urlParam.site = ''
      urlParam.application = ''
      urlParam.id = arrUrlParams[1]
      urlParam.params = this.getPathParamParams(arrUrlParams[3], requestData)
    }

    // share url
    const getParamsString = jQuery.param(this.parseQueryParameters(urlParam.params))
    const shareUrl = window.location.origin + window.location.pathname + '?' + getParamsString + window.location.hash

    if (typeof vm.$data !== 'undefined' && typeof vm.$data.currentUrl !== 'undefined') vm.$data.currentUrl = shareUrl

    // request
    const appClient = new AppClient({
      ENCODE_PATH: (str) => str
    })
    appClient.appNg.getDatasource(urlParam.site, urlParam.application, urlParam.id, urlParam.params)
      .then((data: any): void => {
        this.registerRequestState(vm as VueExt, 200)
        // process data
        const appNGConnector = new AppNGConnector()
        if ((vm.$store.state.user.locale !== data.user.locale) && (data.user.locale !== 'en')) {
          this.fetchLocalizationContent(data.user.locale, (): void => {
            this.processResponseData(vm, appNGConnector.convertData(data), requestData, callback)
          })
        } else {
          this.processResponseData(vm, appNGConnector.convertData(data), requestData, callback)
        }
      })
      .catch((e: any) => {
        this.onRequestFail(e, vm, requestData, callback)
      })
  }
  public getContentData4Action (vm: Vue, sourceURL: string, requestData: {[key: string]: any} | FormData, callback: (data: any) => void): void {
    // data for action
    if (!sourceURL) {
      return
    }

    const urlParam: {[key: string]: string} = {
      site: '',
      application: '',
      id: '',
      eventid: '',
      params: ''
    }
    const arrUrlParams = sourceURL.match(/^\/api\/v1\/action\/([a-zA-Z0-9-_]+)\/([a-zA-Z0-9-_]+)(\/*)(.*)$/)
    if ((arrUrlParams !== null) && (arrUrlParams.length >= 4)) {
      urlParam.site = ''
      urlParam.application = ''
      urlParam.eventid = arrUrlParams[1]
      urlParam.id = arrUrlParams[2]
      urlParam.params = this.getPathParamParams(arrUrlParams[4], requestData)
    }

    const appClient = new AppClient({
      ENCODE_PATH: (str) => str
    })
    appClient.appNg.getAction(urlParam.site, urlParam.application, urlParam.eventid, urlParam.id, urlParam.params)
      .then((data: any): void => {
        this.registerRequestState(vm as VueExt, 200)
        // process data
        const appNGConnector = new AppNGConnector()
        if ((vm.$store.state.user.locale !== data.user.locale) && (data.user.locale !== 'en')) {
          this.fetchLocalizationContent(data.user.locale, (): void => {
            this.processResponseData(vm, appNGConnector.convertData(data), requestData, callback)
          })
        } else {
          this.processResponseData(vm, appNGConnector.convertData(data), requestData, callback)
        }
      })
      .catch((e: any) => {
        this.onRequestFail(e, vm, requestData, callback)
      })
  }
  public postData4Action (vm: Vue, sourceURL: string, requestData: {[key: string]: any} | FormData, callback: (data: any) => void, hideLoader?: boolean): void {
    // post data
    if (!sourceURL) {
      return
    }
    if ((typeof hideLoader === 'undefined') || (typeof hideLoader !== 'undefined' && !hideLoader)) uiService.showCommunicationLoader()

    const urlParam: {[key: string]: string} = {
      site: '',
      application: '',
      eventid: '',
      id: ''
    }
    const arrUrlParams = sourceURL.match(/^\/api\/v1\/action\/multipart\/([a-zA-Z0-9-_]+)(\/*)(.*)$/)
    if ((arrUrlParams !== null) && (arrUrlParams.length >= 3)) {
      urlParam.site = ''
      urlParam.application = ''
      urlParam.eventid = arrUrlParams[1]
      urlParam.id = arrUrlParams[3]
    }
    let queryStringFromSourceURL = ''
    if (urlParam.id.indexOf('?') !== -1) {
      queryStringFromSourceURL = urlParam.id.substring(urlParam.id.indexOf('?') + 1)
      urlParam.id = urlParam.id.substring(0, urlParam.id.indexOf('?'))
    }
    const getParamsObject = {}
    jQuery.extend(getParamsObject, this.parseQueryParameters(queryStringFromSourceURL))

    let pathParams = ''
    for (const paramName in getParamsObject) {
      pathParams += ';' + paramName + '=' + encodeURIComponent(getParamsObject[paramName])
    }

    const object = {}    
    if (requestData instanceof FormData) {
      for (const pair of requestData.entries()) {
        if (typeof object[pair[0]] !== 'undefined') {
          if (!Array.isArray(object[pair[0]])) object[pair[0]] = [object[pair[0]]]
          object[pair[0]].push(pair[1])
        } else {
          object[pair[0]] = pair[1]
        }
      }
    } else {
       Object.assign(object, requestData)
    }
    generalService.log('>>>>>> requestData', JSON.stringify(object))

    const appClient = new AppClient()
    appClient.appNg.performActionMultiPart(urlParam.site, urlParam.application, urlParam.eventid, urlParam.id, pathParams, object)
      .then((data: any): void => {
        this.registerRequestState(vm as VueExt, 200)
        // process data
        const appNGConnector = new AppNGConnector()
        uiService.removeCommunicationLoader()
        if ((vm.$store.state.user.locale !== data.user.locale) && (data.user.locale !== 'en')) {
          this.fetchLocalizationContent(data.user.locale, (): void => {
            this.processResponseData(vm, appNGConnector.convertData(data), requestData, callback)
          })
        } else {
          this.processResponseData(vm, appNGConnector.convertData(data), requestData, callback)
        }
      })
      .catch((e: any) => {
        this.onRequestFail(e, vm, requestData, callback)
      })
  }
  public getPageData (vm: Vue, urlParam: {[key: string]: any}, callback: (data: any) => void): void {
    // get page data
    uiService.showCommunicationLoader()

    const appClient = new AppClient({
      ENCODE_PATH: (str) => str
    })

    let pageId = urlParam.page + (urlParam.action ? '/' + urlParam.action : '') + (urlParam.actionid ? '/' + urlParam.actionid : '') + (urlParam.pathMatch ? '/' + urlParam.pathMatch : '')
    pageId += (pageId.indexOf('?') === -1 ? '?' : '&') + decodeURIComponent(jQuery.param(this.getQueryParams(urlParam.query)))

    appClient.appNg.getPageWithUrlParams(urlParam.site, urlParam.application, pageId, urlParam.pageUrlParams)
      .then((data: any): void => {
        this.registerRequestState(vm as VueExt, 200)
        const r: any = {}
        r.id = data.id
        r.sections = []
        if (typeof data.sections !== 'undefined') {
          for (let sectionIndex = 0; sectionIndex < data.sections.length; sectionIndex++) {
            if (!data.sections[sectionIndex].hidden) {
              const section: {[key: string]: any} = {
                id: data.sections[sectionIndex].id,
                title: data.sections[sectionIndex].title.value,
                elements: []
              }
              for (let i = 0; i < data.sections[sectionIndex].elements.length; i++) {
                const item: {[key: string]: any} = {
                  title: data.sections[sectionIndex].elements[i].title,
                  collapsed: data.sections[sectionIndex].elements[i].collapsed
                }
                if (typeof data.sections[sectionIndex].elements[i].action !== 'undefined') {
                  item.action = {}
                  item.action.sourceURL = data.sections[sectionIndex].elements[i].action._self
                  item.action.id = data.sections[sectionIndex].elements[i].action.id
                }
                if (typeof data.sections[sectionIndex].elements[i].datasource !== 'undefined') {
                  item.datasource = {}
                  item.datasource.sourceURL = data.sections[sectionIndex].elements[i].datasource._self
                  item.datasource.id = data.sections[sectionIndex].elements[i].datasource.id
                }
                section.elements.push(item)
              }
              r.sections.push(section)
            }
          }
        }
        vm.$store.commit('updateUserData', data.user)
        this.getFrontendAppVersion((versionNumber: string): void => {
          vm.$store.commit('updateVersionData', { appNGVersion: data.appNGVersion, appVersion: data.appVersion, frontendVersion: versionNumber})
        })

        uiService.removeCommunicationLoader()
        if (typeof callback === 'function') callback(r)
      })
      .catch((e: any) => {
        uiService.removeCommunicationLoader()
        this.onRequestFail(e, vm, {}, callback)
      })
  }
  public getNavigationData (vm: Vue, urlParam: IPageServiceUrlParams, callback: (data: {[key: string]: any}) => void): void {
    // get navigation
    uiService.showCommunicationLoader()

    const appClient = new AppClient()

    appClient.appNg.getNavigation(urlParam.site, urlParam.application)
      .then((data: any): void => {
        this.registerRequestState(vm as VueExt, 200)
        const r: {[key: string]: any} = {}
        r.tree = []
        r.logout = {}

        for (let i = 0; i < data.items.length; i++) {
          if (data.items[i].type === 'SITE') {
            r.tree.push(data.items[i])
          }
          if (data.items[i].type === 'PAGE') {
            r.logout = data.items[i].items
          }
        }
        vm.$store.commit('updateUserData', data.user)
        this.getFrontendAppVersion((versionNumber: string): void => {
          vm.$store.commit('updateVersionData', { appNGVersion: data.appNGVersion, appVersion: data.appVersion, frontendVersion: versionNumber})
        })

        uiService.removeCommunicationLoader()
        if (typeof callback === 'function') callback(r)
      })
      .catch((e: any) => {
        uiService.removeCommunicationLoader()
        this.onRequestFail(e, vm, {}, callback)
      })
  }
  private onRequestFail (error: {[key: string]: any}, vm: Vue, requestData: {[key: string]: any} | FormData, callback?: (e: any, code?: number) => void): void {
    if (error) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      switch (error.status) {
        case 400: {
          generalService.log('ERROR 400', error.body)
          const appNGConnector = new AppNGConnector()
          if ((vm.$store.state.user.locale !== error.body.user.locale) && (error.body.user.locale !== 'en')) {
            this.fetchLocalizationContent(error.body.user.locale, (): void => {
              this.processResponseData(vm, appNGConnector.convertData(error.body), requestData, callback)
            })
          } else {
            this.processResponseData(vm, appNGConnector.convertData(error.body), requestData, callback)
          }
          break
        }
        case 401: {
          generalService.log('ERROR 401', error.body)
          if (sessionStorage) {
            localStorage.removeItem('userAvatarPicture')
            vm.$store.commit('resetUserData')
            sessionStorage.setItem('HTTP-401', 'true')
          }
          if (vm.$router.currentRoute.name !== 'root') {
            if (sessionStorage) sessionStorage.setItem('redirectUrl', vm.$router.currentRoute.fullPath)
            vm.$router.push({ name: 'root' })
          }
          break
        }
        case 403: {
          generalService.log('ERROR 403', error.body)
          uiService.showMessage('403 Forbidden', 'You are not allowed to access this content!', null, 'xl')
          if (vm.$router.currentRoute.name !== 'root' && sessionStorage.getItem('pendingAccountToDelete')) {
            vm.$router.push({ name: 'restoreAccount' })
          } else {
            this.registerRequestState(vm as VueExt, 403)
            if (typeof callback === 'function') callback(null)
          }
          break
        }
        case 404: {
          generalService.log('ERROR 404', error.statusText, error)
          if (typeof vm.$router.currentRoute.params.nickName !== 'undefined') {
            if (vm.$router.currentRoute.name !== 'customer-404') vm.$router.replace({name: 'customer-404', params: { nickName: vm.$router.currentRoute.params.nickName}})
          } else {
            this.registerRequestState(vm as VueExt, 404)
            if (typeof callback === 'function') callback(null)
          }
          break
        }
        case 422: {
          generalService.log('ERROR 422', error.body)
          const appNGConnector = new AppNGConnector()
          if ((vm.$store.state.user.locale !== error.body.user.locale) && (error.body.user.locale !== 'en')) {
            this.fetchLocalizationContent(error.body.user.locale, (): void => {
              this.processResponseData(vm, appNGConnector.convertData(error.body), requestData, callback)
            })
          } else {
            this.processResponseData(vm, appNGConnector.convertData(error.body), requestData, callback)
          }
          break
        }
        case 471: {
          generalService.log('ERROR 471', error.body)
          sessionStorage.setItem('HTTP-471', 'true')
          sessionStorage.setItem('redirectUrl', vm.$router.currentRoute.fullPath)
          vm.$router.push({ name: 'login' })
          break;
        }
        case 473:
        case 474: {
          generalService.log('ERROR 473', error.body)
          if (typeof vm.$router.currentRoute.params.nickName !== 'undefined') {
            if (vm.$router.currentRoute.name !== 'customer-473') vm.$router.replace({name: 'customer-473', params: { nickName: vm.$router.currentRoute.params.nickName}})
          }
          break;
        }
        case 500: {
          uiService.showMessage('500 Internal Server Error', 'Ops, something went terribly wrong!', null, 'xl')
          this.registerRequestState(vm as VueExt, 500)
          if (typeof callback === 'function') callback(null)
          break
        }
        case 503: {
          uiService.showMessage('503 Service Unavailable', 'Ops, something went terribly wrong!', null, 'xl')
          this.registerRequestState(vm as VueExt, 503)
          if (typeof callback === 'function') callback(null)
          break
        }
        default: {
          console.error('> Request fail: Status - ', error.status, ' StatusText - ', error.statusText)
        }
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('> Request fail', error)
    }

    uiService.removeCommunicationLoader()
  }
  private registerRequestState(vm: VueExt, code: number): void {
    if (typeof vm.registerRequestState === 'function') vm.registerRequestState(code)
  }
  public processResponseData (vm: Vue, response: any, requestParams: {[key: string]: string} | FormData, callback?: (data: any, code?: number) => void): void {
    // process response data
    function getParamFormAction (requestParams: {[key: string]: string} | FormData): string | null {
      if (requestParams !== null) {
        if (requestParams instanceof FormData) {
          if (requestParams.has('form_action')) {
            const r: any = requestParams.get('form_action')
            if (r !== null) return r.toString()
            else return null
          } else return null
        } else {
          return ((requestParams !== null) && (typeof requestParams.form_action !== 'undefined')) ? requestParams.form_action : null
        }
      } else return null
    }
    function triggerAfterSendDataEvent (vm: Vue, requestParams: {[key: string]: string} | FormData, d: any, o:any): void {
      // afterSendData for action page components
      if (getParamFormAction(requestParams) === d.originId) {
        vm.$emit('afterSendData', {
          id: d.id,
          originId: d.originId,
          user: d.userdata,
          messages: d.messages,
          params: d.params,
          field: o.field
        })
      } else {
        vm.$emit('afterProcessResponseData', {
          id: d.id,
          originId: d.originId,
          user: d.userdata,
          messages: d.messages,
          params: d.params,
          field: o.field
        })
      }
    }
    for (const eventType in response) {
      let d: any
      switch (eventType) {
        case 'action': {
          d = response.action
          break
        }
        case 'datasource': {
          d = response.datasource
          break
        }
      }

      generalService.log('> Communication: Response Backend data', d)

      // Template data
      const o:any = {}
      o.id = d.id
      o.originId = d.originId
      if (eventType === 'action') o.execute = this.getActionExecuteUrl(d.execute)
      o.title = d.title
      o.formName = d.id
      o.permissions = typeof d.permissions === 'undefined' ? null : d.permissions
      o.messages = typeof d.messages === 'undefined' ? null : d.messages

      o.params = {}
      if (d.params !== null) {
        for (const prop in d.params) {
          if (prop === 'form_action') o.params[prop] = o.id
          else o.params[prop] = d.params[prop]
        }
      }

      const defaultLabels = {}
      jQuery.extend(true, defaultLabels, globalVar.view.default.labels)

      o.field = this.getFormFieldsData((typeof d.fields !== 'undefined') ? d.fields : [])
      o.results = (typeof d.resultset !== 'undefined') ? d.resultset : []
      o.links = (typeof d.links !== 'undefined') ? d.links : {}
      o.filter = this.getFilterFieldsData((typeof d.filter !== 'undefined') ? d.filter : [])
      o.setFilters = this.getSetedFilters(o.filter)
      o.pagination = this.getPagination(d.page)
      o.sort = (typeof d.sort !== 'undefined') ? d.sort : []
      o.labels = ((typeof globalVar.view[o.id] !== 'undefined') && (typeof globalVar.view[o.id].labels !== 'undefined')) ? jQuery.extend(true, defaultLabels, globalVar.view[o.id].labels) : defaultLabels
      o.images = ((typeof globalVar.view[o.id] !== 'undefined') && (typeof globalVar.view[o.id].images !== 'undefined')) ? globalVar.view[o.id].images : globalVar.view.default.images
      o.layout = ((typeof d.layout !== 'undefined') && (d.layout !== null)) ? d.layout : (((typeof globalVar.view[o.id] !== 'undefined') && (typeof globalVar.view[o.id].layout !== 'undefined')) ? globalVar.view[o.id].layout : globalVar.view.default.layout)
      o.userdata = (typeof d.userdata !== 'undefined') ? d.userdata : null
      o.appNGVersion = (typeof d.appNGVersion !== 'undefined') ? d.appNGVersion : null
      o.appVersion = (typeof d.appVersion !== 'undefined') ? d.appVersion : null


      generalService.log('> Communication: Response Template data', o)

      // messages
      if ((d.messages !== null) && (d.messages.length !== 0)) {
        const messagesState = this.getMessagesState(d.messages)
        if (messagesState.OK || messagesState.ERROR || messagesState.NOTICE) {
          uiService.showPopupMessages(d.id, this.getMessagesText(d.messages))
          triggerAfterSendDataEvent(vm, requestParams, d, o)
        }
      } else {
        // d.messages === null
        triggerAfterSendDataEvent(vm, requestParams, d, o)
      }
      
      vm.$store.commit('updateUserData', d.userdata)
      this.getFrontendAppVersion((versionNumber: string): void => {
        vm.$store.commit('updateVersionData', { appNGVersion: d.appNGVersion, appVersion: d.appVersion, frontendVersion: versionNumber})
      })

      if (typeof callback === 'function') callback(o, 200)
    }
  }
  private getFormFieldsData (data: any): any {
    const r: any = {}

    if ((data === null) || (typeof data === 'undefined')) return r

    for (let i = 0; i < data.length; i++) {
      if (typeof data[i].name !== 'undefined') {
        const prop: any = {}
        if (data[i].type === 'OBJECT') {
          r[data[i].name] = {}
          for (const prop in  data[i].props) {
            switch (data[i].props[prop].type) {
              case 'OBJECT': {
                r[data[i].name][prop] = this.getFormFieldsData(data[i].props[prop].fields)
                break
              }
              case 'LIST_OBJECT': {
                r[data[i].name][prop] = []
                for (let j = 0; j < data[i].props[prop].props.length; j++) {
                  r[data[i].name][prop].push(data[i].props[prop].props[j])
                }
                break
              }
              default: {
                r[data[i].name][prop] = {
                  index: typeof data[i].props[prop].index !== 'undefined' ? data[i].props[prop].index : null,
                  name: data[i].props[prop].name,
                  value: typeof data[i].props[prop].value !== 'undefined' ? data[i].props[prop].value : null,
                  validation: typeof data[i].props[prop].rules !== 'undefined' ? data[i].props[prop].rules : null,
                  required: this.hasNotBlankRule(typeof data[i].props[prop].rules !== 'undefined' ? data[i].props[prop].rules : []),
                  type: data[i].props[prop].type,
                  options: typeof data[i].props[prop].options !== 'undefined' ? data[i].props[prop].options : null,
                  label: data[i].props[prop].label,
                  format: typeof data[i].props[prop].format !== 'undefined' ? data[i].props[prop].format : null,
                  readonly: data[i].props[prop].readonly,
                  messages: typeof data[i].props[prop].messages !== 'undefined' ? data[i].props[prop].messages : null
                }
              }
            }
          }
        } else if (data[i].type === 'LIST_OBJECT') {
          r[data[i].name] = []
          if (typeof data[i].props !== 'undefined') {
            for (let listIndex = 0;  listIndex < data[i].props.length; listIndex++) {
              r[data[i].name].push(data[i].props[listIndex])
            }
          }
        } else {
          if (typeof data[i].index !== 'undefined') prop.index = data[i].index
          prop.name = this.getPropFromField(data, data[i].name, 'binding'); prop.name = (prop.name === null) ? data[i].name : prop.name
          prop.value = this.getPropFromField(data, data[i].name, 'value')
          prop.validation = this.getPropFromField(data, data[i].name, 'rules')
          prop.required = this.hasNotBlankRule(prop.validation)
          prop.type = this.getPropFromField(data, data[i].name, 'type')
          prop.options = this.getPropFromField(data, data[i].name, 'options')
          prop.label = this.getPropFromField(data, data[i].name, 'label')
          prop.format = this.getPropFromField(data, data[i].name, 'format')
          prop.readonly = this.getPropFromField(data, data[i].name, 'readonly')
          prop.messages = this.getPropFromField(data, data[i].name, 'messages')
          prop.fields = this.getPropFromField(data, data[i].name, 'fields')

          r[data[i].name] = prop          
        }
      } else {
        for (const key in data[i]) {
          r[key] = data[i][key]
        }
      }
    }
    return r
  }
  private getFilterFieldsData (data: any): any {
    const r: any = {}

    if ((data === null) || (typeof data === 'undefined')) return r

    for (let i = 0; i < data.length; i++) {
      const prop: any = {}

      prop.name = this.getPropFromField(data, data[i].name, 'name')

      prop.validation = this.getPropFromField(data, data[i].name, 'rules')
      prop.required = this.hasNotBlankRule(prop.validation)
      prop.type = this.getPropFromField(data, data[i].name, 'type')
      prop.options = this.getPropFromField(data, data[i].name, 'options')
      prop.label = this.getPropFromField(data, data[i].name, 'label')
      prop.format = this.getPropFromField(data, data[i].name, 'format')
      prop.messages = null
      prop.value = this.getPropFromField(data, data[i].name, 'value')
      r[data[i].name] = prop
    }

    return r
  }
  private getPropFromField (fieldsData: any, fieldName: any, propName: any): any {
    if (fieldsData === null) return null
    for (let i = 0; i < fieldsData.length; i++) {
      if (fieldsData[i].name === fieldName) return (typeof fieldsData[i][propName] !== 'undefined') ? fieldsData[i][propName] : null
    }
    return null
  }
  public getFilterLabel (data: any, filterName: any, map: any): any {
    if (typeof data.filter[filterName] === 'undefined') return ''

    if ((typeof data.filter[filterName].options === 'undefined') || (data.filter[filterName].options === null)) return data.filter[filterName].value

    const selectedOptionValues: Array<string> = []
    for (let i = 0; i < data.filter[filterName].options.length; i++) {
      if (data.filter[filterName].options[i].selected) selectedOptionValues.push(data.filter[filterName].options[i].value)
    }

    if (selectedOptionValues.length === 0) return data.filter[filterName].value

    let result = ''
    for (let i = 0; i < selectedOptionValues.length; i++) {
      if ((typeof map[filterName] === 'undefined') || (typeof map[filterName][selectedOptionValues[i]] === 'undefined')) result = data.filter[filterName].value
      else {
        if (result !== '') result += '; ' + map[filterName][selectedOptionValues[i]]
        else result = map[filterName][selectedOptionValues[i]]
      }
    }
    return result
  }
  private hasNotBlankRule (rules: {[key: string]: any}): boolean {
    if ((typeof rules === 'undefined') || rules === null) return false
    if ((typeof rules.NotBlank !== 'undefined') || (typeof rules.NotNull !== 'undefined')) return true
    return false
  }
  private getSetedFilters (filter: {[key: string]: any}): Array<{[key: string]: any}> {
    const r: Array<{[key: string]: any}> = []
    for (const filterParam in filter) {
      const filterItem = filter[filterParam]
      const item: {[key: string]: any} = {
        name: filterItem.name,
        label: filterItem.label,
        value: null
      }
      if ((filterItem.value !== null) && (filterItem.value !== '')) {
        switch (filterItem.type) {
          case 'SELECT':
          case 'CHECKBOX':
          case 'RADIO': {
            let label = ''
            for (let i = 0; i < filterItem.options.entries.length; i++) {
              label = !filterItem.options.entries[i].selected ? label : filterItem.options.entries[i].label
            }
            if (label !== '') {
              item.value = label
              r.push(item)
            }
            break
          }
          default: {
            item.value = filterItem.value
            r.push(item)
          }
        }
      }
    }
    return r
  }
  private getPagination (page: {[key: string]: any}): {[key: string]: any} | null {
    if ((typeof page === 'undefined') || (page === null)) return null
    for (let i = 0; i < page.pageSizes.length; i++) {
      if (page.pageSizes[i].size === page.size) {
        page.pageSizes[i].selected = true
      } else {
        page.pageSizes[i].selected = false
      }
    }
    return page
  }
  private getMessagesText (messages: Array<{[key: string]: string}>): Array<{[key: string]: string}> {
    const r: Array<{[key: string]: string}> = []
    for (let i = 0; i < messages.length; i++) {
      const messageContent: string = ((typeof globalVar !== 'undefined') && (typeof globalVar.message[messages[i].text] !== 'undefined')) ? globalVar.message[messages[i].text] : messages[i].text
      r.push({
        level: messages[i].level,
        text: messageContent
      })
    }
    return r
  }  
  private getQueryParams (data: {[key: string]: any} | FormData): {[key: string]: any} {
    const arrBrowserRequestParams: Array<IComParamScopeItem> = (typeof comParam !== 'undefined') ? comParam.getRequestedState() : []
    const objBrowserRequestParams: IComParamScopeItem = {}
    let ajaxParams: {[key: string]: any} = {}
    let methodeType = ''
    let formActionParamEnabled = false

    if (data instanceof FormData) {
      // FileData API - 'data' is FileData Instance
      for (let i = 0; i < arrBrowserRequestParams.length; i++) {
        for (const paramName in arrBrowserRequestParams[i]) {
          if (!data.has(paramName)) data.append(paramName, arrBrowserRequestParams[i][paramName])
        }
      }

      data.append('rts', Date.now().toString())
      ajaxParams = data
      formActionParamEnabled = (data.has('form_action')) && (data.get('form_action') !== '')
      methodeType = formActionParamEnabled ? 'POST' : 'GET'
    } else {
      // without FileData API
      for (let i = 0; i < arrBrowserRequestParams.length; i++) {
        for (const paramName in arrBrowserRequestParams[i]) {
          if ((paramName !== 'remove') && (typeof arrBrowserRequestParams[i][paramName] !== 'function')) objBrowserRequestParams[paramName] = arrBrowserRequestParams[i][paramName]
        }
      }

      jQuery.extend(ajaxParams, objBrowserRequestParams, data)
      jQuery.extend(ajaxParams, { rts: Date.now().toString() })
      formActionParamEnabled = (typeof data !== 'undefined') && (typeof data.form_action !== 'undefined') && (data.form_action !== '')
      methodeType = formActionParamEnabled ? 'POST' : 'GET'
    }

    const rp: Array<IComParamScopeItem> = []
    let rpItem: {[key: string]: string} = {}
    if (ajaxParams instanceof FormData) {
      for (const pair of ajaxParams.entries()) {
        if (jQuery.inArray(pair[0], ['ghost', 'form_action', 'ffts', 'rts']) === -1) {
          rpItem = {}
          rpItem[pair[0]] = pair[1].toString()
          rp.push(rpItem)
        }
      }
    } else {
      for (const pair in ajaxParams) {
        if (jQuery.inArray(pair, ['ghost', 'form_action', 'ffts', 'rts']) === -1) {
          rpItem = {}
          rpItem[pair] = ajaxParams[pair]
          rp.push(rpItem)
        }
      }
    }

    if ((methodeType === 'GET') && (rp.length > 0) && !formActionParamEnabled) {
      // comParam.refreshBrowserGetParams(rp)
    }

    if ((methodeType === 'GET') && (ajaxParams instanceof FormData)) {
      const params: IComParamScopeItem = {}
      for (const pair of ajaxParams.entries()) {
        params[pair[0]] = pair[1].toString()
      }
      ajaxParams = params
    }
    generalService.log('> Communication: Request parameters', ajaxParams, formActionParamEnabled)
    return ajaxParams
  }
  private getUrlParamsForPageService (currentRoute: {[key: string]: any}, navigationTree: Array<{[key: string]: any}>): IPageServiceUrlParams {
    function getItems (r: Array<{[key: string]: any}>, items: Array<{[key: string]: any}>): Array<{[key: string]: any}> {
      for (let i = 0; i < items.length; i++) {
        if (typeof items[i]._self !== 'undefined') r.push({ _self: items[i]._self, path: items[i].path })
        if (typeof items[i].items !== 'undefined') getItems(r, items[i].items)
      }
      return r
    }
    const urlParams: IPageServiceUrlParams = {
      site: '',
      application: '',
      page: '',
      pageUrlParams: '',
      action: '',
      actionid: '',
      pathMatch: '',
      query: {}
    }
    if (currentRoute.params) {
      if (typeof currentRoute.params.site !== 'undefined') {
        urlParams.site = currentRoute.params.site
      }
      if (typeof currentRoute.params.application !== 'undefined') {
        urlParams.application = currentRoute.params.application
      }
      if (typeof currentRoute.params.page !== 'undefined') {
        urlParams.page = currentRoute.params.page
      }
      if (typeof currentRoute.params.action !== 'undefined') {
        urlParams.action = currentRoute.params.action
      }
      if (typeof currentRoute.params.actionid !== 'undefined') {
        urlParams.actionid = currentRoute.params.actionid
      }
      if (typeof currentRoute.params.pathMatch !== 'undefined') {
        urlParams.pathMatch = currentRoute.params.pathMatch
      }
    }
    if (urlParams.page === '') {
      // params for Application URL
      let pageServiceUrl = ''
      const navItems = getItems([], navigationTree)
      for (let i = 0; i < navItems.length; i++) {
        if (currentRoute.path === navItems[i].path) pageServiceUrl = navItems[i]._self
      }
      const arrUrlParams = pageServiceUrl.match(/^\/service\/([a-zA-Z0-9-_]+)\/([a-zA-Z0-9-_]+)\/rest\/openapi\/page\/([a-zA-Z0-9-_.]+)(\/*)([;=%a-zA-Z0-9-_.]*)(\?.*)*$/)
      if ((arrUrlParams !== null) && (arrUrlParams.length === 7)) {
        urlParams.site = arrUrlParams[1]
        urlParams.application = arrUrlParams[2]
        urlParams.page = arrUrlParams[3]
        urlParams.pageUrlParams = arrUrlParams[5]
      }
    }
    if (typeof currentRoute.query !== 'undefined') {
      urlParams.query = currentRoute.query
    }
    generalService.log('> Page urlParams', urlParams)
    return urlParams
  }
  private getSourceUrlForPage (sourceURL: string, currentRoute: {[key: string]: any}, navigationTree: Array<{[key: string]: any}>): string {
    function getItems (r: Array<{[key: string]: any}>, items: Array<{[key: string]: any}>): Array<{[key: string]: any}> {
      for (let i = 0; i < items.length; i++) {
        if (typeof items[i]._self !== 'undefined') r.push({ _self: items[i]._self, path: items[i].path })
        if (typeof items[i].items !== 'undefined') getItems(r, items[i].items)
      }
      return r
    }

    if (typeof sourceURL === 'undefined') {
      // from Route Meta
      sourceURL = ((typeof currentRoute.meta !== 'undefined') && (typeof currentRoute.meta.sourceURL !== 'undefined')) ? currentRoute.meta.sourceURL : ''
      if (sourceURL === '') {
        // generate service url from page path
        if (currentRoute.params && (typeof currentRoute.params.site !== 'undefined') && (typeof currentRoute.params.application !== 'undefined') && (typeof currentRoute.params.page !== 'undefined')) {
          sourceURL = '/service/' + currentRoute.params.site + '/' + currentRoute.params.application + '/rest/openapi/page/' + currentRoute.params.page
          if ((typeof currentRoute.params.action !== 'undefined') && (typeof currentRoute.params.actionid !== 'undefined')) {
            sourceURL += '/' + currentRoute.params.action + '/' + currentRoute.params.actionid
          }
          if (typeof currentRoute.params.pathMatch !== 'undefined') {
            sourceURL += '/' + currentRoute.params.pathMatch
          }
        }
      }
      if (sourceURL === '') {
        // get service url from navigation response
        const navItems = getItems([], navigationTree)
        for (let i = 0; i < navItems.length; i++) {
          if (currentRoute.path === navItems[i].path) sourceURL = navItems[i]._self
        }
      }
      const query = jQuery.param(currentRoute.query, true)
      sourceURL += query ? '?' + query : ''
    }
    generalService.log('> SourceURL', sourceURL)
    generalService.log('> Currect Route Params', currentRoute.params)
    return sourceURL
  }
  private getPathParamParams (urlParamParams: string, requestData: {[key: string]: any} | FormData): string {
    const getParamsObject = this.getQueryParams(requestData)
    const mergeObj = {}
    jQuery.extend(mergeObj, this.parseQueryParameters(urlParamParams), getParamsObject)

    let pathParams = ''
    for (const paramName in mergeObj) {
      pathParams += ';' + paramName + '=' + encodeURIComponent(mergeObj[paramName])
    }
    return pathParams
  }
  private parseQueryParameters (searchParams: string): {[key: string]: any} {
    const r = {}
    if (searchParams.indexOf(';') === 0) {
      searchParams = searchParams.substring(1)
    }
    if (searchParams.indexOf('?') === 0) {
      searchParams = searchParams.substring(1)
    }
    searchParams = searchParams.replaceAll('?', '%3F')
    searchParams = searchParams.replaceAll(';', '&')
    const params = new URLSearchParams(searchParams)
    for (const pair of params.entries()) {
      r[pair[0]] = pair[1]
    }
    return r
  }
  private updateVmData (state: {[key: string]: any}, data: {[key: string]: any}): void {
    function clearArraysIn (data: {[key: string]: any}): {[key: string]: any} {
      if ((typeof data === 'object') && (!Array.isArray(data))) {
        for (const prop in data) {
          if ((typeof data[prop] === 'object') && (!Array.isArray(data[prop]))) {
            data[prop] = clearArraysIn(data[prop])
          } else if (Array.isArray(data[prop])) {
            data[prop] = []
          }
        }
      }
      return data
    }
    for (const prop in data) {
      if ((typeof data[prop] === 'object') && (!Array.isArray(data[prop]))) {
        if ((state[prop] === null) || (data[prop] === null)) {
          state[prop] = data[prop]
        } else {
          state[prop] = clearArraysIn(state[prop])
          state[prop] = jQuery.extend(true, state[prop], data[prop])
        }
      } else if (typeof data[prop] === 'string') {
        state[prop] = data[prop]
      } else if ((typeof data[prop] === 'object') && (Array.isArray(data[prop]))) {
        state[prop] = []
        for (let i = 0; i < data[prop].length; i++) {
          state[prop].push(data[prop][i])
        }
      }
    }
  }
  private updateLabels (data: {[key: string]: any}): {[key: string]: any} {

    function updateFieldLabels (field: {[key: string]: any} | Array<{[key: string]: any}>): {[key: string]: any} {
      if (Array.isArray(field)) { //LIST_OBJECT
        for (let i = 0; i < field.length; i++) {
          field[i] = updateFieldLabels(field[i])
        }
      } else { // OBJECT or "form field"
        if ((typeof field.type !== 'undefined') &&  (field.type !== null)) { //normal form field
          const fieldName = field.name
          field.label = ((typeof labels.field !== 'undefined') && (typeof labels.field[fieldName] !== 'undefined') && (typeof labels.field[fieldName].label !== 'undefined')) ? labels.field[fieldName].label : field.label
          if ((typeof field.options !== 'undefined') && (field.options !== null)) {
            switch (field.type) {
              case 'LIST_SELECT':
              case 'LIST_RADIO': {
                for (let i = 0; i < field.options.entries.length; i++) {
                  const label = field.options.entries[i].label
                  const value = field.options.entries[i].value
                  field.options.entries[i].label = ((typeof labels.field !== 'undefined') && (typeof labels.field[fieldName] !== 'undefined') && (typeof labels.field[fieldName].options !== 'undefined') && (typeof labels.field[fieldName].options[value] !== 'undefined')) ? labels.field[fieldName].options[value] : label
                }
                break
              }
              default: {
                for (let i = 0; i < field.options.length; i++) {
                  field.options[i].name = ((typeof labels.field !== 'undefined') && (typeof labels.field[fieldName] !== 'undefined') && (typeof labels.field[fieldName].options !== 'undefined') && (typeof labels.field[fieldName].options[field.options[i].value] !== 'undefined')) ? labels.field[fieldName].options[field.options[i].value] : field.options[i].name
                }
              }
            }
          }
          // validation messages
          if ((typeof field.validation !== 'undefined') && (field.validation !== null) && (typeof globalVar.view[datasourceId] !== 'undefined') && (typeof globalVar.view[datasourceId].labels !== 'undefined') && (typeof globalVar.view[datasourceId].labels.field !== 'undefined') && (typeof globalVar.view[datasourceId].labels.field[fieldName] !== 'undefined') && (typeof globalVar.view[datasourceId].labels.field[fieldName].validation !== 'undefined')) {
            jQuery.extend(true, field.validation, globalVar.view[datasourceId].labels.field[fieldName].validation)
          }          
        } else { //OBJECT
          for (const prop in field) {
            field[prop] = updateFieldLabels(field[prop])
          }
        }
      }
      return field
    }

    const datasourceId = (typeof data.id !== 'undefined') ? data.id : null

    if ((datasourceId === null) || (typeof globalVar === 'undefined') || (typeof globalVar.view === 'undefined') || (typeof globalVar.view[datasourceId] === 'undefined') || (typeof globalVar.view[datasourceId].labels === 'undefined')) return data

    const labels = globalVar.view[datasourceId].labels

    // field
    for (const fieldName in data.field) {
      data.field[fieldName] = updateFieldLabels(data.field[fieldName])
    }

    // filter
    for (const filterName in data.filter) {
      data.filter[filterName].label = ((typeof labels.filter !== 'undefined') && (typeof labels.filter[filterName] !== 'undefined') && (typeof labels.filter[filterName].label !== 'undefined')) ? labels.filter[filterName].label : data.filter[filterName].label
      if (data.filter[filterName].options !== null) {
        for (let i = 0; i < data.filter[filterName].options.entries.length; i++) {
          const label = data.filter[filterName].options.entries[i].label
          const value = (typeof data.filter[filterName].options.entries[i].value !== 'undefined') ? data.filter[filterName].options.entries[i].value : ''
          data.filter[filterName].options.entries[i].label = ((typeof labels.filter !== 'undefined') && (typeof labels.filter[filterName] !== 'undefined') && (typeof labels.filter[filterName].options !== 'undefined') && (typeof labels.filter[filterName].options[value] !== 'undefined')) ? labels.filter[filterName].options[value] : label
        }
      }
    }

    // layout
    if (typeof labels.fieldset !== 'undefined') {
      if ((typeof data.layout !== 'undefined') && (data.layout !== null) && (typeof data.layout.filter !== 'undefined')) {
        for (let i = 0; i < data.layout.filter.length; i++) {
          if (typeof labels.fieldset[i] !== 'undefined') data.layout.filter[i].legend = labels.fieldset[i].legend
        }
      }
    }
    if (typeof labels.button !== 'undefined') {
      if ((typeof data.layout !== 'undefined') && (data.layout !== null) && (typeof data.layout.table !== 'undefined') && (typeof data.layout.table.actions !== 'undefined')) {
        for (let i = 0; i < data.layout.table.actions.length; i++) {
          if (typeof labels.button[data.layout.table.actions[i].id] !== 'undefined') data.layout.table.actions[i].label = labels.button[data.layout.table.actions[i].id]
        }
      }
    }

    // setFilters
    for (let i = 0; i < data.setFilters.length; i++) {
      data.setFilters[i].label = ((typeof labels.filter !== 'undefined') && (typeof labels.filter[data.setFilters[i].name] !== 'undefined') && (typeof labels.filter[data.setFilters[i].name].text !== 'undefined')) ? labels.filter[data.setFilters[i].name].text : data.setFilters[i].label
    }
    return data
  }
  public updateComponentData (component: {[key: string]: any}, data: {[key: string]: any}): void {
    if (data === null) return
    generalService.log('Update component data for "' + data.id + '" ...', data)

    data = this.updateLabels(data)
    component.id = data.id
    component.originId = data.originId
    component.execute = data.execute
    component.title = data.title
    component.filter = data.filter
    component.setFilters = data.setFilters
    component.field = data.field
    component.links = data.links
    component.pagination = data.pagination
    component.params = data.params
    component.permissions = data.permissions
    component.results = data.results === null ? [] : data.results
    component.sort = data.sort
    component.labels = data.labels
    component.layout = data.layout
  }
  public getMessagesState (messages: [{[key: string]: any}]): {[key: string]: number} {
    let multipleMessagesOK = 0
    let multipleMessagesError = 0
    let multipleMessagesWarning = 0

    for (let i = 0; i < messages.length; i++) {
      if (messages[i].level === 'OK') multipleMessagesOK++
      if (messages[i].level === 'ERROR') multipleMessagesError++
      if (messages[i].level === 'NOTICE') multipleMessagesWarning++
    }
    return {
      OK: multipleMessagesOK,
      ERROR: multipleMessagesError,
      NOTICE: multipleMessagesWarning
    }
  }
  private getFrontendAppVersion (callback: (data: any) => void): void {
    jQuery.ajax({
      type: 'GET',
      dataType: 'json',
      url: globalVar.serviceURL.keepAlive,   
      async: true,
      success: (data: any) => {
        callback(data.frontendVersion)
      }
    })
  }
  public fetchUserData (vm: Vue, profileUrl: string, callback?: () => void): void {
    this.getContentData4Datasource(vm, profileUrl, {nickName: vm.$store.state.user.nickName}, (profileData: any) => {
      if ((profileData.results.length > 0) &&  profileData.results[0].fields['image.previewPath'].value) {
        localStorage.setItem('userAvatarPicture', profileData.results[0].fields['image.previewPath'].value)
        vueTemplateService.$emit4Children(vm, 'updateAvatarPicture', profileData.results[0].fields['image.previewPath'].value)
      } else {
        localStorage.removeItem('userAvatarPicture')
        vueTemplateService.$emit4Children(vm, 'updateAvatarPicture', '')
      }

      if ((profileData.results.length > 0) &&  profileData.results[0].fields['unit'].value) {
        localStorage.setItem('userUnitsType', profileData.results[0].fields['unit'].value)
      }

      if (typeof callback === 'function') callback()
    })
  }
  public getActionExecuteUrl(execute: string): string {
    return execute.replaceAll('/service/travelmap/travelmap/rest/openapi/', '/api/v1/').replaceAll('/service/voyoa/travelmap/rest/openapi/', '/api/v1/').replaceAll('/service/voyoa/voyoa/rest/openapi/', '/api/v1/')
  }
}
export const dataService = new DataService()