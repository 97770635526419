<template>
  <div>
    <label v-bind:class="{required:isRequired}" class="checkbox">{{field.label}}</label>
    <div class="form-group mark-container form-check">
      <div v-for="(option, index) in field.options.entries" v-bind:key="index" class="custom-control custom-checkbox">
        <input type="checkbox"
          data-type="checkbox"
          class="form-check-input"
          v-bind:class="{'invalid-backend': isInvalid}"
          v-bind:id="id+index"
          v-bind:name="fieldName"
          v-bind:checked="option.selected"
          v-bind:required="isRequired"
          v-bind:readonly="isReadonly"
          v-bind:disabled="isReadonly && !option.selected"
          v-bind:value="option.value"
          v-model="value[index]"
          v-bind:data-error-message-selector="errorMessageSelector"
          v-bind:data-error-message="errorMessage"
          v-on:change="onChange(index)"
        >
        <label class="form-check-label" v-bind:for="id+index" v-html="option.label"></label>
        <input v-if="isReadonly && isChecked" type="hidden" v-bind:name="field.name" v-bind:value="field.value">
      </div>
    </div>
    <div class="error-message" v-bind:id="errorMessageID"></div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { globalVar } from '@/scripts/own/_globalVar'
import { IFormCheckboxList } from '@/types/app.d'
import { generalService } from '@/scripts/services/GeneralService'

export default Vue.extend({
  name: 'f-input-checkbox-list',
  data (): {[key: string]: any} {
    return {
      guid: '',
      value: []
    }
  },
  props: {
    field: Object as PropType<IFormCheckboxList>,
    errorMessageRequired: String
  },
  mounted (): void {
    this.guid = generalService.createGuid()
    for (let i = 0; i < this.field.options.entries.length; i++) {
      this.value.push(this.field.options.entries[i].value)
    }
  },
  computed: {
    id (): string {
      return  typeof this.field !== 'undefined' ? 'id_' + this.field.name.replace(/\./g, '_') + '_' + this.guid : 'id_undefined_' + this.guid
    },
    isChecked (): boolean {
      return this.field.value === 'true'
    },
    fieldName (): string {
      const fieldName = typeof this.field !== 'undefined' ? this.field.name : 'undefined'
      return !this.isReadonly ? fieldName : (fieldName + '_disabled')
    },
    isRequired (): boolean {
      return typeof this.field === 'undefined' ? false : this.field.required
    },
    isReadonly (): boolean {
      return typeof this.field === 'undefined' ? true : this.field.readonly
    },
    errorMessage (): string {
      return this.errorMessageRequired ? this.errorMessageRequired : (this.field.errorMessage === undefined ? 'required' : this.field.errorMessage)
    },
    errorMessageID (): string {
      return 'error_' + this.id
    },
    errorMessageSelector (): string {
      return '#' + this.errorMessageID
    },
    fieldLabel (): string | undefined {
      return this.$slots.default !== undefined ? this.$slots.default[0].text : this.field.label
    },
    errorMessages (): string | null {
      const r: any = []
      if ((typeof this.field !== 'undefined') && (this.field.messages !== null)) {
        for (let i = 0; i < this.field.messages.length; i++) {
          if (this.field.messages[i].level === 'ERROR') r.push(this.field.messages[i].text)
        }
        return r
      } return null
    },
    isInvalid (): boolean {
      if ((typeof this.field !== 'undefined') && (this.field.messages !== null)) {
        for (let i = 0; i < this.field.messages.length; i++) {
          if (this.field.messages[i].level === 'ERROR') return true
        }
      } return false
    }
  },
  methods: {
    onChange (e: any, index: number): void {
      this.value[index] = e.target.value
    },
    getMessageText (index: string): string {
      return typeof globalVar.message[index] !== 'undefined' ? globalVar.message[index] : index
    }
  }
})
</script>
