/* COMPATIBILITY: voyoa-frontend@0.1.0 with vue:v2.6.14 and tinymce/tinymce-vue:v3.2.8 */
/* NOTE: Vue.js 2.x applications, use tinymce-vue version 3. Version 4 of the tinymce-vue package supports Vue.js 3.x */
export const tinymceHelper = {
  richEditorButtons: {
    "formatselect": {
      pattern: /\bp\b|\baddress\b|\bpre\b|\bh1\b|\bh2\b|\bh3\b|\bh4\b|\bh5\b|\bh6\b/,
      code: 'formatselect ',
      group: "0"
    },
    "bold": {
      pattern: /\bb\b|\bstrong\b/,
      code: 'bold ',
      group: "1"
    },
    "italic": {
      pattern: /\bi\b/,
      code: 'italic ',
      group: "1"
    },
    "underline": {
      pattern: /\bunderline\b/,
      code: 'underline ',
      group: "1"				
    },
    "sub": {
      pattern: /\bsub\b/,
      code: 'sub ',
      group: "2"
    },
    "sup": {
      pattern: /\bsup\b/,
      code: 'sup ',
      group: "2"
    },
    "bullist": {
      pattern: /\bul\b/,
      code: 'bullist ',
      group: "3"
    },
    "numlist": {
      pattern: /\bol\b/,
      code: 'numlist ',
      group: "3"
    },
    "link": {
      pattern: /\ba\[\b|\ba\b/,
      code: 'link unlink ',
      group: "4"
    },
    "align": {
      pattern: /div\[align\]|p\[align\]/,
      code: 'justifyleft justifycenter justifyright justifyfull ',
      group: "5"				
    },
    "indent": {
      pattern: /\\indent\b/,
      code: 'outdent indent ',
      group: "6"				
    }
    
  },
  richEditorButtonGroups: {
    "0": false,
    "1": false,
    "2": false,
    "3": false,
    "4": false,
    "5": false
  },
  getRrichEditorBlockformats ( format : string | undefined ) : string | undefined {
    if (typeof format == 'undefined') return;
    let result = '';
    const blockformats = [
      { title: 'Paragraph', pattern: /\bp\b/, code: 'p' },
      { title:'Heading', pattern: /\bh1\b/, code: 'h1' },
      { title:'Subheading', pattern: /\bh2\b/, code: 'h2' },
      { title:'Title', pattern: /\bh3\b/, code: 'h3' },
      { title:'Subtitle', pattern: /\bh4\b/, code: 'h4' },
      { title:'Headline', pattern: /\bh5\b/, code: 'h5' },
      { title:'Subheadline', pattern: /\bh6\b/, code: 'h6' },
      { title:'Blockquote', pattern: /\bblockquote\b/, code: 'blockquote' },
      { title:'Preformatted', pattern: /\bpre\b/, code: 'pre' },
      { title:'Address', pattern: /\baddress\b/, code: 'address' }
    ];
    const validElements = format.split(',');
        
    for (let i=0; i<blockformats.length;i++) {
      for (let j=0; j<validElements.length; j++) {
        if (validElements[j].match(blockformats[i].pattern)) {
          result = result + blockformats[i].title + '=' + blockformats[i].code + ';';
          break;
        }
      }
    }    
    return result.substring(0,(result.length-1));
  },
  getRrichEditorButton (format : string | undefined, name: string): string {
    if (typeof format == 'undefined') return 'bold italic | bullist numlist | link | undo redo';
    const validElements = format.split(',');
    for(let i=0;i<validElements.length;i++){
      if ((typeof(this.richEditorButtons[name]) != 'undefined') && (validElements[i].match(this.richEditorButtons[name].pattern))) {
        this.richEditorButtonGroups[this.richEditorButtons[name].group] = true;
        return this.richEditorButtons[name].code;
      }
    }
    return '';
  },  
  getRrichEditorGroupsSeparator (name : string) : string{
    if (this.richEditorButtonGroups[name]) return '|';
    return '';
  }
}