<template>
  <a class="mention-link"
  target="_blank"
  v-text="getLabel"
  v-bind:title="getLabel"
  v-bind:href="getHRef"
  v-tooltip="getTooltip"/>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'mention-link',
  props: {
    label: String,
    href: String,
    tooltip: Object

  },
  computed: {
    getLabel (): string {
        return this.label
    },
    getHRef (): string {
        return this.href
    },
    getTooltip (): any {
      return this.tooltip
    }
  }
})
</script>
<style>
  .tooltip .tooltip-inner {
    color: #55544a;
    background: #efeeed;
    max-width: 300px;
    min-height: 50px;
    border-radius: 5px;
    margin-bottom: 0;
    height: 100%;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.3);
  }
  .tooltip .tooltip-arrow {
    border-color: #efeeed;
  }
  .tooltip .card-container {
    width: 100%;
    max-width: 210px;
  }
  .tooltip .card-item {
    display: inline-block;
    margin: 0 0px 0 0;
    text-align: left;
    vertical-align: top;
  }
  .tooltip .card-item.w30 {
    width: 44px;
  }
  .tooltip .card-item.w70 {
    width: 160px;
  }
  .tooltip .avatar-picture {
    width: 38px;
    height: 38px;
    margin-top: -1px;
    overflow: hidden;
    border-radius: 100% !important;
    border: 2px solid #ffffff;
    background: #6ebaa0;
  }
  .tooltip .avatar-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 100% !important;
    border: 2px solid #ffffff;
    background: #6ebaa0;
    strong {
      display: inline-block;
      font-size: 18px;
      line-height: 15px;
      height: 18px;
      color: #55544a;
      text-align: center;
      font-family: barlow-bold, Helvetica, Arial, sans-serif;
    }
  }
</style>
