<template>
  <div class="alert alert-danger">
    Content was not found. More information is in browser console.
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
  name: 'contentErrorMessage'
})
</script>
