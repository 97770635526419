<template>
  <div v-if="id != null" class="action wrapper" v-bind:class="{loading: showLoader}">
    <form v-bind:action="executeUrl" v-bind:name="id" v-bind:id="formId" enctype="multipart/form-data" class="form-framework" v-bind:data-locale="locale" v-bind:data-sourceid="id" data-send-method="ajax">
      <div class="modal-body">
        <h3 v-if="labelsEnabled" class="text-center mb-3" v-html="confirmationText"></h3>
        
        <input type="hidden" v-if="field['item.latitude']" v-bind:name="field['item.latitude'].name" v-bind:value="parameters.position[0]">
        <input type="hidden" v-if="field['item.longitude']" v-bind:name="field['item.longitude'].name" v-bind:value="parameters.position[1]">
        
        <div v-for="(value, key) in params" v-bind:key="value">
          <input type="hidden" v-bind:name="key" v-bind:value="value">
        </div>
      </div>
      <div v-if="labelsEnabled" class="modal-footer pb-0">
        <button type="button" class="btn btn-secondary" @click="cancel" v-html="labels.button.cancel"></button>
        <button type="submit" class="btn btn-primary" v-html="labels.button.submit"></button>
      </div>
      <input type="hidden" name="form_action" :value="originId">
    </form>
    <ContentErrorMessage v-if="isDataRequestError"></ContentErrorMessage>
  </div>
</template>

<script lang="ts">
import mixins from 'vue-typed-mixins'
import MXActionPopup from '@/components/mixins/actionPopup'
import ContentErrorMessage from '@/components/_content_error_message.vue'

export default mixins(MXActionPopup).extend({
  name: 'ActionUpdatePointPosition',
  components: {
    ContentErrorMessage
  }
})
</script>
